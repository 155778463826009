import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
} from "react";
// import LogRocket from "logrocket";
import { getUserData, getProfilePic, User as ValorUser } from "@/api/Users";
import analytics from "@/shared/analytics";
import config from "../config";
import packageJson from "../../package.json";

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
const firebaseProvider = new firebase.auth.OAuthProvider("microsoft.com");

firebaseProvider.setCustomParameters({
  tenant: config.microsoft.tenant,
});

// // This is for OneDrive/Sharepoint Org Files
// firebaseProvider.addScope("files.readwrite.all");
type Auth = {
  user: ValorUser;
  microsoftToken: string | null;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  fetchUser: () => Promise<void>;
  firebaseToken: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
};

const authContext = createContext<Auth>({
  user: null,
  microsoftToken: null,
  signIn: async () => {},
  signOut: async () => {},
  fetchUser: async () => {},
  firebaseToken: null,
  isAuthenticated: false,
  isLoading: true,
});
function useProvideAuth() {
  const [user, setUser] = useState<ValorUser | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [microsoftToken, setMicrosoftToken] = useState<string | null>(null);
  const [firebaseToken, setFirebaseToken] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const fetchUser = async (): Promise<ValorUser> => {
    try {
      const response = await getUserData();
      setUser(response);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const cypressToken = window.localStorage.getItem("vos_qaSecret--");

  const signIn = async () => {
    // if mobile, redirect else popup
    let response = null;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      ) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      response = await firebase.auth().signInWithRedirect(firebaseProvider);
    } else {
      response = await firebase.auth().signInWithPopup(firebaseProvider);
    }
    if (response.user) {
      const dbUser = await fetchUser();
      const idToken = await response.user.getIdToken();
      setFirebaseToken(idToken);
      setMicrosoftToken(response?.credential?.accessToken);
      if (!dbUser?.profilePicture) {
        getProfilePic(response?.credential?.accessToken);
      }
      setIsAuthenticated(true);
      setLoading(false);
    }
  };

  const signOut = async () => {
    await firebase.auth().signOut();
    setUser(null);
    setIsAuthenticated(false);
    setLoading(false);
  };

  useLayoutEffect(() => {
    const interceptor = axios.interceptors.request.use(async (requestConfig) => {
      if (
        requestConfig.url.startsWith("/api/")
        || requestConfig.url.startsWith("/theia-api/")
      ) {
        const controller = new AbortController();
        if (!user?.id) {
          controller.abort();
        }
        const modifiedConfig = { ...requestConfig };
        modifiedConfig.baseURL = config.api.ingress;
        modifiedConfig.headers.post["Content-Type"] = "application/json";

        if (cypressToken) {
          modifiedConfig.headers.common.Authorization = `Bearer ${cypressToken}`;
        } else {
          const idToken = await firebase.auth().currentUser.getIdToken();
          modifiedConfig.headers.common.Authorization = `Bearer ${idToken}`;
        }
        return modifiedConfig;
      }
      return requestConfig;
    });
    return () => axios.interceptors.request.eject(interceptor);
  }, [user?.id, cypressToken]);

  useEffect(() => {
    if (user?.id) {
      const identify = new analytics.Identify();
      identify.set("version", packageJson.version);
      if (user.email) {
        identify.set("email", user.email);
      }
      if (user.department) {
        identify.set("department", user.department);
      }
      analytics.identify(identify);

      // LogRocket.identify(user.id, {
      //   name: user?.name,
      //   email: user.email,
      //   department: user.department,
      //   picture: user?.profilePicture,
      // });
    }
  }, [user]);

  useLayoutEffect(() => {
    const fetchData = async () => {
      if (cypressToken) {
        const dbUser = await fetchUser();
        setFirebaseToken(null);
        setUser({
          ...dbUser,
          id: "49a18fac-a63e-57fc-abe6-d8e6b1ba5913",
          name: "Labs QA",
          firstName: "Labs",
          lastName: "QA",
          email: "labsqa@valorep.com",
          roleId: 18,
        });
        setIsAuthenticated(true);
        setLoading(false);
      }
    };
    fetchData();
  }, [cypressToken]);

  useLayoutEffect(() => {
    const fetchData = async (firebaseUser) => {
      if (firebaseUser) {
        const token = await firebaseUser.getIdToken();
        setFirebaseToken(token);
        await fetchUser();
        setIsAuthenticated(true);
      }
      setLoading(false);
    };

    const unsubscribeIdTokenChange = firebase.auth().onIdTokenChanged(async (savedUser) => {
      await fetchData(savedUser);
    });

    firebase
      .auth()
      .getRedirectResult()
      .then(async (response) => {
        console.log("redirect resp", response);
        if (response.user) {
          await fetchData(response.user);
          setMicrosoftToken(response?.credential?.accessToken);
        }
        return response.user;
      })
      .then(async (responseUser) => {
        if (responseUser) {
          const token = await responseUser?.getIdToken();
          setFirebaseToken(token);
        }
      });

    return () => unsubscribeIdTokenChange();
  }, []);

  return {
    user,
    microsoftToken,
    fetchUser,
    signIn,
    signOut,
    firebaseToken,
    isAuthenticated,
    isLoading,
  };
}

// eslint-disable-next-line react/prop-types
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  // eslint-disable-next-line react/jsx-filename-extension
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = (): Auth => useContext<Auth>(authContext);
export type User = ValorUser;
