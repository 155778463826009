import React from "react";
import {
  useParams, useNavigate, Routes, Route, Navigate,
} from "react-router-dom";
import Page from "@/ui/molecules/Page";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import IHEChange from "@/pages/IHEChange/IHEChanges";
import {
  Box, Typography, useMediaQuery, Theme,
} from "@mui/material";
import { useQuery } from "react-query";
import {
  getActiveVSVDealsWeeklyMeeting,
  getActiveGrowthDealsWeeklyMeeting,
} from "@/api/Reporting";
import dayjs from "dayjs";
import Agenda from "./Agenda";
import MeetingNotesPages from "./Notes/MeetingNotesPages";
import { agendaActions, flattenData } from "./utils";
import GrowthVSVPipelineReview from "./PipelineReview/GrowthVSV";
import TitlePage from "./TitlePage";
import ScaleGroupApprovals from "./ScaleGroupApprovals";
import FundAdmin from "./FundAdministration";
import ValorFundraising from "./ValorFundraising";
import DealPassReport from "./DealPass";
import FirstMeetingsReport from "./FirstMeetingReports";

export function WeeklyMeeting() {
  const { "*": typepage } = useParams<{ type: string; page?: string }>();
  const [type, page] = typepage.split("/");
  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const titleMapping = {
    "weekly-ic": isSmDown ? "Weekly IC Meeting" : "One Firm - Weekly Investment Committee Meeting",
    standup: isSmDown ? "Investment Standup" : "Investment Standup Weekly Meeting",
  };
  const title = titleMapping[type];
  const navigate = useNavigate();
  const tabs = type === "weekly-ic"
    ? agendaActions["weekly-ic"]
    : flattenData(agendaActions[type]).filter(
      (x) => x.uselessParent !== true,
    );

  // HACK TO PRELOAD
  const announcementStatuses = [
    "Investment Team Drafting",
    "Company Review",
    "IR Review",
    "Legal Review",
  ];
  useQuery(
    ["VSVDealData", announcementStatuses],
    async () => getActiveVSVDealsWeeklyMeeting(
      null,
      announcementStatuses,
      null,
      null,
    ),
    {
      staleTime: 10 * 60 * 1000,
    },
  );

  useQuery(
    ["GrowthDealData", announcementStatuses],
    async () => getActiveGrowthDealsWeeklyMeeting(
      null,
      null,
      null,
      announcementStatuses,
      null,
    ),
    {
      staleTime: 10 * 60 * 1000,
    },
  );

  const closeDate = dayjs().subtract(1, "week").format("YYYY-MM-DD");
  useQuery(
    ["PipelineReview", "Won", undefined, undefined, undefined, closeDate],
    async () => Promise.all([
      getActiveGrowthDealsWeeklyMeeting(
        "Won",
        undefined,
        undefined,
        undefined,
        closeDate,
      ).catch(() => []),
      getActiveVSVDealsWeeklyMeeting("Won", undefined, undefined, closeDate).catch(
        () => [],
      ),
    ]),
    {
      staleTime: 10 * 60 * 1000,
    },
  );

  // HACK TO PRELOAD

  return (
    <Page>
      <Page.Title>{title}</Page.Title>
      <Page.Content>
        <Box mb={5}>
          <ButtonTabs
            width="100%"
            tabLabel="Agenda"
            wrap
            disableTest={(o) => o?.disabled === true}
            activeKey={page}
            onClick={(option) => navigate(`${type}/${option.link}`)}
            options={tabs}
          />
        </Box>
        <Routes>
          <Route
            path="weekly-ic"
            element={<Navigate to="agenda" replace />}
          />
          <Route
            path="standup"
            element={<Navigate to="agenda" replace />}
          />
          <Route
            path="weekly-ic/investment-ihe-review/*"
            element={<IHEChange />}
          />
          <Route
            path="weekly-ic/deal-passes"
            element={<DealPassReport />}
          />
          <Route
            path="weekly-ic/requested-scale-group-approvals/:subPage?/:subPage2?"
            element={<ScaleGroupApprovals />}
          />
          <Route
            path="weekly-ic/requested-investment-approvals"
            element={<GrowthVSVPipelineReview />}
          />
          <Route
            path="weekly-ic/in-progress/*"
            element={<GrowthVSVPipelineReview />}
          />
          <Route
            path="weekly-ic/completed-since-last-ic"
            element={<GrowthVSVPipelineReview />}
          />

          <Route
            path="weekly-ic/portfolio-financing/:subPage?/*"
            element={<MeetingNotesPages />}
          />
          <Route path=":type/agenda" element={<Agenda />} />
          <Route
            path="weekly-ic/smaller-group-discussion"
            element={(
              <TitlePage>
                <Typography variant="h1">Smaller Group Discussion</Typography>
              </TitlePage>
            )}
          />
          <Route
            path="weekly-ic/partner-group-discussion"
            element={(
              <TitlePage>
                <Typography variant="h1">Partner Group Discussion</Typography>
              </TitlePage>
            )}
          />
          <Route
            path="weekly-ic/fund-admin/:subPage?/*"
            element={<FundAdmin />}
          />
          <Route
            path="weekly-ic/valor-fundraising/:subPage?"
            element={<ValorFundraising />}
          />
          <Route
            path="weekly-ic/first-meeting"
            element={<FirstMeetingsReport />}
          />
        </Routes>
      </Page.Content>
    </Page>
  );
}

export default WeeklyMeeting;
