import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { DataTable } from "@/components/LocalTable";
import { Typography, Link } from "@mui/material";
import {
  CompanyCell,
  TextCell,
  UserCell,
  OpportunityNoteCell,
} from "@/components/Table/Primitives";
import { getVSVOnBoarding } from "@/api/Reporting";
import Progress from "@/ui/atoms/Progress";
import MeetingPage from "../MeetingPage";

const statusOrder = [
  "Complete",
  "Scheduled",
  "Scheduling",
  "Intro Required",
  "Intro Planned",
  "TBD",
  "On Hold",
  "Pre-Investment",
];

export function OnboardingRevGen() {
  const { data: opps = [], isLoading } = useQuery(
    ["Onboarding"],
    async () => {
      const opportunities = await getVSVOnBoarding();
      return opportunities.sort((a, b) => {
        const aStatus = statusOrder.indexOf(a.onboardingStatus);
        const bStatus = statusOrder.indexOf(b.onboardingStatus);
        return aStatus - bStatus;
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    }
  );

  const columns = useMemo(
    () => [
      {
        header: "Deal Name",
        accessorFn: (row) => row.name,
        cell: (prop) => (
          <Typography
            sx={(t) => ({
              color: t.palette.text.primary,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "normal",
            })}
          >
            <Link
              href={`https://valor.affinity.co/opportunities/${prop.row.original.opportunityId}`}
              target="_blank"
              rel="noreferrer"
            >
              {prop.getValue()}
            </Link>
          </Typography>
        ),
        id: "name",
        sortingFn: "alphanumeric",
        minSize: 250,
      },
      {
        header: "Company",
        accessorFn: (row) => row.organizations,
        cell: (prop) =>
          prop.getValue() ? <CompanyCell value={prop.getValue()[0]} /> : null,
        id: "company",
        minSize: 200,
        size: 200,
      },
      {
        header: "Status",
        accessorFn: (row) => row.onboardingStatus,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        disableSortBy: true,
        disableFilters: true,
        minSize: 100,
        size: 120,
      },
      {
        header: "Onboarding Date",
        accessorFn: (row) => row.onboardingDate,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        disableSortBy: true,
        disableFilters: true,
        minSize: 100,
        size: 120,
      },
      {
        header: "Update",
        cell: (prop) => {
          const { opportunityId } = prop.row.original;
          return (
            <OpportunityNoteCell
              opportunityId={opportunityId}
              daysAgo={720}
              noteCount={1}
            />
          );
        },
        id: "notes",
        disableSortBy: true,
        disableFilters: true,
        minSize: 350,
        size: 550,
      },
      {
        header: "Investment Type",
        accessorFn: (row) => row.investmentType,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        disableSortBy: true,
        disableFilters: true,
        minSize: 100,
        size: 120,
      },
      {
        header: "Lead Fund",
        accessorFn: (row) => row.primaryFund,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        disableSortBy: true,
        disableFilters: true,
        minSize: 100,
        size: 120,
      },
      {
        header: "MSL",
        accessorFn: (row) => row.msl,
        cell: (prop) => <UserCell value={prop.getValue()} />,
        id: "msl",
        sortingFn: (rowA, rowB) =>
          rowA.original.msl.lastName.localeCompare(rowB.original.msl.lastName),
        enableColumnFilter: false,
        minSize: 100,
        size: 180,
      },
    ],
    []
  );

  if (isLoading) {
    return <Progress />;
  }

  return (
    <MeetingPage title="Onboarding">
      <DataTable columns={columns} data={opps} />
    </MeetingPage>
  );
}
export default OnboardingRevGen;
