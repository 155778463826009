import React, { useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Link,
  Stack,
  Tooltip,
  Collapse,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import UserAvatar from "@/ui/atoms/UserAvatar";
import dayjs from "dayjs";
import { styled } from "@mui/system";
import { useLocation, Link as RouterLink } from "react-router-dom";
import IHEChangeCellIcon from "@/pages/IHEChange/IHEIcon";
import { FrameworkChoiceColorIconMapping } from "@/constants/FrameworkChoiceColorIconMapping";
import SignalChip from "@/components/SignalChip";
import { useAuth } from "@/hooks/useAuth";
import CommentIcon from "@mui/icons-material/Comment";
import { DEPARTMENT_LABELS } from "./utils";
import KeyMaterials from "./KeyMaterials";

const AvengerIcon = FrameworkChoiceColorIconMapping.blue;

const colorMap = {
  AGREE: "green",
  DISAGREE: "red",
  DISCUSS: "gray",
};

const mapTitles = {
  AGREE: "Approve",
  DISAGREE: "Disapprove",
  DISCUSS: "Discuss",
};

function LabeledDetails({ children }: { children: React.ReactNode }) {
  return (
    <Box
      minWidth={{
        xs: "100%",
        sm: "100%",
        md: "100%",

        xl: 700,
      }}
      maxWidth={{
        xs: "100%",
        sm: "100%",
        md: "100%",
        xl: 700,
      }}
    >
      {children}
    </Box>
  );
}

const DetailText = styled(Typography)(() => ({
  height: 80,
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 4,
  textOverflow: "ellipsis",
  lineHeight: "20px",
}));

const DetailHeader = Typography;
const BlueChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.blue,
}));

export default function FirstMeetingResponseItem({
  response,
  canSeeVotes = false,
  canVote = false,
  collapseVoted = false,
}: {
  response: any;
  canSeeVotes?: boolean;
  canVote?: boolean;
  collapseVoted?: boolean;
}) {
  const location = useLocation();
  const { user } = useAuth();
  const [collapse, setCollapse] = React.useState(false);
  const isBetweenSmAndLg = useMediaQuery((theme) => theme.breakpoints.between("sm", "lg"));
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const {
    investmentType,
    recommendation,
    description,
    roundOverview,
    teamAssigned,
    partnerAssigned,
    avengerSymbol,
    avengerSymbolComment,
  } = response.metadata;
  const {
    fimoicaSignal, keyMaterials, monitoringServiceLead,
  } = response;

  const investmentBlurb = `${investmentType} [${
    DEPARTMENT_LABELS[response.department]
  }]`;

  const { yesVotes, noVotes, discussVotes } = response.votes
    ? Object.values(response.votes).reduce(
      (acc, vote) => {
        acc[
          vote.vote === "AGREE"
            ? "yesVotes"
            : vote.vote === "DISAGREE"
              ? "noVotes"
              : "discussVotes"
        ].push(vote);
        return acc;
      },
      { yesVotes: [], noVotes: [], discussVotes: [] },
    )
    : { yesVotes: [], noVotes: [], discussVotes: [] };

  const userVote = response.votes ? response.votes[user.id] : null;

  const voteBlurb = userVote
    ? `You responded  ${mapTitles[userVote.vote]} on ${dayjs(
      userVote.createdAt,
    ).format("MM/DD/YYYY")}`
    : null;

  useEffect(() => {
    if (collapseVoted && userVote) {
      setCollapse(true);
    }
  }, [userVote, collapseVoted]);

  const votesRecorded = !!(
    yesVotes.length
    + noVotes.length
    + discussVotes.length
  );

  const voteRender = userVote ? (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
        padding: {
          xs: 1,
        },
        border: (t) => `1px solid ${t.palette[colorMap[userVote?.vote]].main}`,
        borderRadius: (t) => t.spacing(1),
      }}
    >
      <IHEChangeCellIcon color={colorMap[userVote?.vote]} size="big" />
      {isSmDown ? null : <Typography variant="body2">{voteBlurb}</Typography>}
    </Box>
  ) : null;

  const getTooltipVoteBlurb = (vote) => {
    let blurb = `${vote.user.firstName} ${vote.user.lastName}`;
    if (vote.commentary) {
      blurb += `: ${vote.commentary}`;
    }
    return blurb;
  };

  const voteStates = [
    { color: "green", text: "Agree", votes: yesVotes },
    { color: "red", text: "Disagree", votes: noVotes },
    { color: "gray", text: "Discuss", votes: discussVotes },
  ];

  const voteBox = canSeeVotes ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
        },
        justifyContent: "space-between",
        alignItems: "center",
        border: (t) => `1px solid ${t.palette.divider}`,
        borderRadius: (t) => t.spacing(1),
        padding: 1,
        "&>div": {
          padding: 1,
          width: "100%",
        },
        height: {
          sm: "auto",
        },
        width: {
          xs: "100%",
          sm: "auto",
        },
      }}
    >
      {votesRecorded ? (
        <>
          {voteStates.map((voteState) => (
            <Box
              key={voteState.color}
              display="flex"
              justifyContent="flex-start"
              sx={{
                gap: "4px",
              }}
              alignItems="center"
            >
              <IHEChangeCellIcon color={voteState.color} size="big" />
              <Box display="flex" sx={{ gap: "4px" }}>
                {voteState.votes.map((vote) => (
                  <Tooltip
                    disableFocusListener
                    title={getTooltipVoteBlurb(vote)}
                    key={vote.user.id}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      <UserAvatar user={vote.user} />
                      {vote.commentary ? (
                        <CommentIcon
                          sx={{
                            color: (t) => t.palette.blue.main,
                            position: "absolute",
                            top: -8,
                            left: -16,
                          }}
                        />
                      ) : null}
                    </Box>
                  </Tooltip>
                ))}
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <Typography> No Votes Recorded Yet</Typography>
      )}
    </Box>
  ) : (
    isSmDown ? (
      null
    ) : <Box>{voteRender}</Box>

  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap={{
        xs: 1,
        sm: 2,
      }}
      padding={{
        xs: 1,
        sm: 2,
      }}
      width="100%"
      flexWrap="wrap"
      sx={{
        background: (t) => (userVote && collapseVoted
          ? t.palette.background.blue
          : t.palette.background.paper),
      }}
    >
      <Collapse
        sx={{
          minWidth: "100%",
        }}
        collapsedSize={isSmDown ? "48px" : isBetweenSmAndLg ? "120px" : "130px"}
        in={!collapse}
      >
        <Grid container>
          <Grid item xs={12} lg={4}>
            <Box
              display="flex"
              gap={{
                xs: 1,
                sm: 2,
              }}
              flexDirection={{
                xs: "column",
                sm: "row",
                lg: "column",
              }}
            >
              <Box
                display="flex"
                flexDirection={{
                  xs: "column",
                  sm: "row",
                }}
                gap={2}
                alignItems={{
                  xs: "flex-start",
                  sm: "center",
                }}
                width={{
                  xs: "100%",
                  sm: "auto",
                }}
              >
                <Box display="flex" alignItems="center" width={{ xs: "296px" }}>
                  <Box
                    flex={{
                      xs: "1 1 100%",
                      lg: "0 1 200px",
                    }}
                    minWidth={200}
                  >
                    <Link
                      href={`/org/${response.organization.valorId}`}
                      target="_blank"
                      rel="noreferrer"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 2,
                        textDecoration: "none",
                      }}
                    >
                      <CompanyAvatar
                        name={response.organization.name}
                        domain={response.organization.domain}
                        src={response.organization.logoUrl}
                        size="40"
                      />
                      <Box>
                        <Typography
                          variant="h3"
                          sx={{
                            color: "text.primary",
                          }}
                        >
                          {response.organization.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "text.secondary",
                          }}
                        >
                          {response.organization.domain}
                        </Typography>
                      </Box>
                    </Link>
                  </Box>
                  <Box>
                    {isSmDown ? (
                      voteRender
                    ) : (
                      <Box sx={{ width: "96px" }}>
                        <SignalChip
                          signal={fimoicaSignal}
                          showIcon
                          showName={false}
                          showBorder
                          valorId={response.organization.valorId}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>

              <Divider orientation="vertical" flexItem />
              <Box
                display="flex"
                flexDirection={{
                  xs: "row",
                  lg: "column",
                }}
              >
                <Box display="flex" flexDirection="column" gap={1}>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Box>
                      <Stack>
                        <Typography variant="boldSubtitle2">
                          Completed on
                        </Typography>
                        <Typography variant="body1">
                          {dayjs(response.completedAt).format("MM/DD/YYYY")}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection={{
                        xs: "column",
                        sm: "row",
                      }}
                      gap={1}
                    >
                      <Box>
                        <Typography variant="boldSubtitle2">MSL</Typography>
                        <Box>
                          {monitoringServiceLead ? (
                            <UserAvatar
                              user={monitoringServiceLead}
                              displayTooltip
                            />
                          ) : (
                            <>N/A</>
                          )}
                        </Box>
                      </Box>

                      <Box>
                        <Typography variant="boldSubtitle2">
                          Partner Assigned
                        </Typography>
                        <Box>
                          {partnerAssigned?.length ? (
                            <UserAvatar
                              user={partnerAssigned[0]}
                              displayTooltip
                            />
                          ) : (
                            <>N/A</>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="boldSubtitle2">
                      Team Assigned
                    </Typography>
                    <Box display="flex" sx={{ gap: "4px" }}>
                      {teamAssigned?.length ? (
                        teamAssigned.map((u) => (
                          <UserAvatar key={u.id} user={u} displayTooltip />
                        ))
                      ) : (
                        <>N/A</>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Box
              flex={{
                xs: "1 1 100%",
                lg: "1 1 300px",
              }}
              display="flex"
              flexDirection="column"
              gap={1}
              flexWrap={{
                xs: "wrap",
                lg: "nowrap",
              }}
            >
              <Box
                display="flex"
                flexDirection={{
                  xs: "column-reverse",
                  sm: "row",
                }}
                justifyContent="flex-start"
                gap={{
                  xs: 1,
                  sm: 16,
                  md: 4,
                }}
              >
                {avengerSymbol === "blue" && (
                  <Box display="flex" flexDirection="column">
                    <DetailHeader variant="boldSubtitle2">
                      Avenger Symbol
                    </DetailHeader>
                    <Box sx={{ color: (t) => t.palette[avengerSymbol].main }}>
                      <AvengerIcon />
                      <Typography>
                        {" "}
                        {avengerSymbolComment}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {recommendation && (
                  <Box>
                    <DetailHeader variant="boldSubtitle2">
                      Recommendation
                    </DetailHeader>
                    <Box
                      display="flex"
                      sx={{
                        gap: "4px",
                        alignItems: "center",
                        padding: 1,
                        borderRadius: 1,
                        width: "fit-content",
                        border: (t) => `1px solid ${
                          t.palette[recommendation.choice.color].main
                        }`,
                      }}
                    >
                      <IHEChangeCellIcon
                        size="big"
                        color={recommendation.choice.color}
                      />
                      <Typography variant="boldBody2">
                        {recommendation.choice.text}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {isSmDown && fimoicaSignal && (
                  <Box>
                    <DetailHeader variant="boldSubtitle2">
                      MOIC Prediction
                    </DetailHeader>
                    <Box sx={{ width: "96px" }}>
                      <SignalChip
                        signal={fimoicaSignal}
                        showIcon
                        showName={false}
                        showBorder
                        valorId={response.organization.valorId}
                      />
                    </Box>
                  </Box>
                )}
                {(investmentType || DEPARTMENT_LABELS[response.department]) && (
                  <Box display="flex" flexDirection="column">
                    <DetailHeader variant="boldSubtitle2">
                      Investment Type
                    </DetailHeader>
                    <Box>
                      <BlueChip label={investmentBlurb} />
                    </Box>
                  </Box>
                )}
              </Box>
              {description && (
                <LabeledDetails>
                  <DetailHeader variant="boldSubtitle2">
                    Description
                  </DetailHeader>
                  <DetailText variant="body2">
                    {description}
                  </DetailText>
                </LabeledDetails>
              )}
              {recommendation.commentary && (
                <LabeledDetails>
                  <DetailHeader variant="boldSubtitle2">
                    Recommendation Notes
                  </DetailHeader>
                  <DetailText variant="body2">
                    {recommendation.commentary}
                  </DetailText>
                </LabeledDetails>
              )}

              {roundOverview && (
                <LabeledDetails>
                  <DetailHeader variant="boldSubtitle2">
                    Round Overview
                  </DetailHeader>
                  <DetailText variant="body2">{roundOverview}</DetailText>
                </LabeledDetails>
              )}
              {keyMaterials?.length > 0 && (
                <LabeledDetails>
                  <DetailHeader variant="boldSubtitle2">
                    Key Materials
                  </DetailHeader>
                  <DetailText variant="body2">
                    <KeyMaterials keyMaterials={keyMaterials} />
                  </DetailText>
                </LabeledDetails>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} lg={2}>
            <Box
              display="flex"
              flexDirection={{
                xs: "column",
              }}
              gap={1}
              flex={{
                xs: "1 1 100%",
                sm: "1 1 200px",
                lg: "0 1 400px",
              }}
              justifyContent={{
                xs: "space-between",
                sm: "flex-start",
              }}
            >
              <Button
                variant={
                  userVote ? "outlined" : canVote ? "contained" : "outlined"
                }
                size="small"
                to={
                  `/opportunity-assessment-reports/${response.organization.valorId}?responseId=${response.id}&`
                  + `source=${encodeURIComponent(
                    location.pathname + location.search,
                  )}`
                }
                sx={{
                  textAlign: "center",
                  backgroundColor: (t) => {
                    if (userVote) {
                      return t.palette.background.blue;
                    }
                    if (canVote) {
                      return t.palette.primary.main;
                    }
                    return t.palette.background.paper;
                  },
                }}
                LinkComponent={RouterLink}
              >
                {userVote ? "View Report" : canVote ? "Respond" : "View Report"}
              </Button>
              {voteBox}
            </Box>
          </Grid>
        </Grid>
      </Collapse>
      <Button
        sx={{
          visibility: userVote ? "visible" : "hidden",
        }}
        variant="outlined"
        onClick={() => setCollapse((o) => !o)}
      >
        <Typography
          variant="body2"
          sx={{
            color: "text.primary",
          }}
        >
          {collapse ? "Show More" : "Show Less"}
        </Typography>
      </Button>
    </Box>
  );
}
