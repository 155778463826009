import React from "react";
import { Box } from "@mui/material";
import { components } from "@/api/index";
import Note from "./Note";
import {
  CapitalMarketsActive,
  CapitalMarketsClosed,
  MergersAndAcquisitionActive,
  MergersAndAcquisitionClosed,
  LiquidityEventActive,
  LiquidityEventClosed,
  MeetingType,

} from "../meeting-types";

type NoteModel = components["schemas"]["Note"];
type NotesListProps = {
  notes: NoteModel[];
  userId: string;
  hideControls?: boolean;
  editNote: (documentId: string) => void;
  viewNote: (documentId: string) => void;
  deleteNote: (documentId: string) => void;
};

function NotesList({
  notes,
  userId,
  editNote,
  viewNote,
  deleteNote,
  hideControls,
}: NotesListProps) {
  const shouldEdit = (note: NoteModel) => {
    if (hideControls) {
      return false;
    }
    if (userId === note.authorId) {
      return true;
    }
    if (
      [CapitalMarketsActive,
        CapitalMarketsClosed,
        MergersAndAcquisitionActive,
        MergersAndAcquisitionClosed,
        LiquidityEventActive,
        LiquidityEventClosed].indexOf(note.meetingType as MeetingType) > -1
    ) {
      return true;
    }
    return false;
  };
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {notes.map((note) => (
        <Note
          key={note.documentId}
          note={note}
          showEdit={shouldEdit(note)}
          showDelete={!hideControls && note.authorId === userId}
          showViewNote={!hideControls}
          showCopyLink={!hideControls}
          editNote={() => editNote(note.documentId)}
          deleteNote={() => deleteNote(note.documentId)}
          viewNote={() => viewNote(note.documentId)}
        />
      ))}
    </Box>
  );
}

export default NotesList;
