import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { Box, ButtonBase } from "@mui/material";
import Affinity from "@/ui/atoms/Icons/Affinity";
import MeetingPage from "../MeetingPage";
import UpcomingMeetings from "./UpcomingMeetings";
import FundAnnouncements from "./FundAnnouncements";
import LPConsent from "./LPConsent";

const options = {
  "investment-memos": {
    label: "Investment Announcements",
    key: "investment-memos",
    id: 62347,
    showButton: false,
  },
  "upcoming-meetings": {
    label: "Upcoming LP/AB Meetings",
    key: "upcoming-meetings",
    id: 227475,
    showButton: true,
  },
  "lp-consents": {
    label: "LP/AB Consents",
    key: "lp-consents",
    id: 227475,
    showButton: true,
  },
};
const choices = Object.values(options);
export function FundAdmin() {
  const { subPage } = useParams<{ subPage: string }>();
  const navigate = useNavigate();
  const active = options[subPage];
  if (!active) {
    navigate(`/weekly-meeting/weekly-ic/fund-admin/${choices[0].key}`);
  }

  return (
    <MeetingPage title={active.label}>
      <Box m={1} display="flex" justifyContent="space-between">
        <ButtonTabs
          width="512px"
          tabLabel="Report Type"
          activeKey={subPage}
          options={choices}
          onClick={(o) => navigate(`/weekly-meeting/weekly-ic/fund-admin/${o.key}`)}
        />
        {active.showButton ? (
          <ButtonBase
            href="https://valor.affinity.co/lists/227475"
            target="_blank"
            rel="noreferrer"
            sx={(t) => ({
              border: `1px solid ${t.palette.divider}`,
              padding: 1,
              borderRadius: t.spacing(1),
              display: "flex",
              alignItems: "center",
              color: "primary.main",
              "&:hover": {
                color: "primary.dark",
              },
            })}
          >
            <Affinity />
            Edit Report
          </ButtonBase>
        ) : null }
      </Box>
      {subPage === "upcoming-meetings" && <UpcomingMeetings />}
      {subPage === "investment-memos" && <FundAnnouncements />}
      {subPage === "lp-consents" && <LPConsent />}
    </MeetingPage>
  );
}
export default FundAdmin;
