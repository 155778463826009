import {
  // isInAlpha,
  canReadManagementDashboard,
  canSeeVPO,
  canSeeMetricTasks,
  canVoteOnFirstMeeting,
  canSeeWeeklyMeeting,
  canSeeCompliance,
  canSeeMarketMaps,
  canSeeSearchBarDealSearch,
  canSeeFirmList,
  canSeeHome,
  canSeePCAPAudit,
  canSeeUntaggedDocs,
  canSeeRevGen,
  canSeeProcess,
  canSeeSignalModelPipeline,
  canCreateOrgs,
  canSeeDeals,
  canSeeAARVertical,
} from "@/constants/Roles";

import {
  AccountBalance,
  AddCircleOutline,
  Assessment,
  Assignment,
  CheckCircleOutline,
  CorporateFare,
  Construction,
  Description,
  DoneAll,
  Edit,
  Group,
  Home,
  HourglassEmpty,
  MonetizationOn,
  NoteAdd,
  PostAdd,
  Report,
  Search,
  ShowChart,
  SvgIconComponent,
  TrackChanges,
  TrendingFlat,
  Visibility,
} from "@mui/icons-material";
import { User } from "@/hooks/useAuth";

type CanSeeFunction = (user: User) => boolean;

interface Destination {
  name: string;
  path: string;
  canSee: CanSeeFunction;
  Icon?: SvgIconComponent;
  subtitle?: string;
  disableCmdK?: boolean;
}

export const destinations = {
  "After Action Reviews": {
    name: "After Action Reviews",
    path: "/aar-dashboard",
    canSee: canSeeAARVertical,
  },
  "Closed Investments": {
    Icon: DoneAll,
    name: "Closed Investments",
    path: "/investment-reports/closed-last-seven-days",
    subtitle: "Review investments recently closed",
    canSee: (u) => canSeeProcess(u, "deals"),
  },
  "Considering for Term Sheet": {
    name: "Considering for Term Sheet",
    Icon: Description,
    subtitle: "Review opportunities being considered for term sheet",
    path: "/investment-process/considering-for-ts",
    canSee: (u) => canSeeProcess(u, "deals"),
  },
  "Create Organization": {
    name: "Create Organization",
    Icon: CorporateFare,
    path: "/organization",
    canSee: canCreateOrgs,
  },
  "Deal Search": {
    Icon: Search,
    name: "Deal Search",
    path: "/search",
    canSee: canSeeSearchBarDealSearch,
  },
  Following: {
    Icon: Visibility,
    name: "Following Opportunities",
    path: "/investment-reports/company-follow-list",
    subtitle: "Review status of following opportunities",
    canSee: (u) => canSeeProcess(u, "deals"),
  },
  "Finance Metric Tasks": {
    name: "Finance Metric Tasks",
    path: "/metric-tasks",
    canSee: canSeeMetricTasks,
  },
  "Finance Tracker": {
    name: "Finance Tracker",
    path: "/process-management/finance",
    canSee: (u) => canSeeProcess(u, "finance"),
  },
  "Funds (Active / In Consideration)": {
    name: "Funds (Active / In Consideration)",
    path: "/process-management/funds?view=table",
    canSee: (u) => canSeeProcess(u, "funds"),
  },
  "Fund Administration": {
    name: "Fund Administration",
    path: "/process-management/lp-ab?view=table",
    canSee: (u) => canSeeProcess(u, "lp-ab"),
  },
  HomeInvest: {
    Icon: ShowChart,
    name: "Investment Group",
    path: "/home/investments",
    canSee: canSeeHome,
  },
  HomeIR: {
    Icon: MonetizationOn,
    name: "Investor Relations",
    path: "/home/i-r",
    canSee: canSeeHome,
  },
  HomeInfra: {
    Icon: AccountBalance,
    name: "Infrastructure",
    path: "/home/infrastructure",
    canSee: canSeeHome,
  },
  HomeScale: {
    Icon: Construction,
    name: "Scale Group",
    path: "/home/scale-group",
    canSee: canSeeHome,
  },
  Home: {
    name: "Home",
    Icon: Home,
    path: "/home",
    canSee: canSeeHome,
  },

  "Human Capital": {
    name: "Human Capital",
    path: "/valor-people-ops",
    canSee: canSeeVPO,
  },
  "IHE Changes": {
    name: "IHE Changes",
    path: "/ihe-changes",
    canSee: (u) => canSeeWeeklyMeeting(u),
  },
  "Investment Announcements": {
    name: "Investment Announcements",
    path: "/investment-reports/investment-announcements",
    canSee: (user) => canSeeProcess(user, "deals"),
  },

  IT: {
    name: "IT",
    path: "/process-management/it",
    canSee: (u) => canSeeProcess(u, "it"),
  },
  "IT (CEO/CIO)": {
    name: "IT (CEO/CIO)",
    path: "/process-management/itceo",
    canSee: (u) => canSeeProcess(u, "itceo"),
  },
  "Lean Growth": {
    name: "Lean Growth",
    path: "/process-management/leangrowth",
    canSee: (u) => canSeeProcess(u, "leangrowth"),
  },
  Legal: {
    name: "Legal",
    path: "/process-management/legal",
    canSee: (u) => canSeeProcess(u, "legal"),
  },
  "LP / AB Meetings & Consents": {
    name: "LP / AB Meetings & Consents",
    path: "/process-management/lp-ab?view=table",
    canSee: (u) => canSeeProcess(u, "lp-ab"),
  },
  "Market Maps": {
    name: "Market Maps",
    path: "/market-maps",
    canSee: canSeeMarketMaps,
  },
  "Meeting Recordings": {
    name: "Meeting Recordings",
    path: "/meetings",
    canSee: canReadManagementDashboard,
  },
  "New Opportunities - Pre OA": {
    Icon: HourglassEmpty,
    name: "Pre-OA Status",
    subtitle: "Review opportunities in Pre OA stage",
    path: "/investment-process/new-opps-pre-oa",
    canSee: (u) => canSeeDeals(u),
  },
  "New Opportunity Distribution": {
    Icon: Report,
    name: "New Opportunity Distribution",
    subtitle: "Top of funnel activity (qualified v. unqualified)",
    path: "/investment-process/newly-added-opps",
    canSee: (u) => canSeeDeals(u),
  },
  "New Opportunity": {
    Icon: AddCircleOutline,
    name: "New / Edit Opportunity",
    path: "/process-management/deals?view=table&sort=%5B%7B%22id%22%3A%22tasks.fieldValues.a31088f0-0993-4c00-a3d1-04bf3be8a0e6.choiceId%22%2C%22desc%22%3Afalse%7D%5D&filter=%5B%7B%22id%22%3A%22tasks.fieldValues.a31088f0-0993-4c00-a3d1-04bf3be8a0e6.choiceId%22%2C%22value%22%3A%5B%7B%22value%22%3A%22421eb50c-7522-0508-3563-ab0b9302b488%22%2C%22label%22%3A%22IC%2BApproved%2Bto%2BClose%22%7D%2C%7B%22value%22%3A%22ffc04fd1-e834-d42b-7983-02497aa7cd47%22%2C%22label%22%3A%22IC%2BApproval%2Bto%2BClose%22%7D%2C%7B%22value%22%3A%22e52a34e9-b3cb-8c4f-6771-20b180834860%22%2C%22label%22%3A%22Term%2BSheet%2BApproved%2Bby%2BIC%22%7D%2C%7B%22value%22%3A%22ba617fa4-5825-8040-da39-fb1374a7e583%22%2C%22label%22%3A%22IC%2BTerm%2BSheet%2BApproval%22%7D%2C%7B%22value%22%3A%2228d03b76-6d74-47f0-aee7-c82ef8387959%22%2C%22label%22%3A%22Considering%2Bfor%2BTerm%2BSheet%22%7D%2C%7B%22value%22%3A%22830ba2b3-6902-3cba-15cb-64b5ff3773ec%22%2C%22label%22%3A%22IC%2BOpportunity%2BAssessment%2BApproval%22%7D%2C%7B%22value%22%3A%225c8f6ed1-e464-0376-7c20-579e3418a556%22%2C%22label%22%3A%22Pre%2BOpportunity%2BAssessment%22%7D%2C%7B%22value%22%3A%2263cdc524-c097-9ff0-09b6-8cfb59267f9b%22%2C%22label%22%3A%22New%2BOpportunity%22%7D%5D%7D%5D&visibility=%7B%7D&order=%5Bobject%2BObject%5D",
    subtitle: "Create or edit an investment opportunity",
    canSee: (u) => canSeeDeals(u),
  },
  "Opportunity Assessments": {
    name: "OA Approvals",
    path: "/opportunity-assessment-reports",
    canSee: (user) => canVoteOnFirstMeeting(user),
    Icon: CheckCircleOutline,
    subtitle: "IC approval of opportunity assessments",
  },
  "Opportunity Assessment - Create": {
    Icon: Edit,
    name: "Create Opportunity Assessment",
    // survey widget
    path: "", // TODO
    subtitle: "Create or edit an opportunity assessment",
    canSee: () => true,
  },
  "Pass Follow Throughs": {
    Icon: TrendingFlat,
    name: "Pass Follow Through",
    path: "/investment-process/pass-follow-through",
    subtitle: "Monitor pass follow through activity",
    canSee: (u) => canSeeProcess(u, "deals"),
  },
  "PCAP Audit": {
    name: "PCAP Audit",
    path: "/pdf-pcap-audit",
    canSee: canSeePCAPAudit,
  },
  "PortCo Financing Activity": {
    name: "PortCo Financing Activity",
    path: "/financing-report/liquidity-events",
    canSee: (u) => canSeeWeeklyMeeting(u),
  },
  "Fund Portfolio CRM": {
    name: "Portfolio Management Process",
    path: "/process-management/portfolio?view=table",
    canSee: (u) => canSeeProcess(u, "portfolio"),
  },
  "Portfolio Surveys": {
    name: "Portfolio Surveys",
    path: "/portfolio-monitoring",
    canSee: canReadManagementDashboard,
  },
  "Post Closing Actions": {
    Icon: PostAdd,
    name: "Post-Closing Items",
    subtitle: "Review status of post closing actions",
    path: "/investment-reports/post-closing-actions",
    canSee: (u) => canSeeProcess(u, "deals"),
  },
  "Research Projects": {
    name: "Research Projects",
    path: "/process-management/research",
    canSee: (u) => canSeeProcess(u, "research"),
  },
  "Revenue Generation": {
    name: "Revenue Generation",
    path: "/rev-ops/rev-gen",
    canSee: canSeeRevGen,
  },
  "Signal Model Opportunities": {
    name: "Signal Model Opportunities",
    path: "/signal-model-pipeline/pipeline",
    canSee: canSeeSignalModelPipeline,
  },
  "Sourcing Lists": {
    name: "Sourcing Lists",
    path: "/sourcing-lists",
    canSee: canSeeFirmList,
  },
  "Survey Drafts": {
    Icon: Assessment,
    canSee: () => true,
    subtitle: "Drafts of surveys in progress",
    name: "Survey Drafts",
    path: "/home/drafts",
  },
  "Survey Management": {
    name: "Survey Management",
    path: "/management-dashboard",
    canSee: canReadManagementDashboard,
  },
  "Under Term Sheet": {
    Icon: Assignment,
    name: "Under Term Sheet",
    subtitle: "Review opportunities under a term sheet",
    path: "/investment-process/under-ts",
    canSee: (u) => canSeeProcess(u, "deals"),
  },
  "Untagged Documents": {
    name: "Untagged Docs",
    path: "/untagged-documents",
    canSee: canSeeUntaggedDocs,
  },
  "User Management": {
    name: "User Management",
    path: "/compliance-and-permissions",
    canSee: canSeeCompliance,
  },
  "vOS Opportunity CRM": {
    name: "vOS CRM",
    Icon: TrackChanges,
    path: "/process-management/deals?view=table&sort=%5B%7B%22id%22%3A%22dateAdded%22%2C%22desc%22%3Atrue%7D%5D",
    canSee: canSeeDeals,
  },
  "Weekly IC Meeting": {
    name: "Investment Committee",
    Icon: Group,
    subtitle: "Weekly investment committee meeting materials",
    path: "/weekly-ic/weekly-ic/agenda",
    canSee: canSeeWeeklyMeeting,
  },
  "Write a Note": {
    Icon: NoteAdd,
    name: "Write a Note",
    subtitle: "Document and note relevant details",
    disableCmdK: true,
    // note widget.
    path: "", // TODO
    canSee: () => true,
  },
} as Record<string, Destination>;

export default destinations;
