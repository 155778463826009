import React, { useState, useMemo } from "react";
import {
  Box, Button, Typography, Stack,
  Skeleton,
} from "@mui/material";
import { omit } from "lodash";
import { updateOrgFieldValue, OrgValues } from "@/api/Organization";

import Input from "@/components/FormInputs/Input";

const typeMap = {
  company: "company",
  person: "person",
  person_multi: "person",
  user: "user",
  user_multi: "user",
  select: "choiceId",
  select_multi: "choiceId",
};

export default function OrgFieldValues({
  valorId,
  fieldValues,
  refetchOrgValues,
}: {
  valorId: string;
  fieldValues: OrgValues["data"];
  refetchOrgValues: () => void;
}) {
  const [masks, setMasks] = useState({});
  const [saving, setSaving] = useState(false);
  const fields = useMemo(() => fieldValues?.toSorted((a, b) => (
    a.field.sort - b.field.sort
  )) || [], [fieldValues]);

  // combine mask + field values
  const formValues = useMemo(() => fields.map((value) => {
    const mask = masks[value.field.key];
    return {
      ...value,
      value: mask || value.value,
    };
  }), [fields, masks]);

  function getValue(field, value) {
    if (!value) {
      return null;
    }
    const values = value.map((v) => v[typeMap[field.type] || "value"]);
    if (field.type.includes("multi") && field.type !== "text_multi") {
      return values;
    }
    return values[0];
  }

  function setValue(field, value) {
    if (field.type.includes("multi") && field.type !== "text_multi") {
      return value.map((v) => ({
        [typeMap[field.type] || "value"]: v,
      }));
    }
    return [{
      [typeMap[field.type] || "value"]: value,
    }];
  }

  function handleOnChange(field, value) {
    setMasks({
      ...masks,
      [field.key]: setValue(field, value),
    });
  }

  function toApiValue(field, value) {
    const apiTypeMap = {
      company: "valorId",
      person: "personId",
      person_multi: "personId",
      user: "userId",
      user_multi: "userId",
      select: "choiceId",
      select_multi: "choiceId",
      default: "value",
    };
    const apiAccessor = {
      company: (v) => v.company.valorId,
      person: (v) => v.person.id,
      person_multi: (v) => v.person.id,
      user: (v) => v.user.id,
      user_multi: (v) => v.user.id,
      select: (v) => v.choiceId,
      select_multi: (v) => v.choiceId,
      default: (v) => v.value,
    };
    return value.map((v) => ({
      [apiTypeMap[field.type] || apiTypeMap.default]:
        apiAccessor[field.type]?.(v) || apiAccessor.default(v),
    })).filter((v) => v[apiTypeMap[field.type] || apiTypeMap.default]);
  }

  async function submitChange() {
    // take the mask value and update the field
    const maskCopy = { ...masks };
    // for each mask field, update the field value
    const keys = Object.keys(maskCopy);
    const updatePromises = [];
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const maskValue = maskCopy[key];

      const { field } = fieldValues.find((v) => v.field.key === key);
      const value = toApiValue(field, maskValue);

      // create update promise
      updatePromises.push(updateOrgFieldValue(
        valorId,
        field.id,
        value,
      ));
    }

    setSaving(true);
    await Promise.all(updatePromises);
    await refetchOrgValues();
    setSaving(false);
    // once complete, clean mask and refetch
    setMasks(
      omit(masks, keys),
    );
  }

  if (!fieldValues?.length) {
    return (
      <Stack spacing={1}>
        <Typography variant="h2">Field Values</Typography>
        <Skeleton variant="rounded" height={40} />
        <Skeleton variant="rounded" height={40} />
        <Skeleton variant="rounded" height={40} />
        <Skeleton variant="rounded" height={40} />
      </Stack>
    );
  }
  return (
    <Box>
      <Typography variant="h2" sx={{ my: 1 }}>Field Values</Typography>
      <form
        onSubmit={(e) => {
          submitChange();
          e.preventDefault();
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
        >
          {formValues.map(({ field, value }) => (
            <Box key={field.key}>
              <Input
                type={field.type}
                name={field.label}
                value={getValue(field, value)}
                onChange={(newValue) => handleOnChange(field, newValue)} // add onChange
                onBlur={() => {}} // add onBlur
                // required={field.required}
                choices={field.choices}
                disabled={saving}
              />
            </Box>
          ))}

          <Box
            display="flex"
            justifyContent="flex-start"
            gap={1}
          >
            <Button
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                setMasks({});
              }}
            >
              Reset Form
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}
