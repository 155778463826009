import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import dayjs from "dayjs";
import { Box, Card } from "@mui/material";
import { useQuery } from "react-query";
import { getActiveGrowthDealsWeeklyMeeting, getActiveVSVDealsWeeklyMeeting } from "@/api/Reporting";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useQueryParam, StringParam } from "use-query-params";
// import DealsReport from "@/pages/DealsReport";
import {
  FundList, VSVStatuses, GrowthStatuses, FundListAffinityKey,
} from "../utils";
import PipelineReviewTable from "./PipelineReviewTable";
import SignalModelFund from "./SignalModelFund";

const filteredFundList = FundList.filter((fund) => fund !== "Fund III");

export default function GrowthVSVPipelineReview() {
  const [fundNameParam, setFund] = useQueryParam("fund", StringParam);

  const navigate = useNavigate();
  const location = useLocation();

  const icPage = "requested-investment-approvals";
  const tsPage = "in-progress/under-ts";
  const cPage = "in-progress/considering-for-ts-review";
  const lPage = "completed-since-last-ic";
  const smPage = "in-progress/signal-model-fund";

  const isICPage = location.pathname.includes(icPage);
  const isTSPage = location.pathname.includes(tsPage);
  const isCPage = location.pathname.includes(cPage);
  const isLPage = location.pathname.includes(lPage);
  const isSMPage = location.pathname.includes(smPage);

  const active = fundNameParam || filteredFundList[0];

  const [statefulCloseDate, setCloseDate] = useState(
    dayjs().subtract(1, "week").format("YYYY-MM-DD"),
  );

  let changedSince = dayjs().subtract(1, "month").format("YYYY-MM-DD");
  changedSince = undefined;

  let icDate;
  let nextStep;
  let status;
  let closeDate;

  if (isICPage) {
    // vsv prop
    icDate = dayjs().format("YYYY-MM-DD");
    // growth prop
    nextStep = "IC";
  }

  if (isTSPage) {
    status = "TS";
  }

  if (isCPage) {
    status = "Considering";
  }

  if (isLPage) {
    status = "Won";
    closeDate = statefulCloseDate;
  }

  const fund = active;

  const { data: dealData = [], isLoading } = useQuery(
    ["PipelineReview", status, changedSince, nextStep, icDate, closeDate],
    async () => Promise.all([
      getActiveGrowthDealsWeeklyMeeting(
        GrowthStatuses[status],
        changedSince,
        nextStep,
        null,
        closeDate,
      ).catch(() => []),
      getActiveVSVDealsWeeklyMeeting(
        VSVStatuses[status],
        null,
        icDate,
        closeDate,
      ).catch(() => []),
    ]),
    {
      staleTime: 10 * 60 * 1000,
    },
  );

  const fundData = React.useMemo(() => {
    const allDeals = dealData[0]?.concat(dealData[1]) || [];
    // filter by target fund
    if (fund) {
      return allDeals.filter(
        (deal) => deal.targetFund === FundListAffinityKey[fund],
      );
    }
    return allDeals;
  }, [dealData, fund]);

  const allDataByFund = React.useMemo(() => {
    const allDeals = dealData[0]?.concat(dealData[1]) || [];
    return filteredFundList.reduce(
      (acc, fundName) => ({
        ...acc,
        [fundName]: allDeals.filter(
          (deal) => deal.targetFund === FundListAffinityKey[fundName],
        ),
      }),
      {},
    );
  }, [dealData, filteredFundList]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" flexDirection="column" gap={2}>
        {isCPage || isTSPage || isSMPage ? (
          <ButtonTabs
            width="625px"
            tabLabel="Status"
            options={[
              {
                key: "under-ts",
                label: "Under Term Sheet",
              },
              {
                key: "considering-for-ts-review",
                label: "Considering for TS",
              },
              {
                key: "signal-model-fund",
                label: "Signal Model Fund",
              },
            ]}
            activeKey={
              isCPage
                ? "considering-for-ts-review"
                : isSMPage
                  ? "signal-model-fund"
                  : "under-ts"
            }
            onClick={(option) => {
              navigate(`/weekly-meeting/weekly-ic/in-progress/${option.key}`);
            }}
            disableTest={({ disabled = false }) => disabled}
          />
        ) : null}
        {/* <DealsReport
          processId="deals"

        /> */}
        {!isSMPage ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <ButtonTabs
              width="720px"
              wrap
              tabLabel="Fund"
              activeKey={active}
              options={filteredFundList.map((fundName) => ({
                key: fundName,
                label: fundName,
              }))}
              disableTest={(option) => !allDataByFund[option.key]?.length}
              onClick={(option) => {
                setFund(option.key);
              }}
            />
            {isLPage ? (
              <DatePicker
                label="Close Date"
                format="YYYY-MM-DD"
                value={statefulCloseDate ? dayjs(statefulCloseDate) : null}
                onChange={(date) => {
                  setCloseDate(date.format("YYYY-MM-DD"));
                }}
              />
            ) : null}
          </Box>
        ) : null}
      </Box>
      <Card elevation={0} sx={{ height: "auto" }}>
        {!isSMPage && (
          <PipelineReviewTable
            fund={active}
            status={status}
            isICItems={isICPage || isLPage}
            fundData={fundData}
            isLoading={isLoading}
          />
        )}
        {isSMPage && <SignalModelFund />}
      </Card>
    </Box>
  );
}
