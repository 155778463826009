import React from "react";
import { Chip, Box } from "@mui/material";
import { searchPersons } from "@/api/People";
import SAYT from "@/components/SAYT";
import renderTextWithHighlighting from "@/utils/renderTextWithHighlighting";

type PersonInputValue = {
  id: string;
  firstName: string;
  lastName: string;
  primaryEmail: string;
};

type PersonInputProps = {
  value?: PersonInputValue;
  onChange: (value: PersonInputValue | PersonInputValue[]) => void;
  multiple?: boolean;
  required?: boolean;
  disabled?: boolean;
  label?: string;
};

export default function PersonInput({
  value,
  onChange,
  multiple,
  required,
  disabled,
  label,
  error,
}: PersonInputProps) {
  return (
    <SAYT
      value={multiple ? value || [] : value}
      onChange={onChange}
      multiple={multiple}
      disabled={disabled}
      style={{ width: "100%" }}
      label={label}
      search={(term) => searchPersons(null, term)}
      getOptionSelected={(option, selectedOption) => option.id === selectedOption.id}
      getOptionLabel={(option: { firstName: string; lastName: string; }) => (
        option ? `${option.firstName} ${option.lastName}` : ""
      )}
      useDefaultFilter={false}
      required={required}
      renderTags={(tags, getTagProps) => (
        <>
          {tags.map((option, index) => (
            <Chip
              key={option.id}
              sx={{ margin: 0.5 }}
              label={`${option.firstName} ${option.lastName}`}
            // eslint-disable-next-line react/jsx-props-no-spreading
              {...getTagProps({ index })}
            />
          ))}
        </>
      )}
      renderOption={(option, { inputValue }) => (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          py={1}
        >
          <span>
            {renderTextWithHighlighting(
              `${option.firstName} ${option.lastName}`,
              inputValue,
            )}
          </span>
          <span>
            {renderTextWithHighlighting(
              option.primaryEmail,
              inputValue,
            )}
          </span>
        </Box>
      )}
      error={error}
    />
  );
}
