import React from "react";
import {
  Box, Typography, Link,
  TableCell, TableRow, Table, TableHead,
  Skeleton, Alert, useMediaQuery,
} from "@mui/material";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import CompanyCell from "@/components/Table/Primitives/CompanyCell";
import dayjs from "dayjs";
import RichTextSerializer from "@/ui/molecules/RichTextEditor/Serializer";
import { TableHeaderCell, TableBody } from "../../GroupedTable";

type Note = {
  authorName: string;
  profilePicture: string;
  documentId: string;
  org: {
    valorId: string;
  };
  funds: {
    isGrowthFund4: boolean;
    isGrowthFund5: boolean;
    isGrowthFund6: boolean;
    isVofFund: boolean;
    isCvConsortioFund: boolean;
    isSeedFund1: boolean;
    isVsvFund1: boolean;
    isVsvFund2: boolean;
  }
  meetingDate: number;
  createdAt: number;
  updatedAt: number;
  richText: any;
};

function NoteCard({
  note,
}: {
  note: Note;
}) {
  const updatedTs = dayjs(note.updatedAt * 1000);
  const isLastSevenDays = updatedTs.isAfter(dayjs().subtract(7, "days").startOf("day"));
  return (
    <Box borderRadius={1} backgroundColor="background.paper" padding={1}>
      <Box display="flex" flexDirection="row" gap={1} alignItems="center">
        <Box display="flex" flexDirection="row" sx={{ alignItems: "center" }} gap={2}>
          <Box>
            <Typography variant="boldBody1">
              <Link
                href={`/org/${note.org.valorId}/notes/edit/${note.documentId}`}
                target="_blank"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  color: "text.primary",
                }}
              >
                {note.title}
                <OpenInNewIcon fontSize="small" />
              </Link>
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={isLastSevenDays ? {
              color: (theme) => theme.palette.primary.main,
              border: (theme) => `1px solid ${theme.palette.primary.main}`,
              borderRadius: 1,
              padding: 0.5,
            } : null}
          >
            {"Updated: "}
            {updatedTs.format("MMM D, YYYY")}
          </Typography>
        </Box>
      </Box>
      <Box
        // hack to overwrite note styles
        color={(theme) => `${theme.palette.text.primary} !important`}
      >
        {RichTextSerializer.toJSX(note.richText)}
      </Box>
    </Box>
  );
}

export default function CompanyNotesTable({
  noteType,
  companyNotes,
  isLoading,
  error,
}: {
  noteType: string,
  companyNotes: Note[][],
  isLoading: boolean,
  error: any,
}) {
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        padding={2}
      >
        <Skeleton variant="rectangular" height={75} />
        <Skeleton variant="rectangular" height={75} />
        <Skeleton variant="rectangular" height={75} />
        <Skeleton variant="rectangular" height={75} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        padding={2}
      >
        <Alert severity="error">
          An error occurred. Please try again later or contact the labs team.
        </Alert>
      </Box>
    );
  }
  if (!companyNotes.length) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        padding={2}
      >
        <Alert severity="info" data-cy="no-data-message">
          No
          {" "}
          {noteType}
          {" "}
          notes
        </Alert>
      </Box>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell>
            Company
          </TableHeaderCell>
          <TableHeaderCell>
            Notes
          </TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {companyNotes.map((notes) => (
          <TableRow key={notes[0]?.documentId}>
            <TableCell sx={{ width: isSmDown ? "48px" : "320px" }}>
              <CompanyCell
                value={notes[0]?.org}
              />
            </TableCell>
            <TableCell>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
              >
                {notes.sort((a, b) => b.updatedAt - a.updatedAt).map((note) => (
                  <NoteCard note={note} key={note.documentId} />
                ))}
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
