import React, { useState } from "react";
import { useQuery } from "react-query";
import ValorFolio from "@/ui/atoms/ValorFolio";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Link,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Organization } from "@/api/Organization";
import { queryMapsForOrg } from "@/api/MarketMap";

interface Props {
  company: Organization;
}

const MapBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "8px",
  maxWidth: "440px",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    gap: "4px",
    maxWidth: "320px",
  },
}));

const MapItem = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  width: "calc(50% - 16px)", // Adjust for gap
  minWidth: "140px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    minWidth: "100%",
  },
}));

function MarketMapFolio({ company }: Props) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const { data: maps = [], isLoading } = useQuery(
    ["MarketMapForCo", company.valorId],
    async () => queryMapsForOrg(company.valorId)
  );

  const renderMap = ({ title, id, numOfCompanies, lastEdited }) => (
    <MapItem key={id}>
      <Link
        sx={{
          color: theme.palette.text.primary,
          height: theme.spacing(5),
          display: "flex",
          alignItems: "center",
        }}
        href={`/market-maps/view/${id}`}
      >
        <Typography variant="tableHeader">{title}</Typography>
      </Link>
      <Typography variant="subtitle2">
        {numOfCompanies ? `${numOfCompanies} Companies` : null}
      </Typography>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle2" mr={1}>
          {lastEdited ? (
            <Tooltip title="Updated on">
              {`${dayjs(lastEdited).format("MM/DD/YYYY")}`}
            </Tooltip>
          ) : null}
        </Typography>
      </Box>
    </MapItem>
  );
  const displayedMaps = expanded ? maps : maps.slice(0, 2);

  return (
    <ValorFolio title="MARKET MAPS" sx={{ height: "100%" }}>
      <MapBox>
        {isLoading ? (
          <>
            <Skeleton variant="rounded" width="200px" height="100px" />
            <Skeleton variant="rounded" width="200px" height="100px" />
          </>
        ) : maps.length > 0 ? (
          <>
            {displayedMaps.map(renderMap)}
            {maps.length > 2 && (
              <Box width="100%" mt={2}>
                <Button
                  onClick={() => setExpanded(!expanded)}
                  variant="outlined"
                  fullWidth
                >
                  {expanded ? "Show Less" : `Show ${maps.length - 2} More`}
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Typography mt={1}>No Market Maps</Typography>
        )}
      </MapBox>
    </ValorFolio>
  );
}

export default MarketMapFolio;
