import React from "react";
import {
  Grid, Typography, Link, Skeleton, Box, Tooltip,
} from "@mui/material";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import Section from "@/ui/atoms/Section";
import { formatTotal } from "@/utils/numberFormat";
import UserAvatar from "@/ui/atoms/UserAvatar";
import EmailIcon from "@mui/icons-material/Email";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Organization,
  getOrganizationFeaturesById,
  getOrganizationInteractions,
} from "@/api/Organization";
import SectorChip from "@/components/SectorChip";
import LabeledDetail from "@/components/LabeledDetail";
import MarketMapsFolio from "./MarketMapsFolio";
import SignalFolio from "./SignalsFolio";

interface Props {
  company: Organization
}
export function GeneralInformation({ company }: Props) {
  const { valorId } = company;
  const EMPLOYEE_FEATURE_TYPE = "li_org_employee_total";

  const { data: mostRecentEmployeeCount, isLoading: isLoadingEmployee } = useQuery(
    ["CURRENT_EMPLOYEE", EMPLOYEE_FEATURE_TYPE, valorId],
    async () => {
      const response = await getOrganizationFeaturesById(valorId, [EMPLOYEE_FEATURE_TYPE]);
      const val = null;
      if (response?.features) {
        const employees = response.features
          .reduce((x) => x.type === EMPLOYEE_FEATURE_TYPE)?.data;
        const { length } = employees;
        return employees[length - 1].value;
      }
      return val;
    },
    {
      retry: 1,
    },
  );

  const { data: recentInteractions = [], isLoading: isLoadingInteractions } = useQuery(
    ["RecentInteractions", valorId],
    async () => getOrganizationInteractions(valorId),
    {
      retry: 1,
    },
  );

  let locationText = "";
  if (company.country !== "US") {
    locationText = [
      company?.city,
      company?.state,
      company?.country,
    ].filter(Boolean)
      .join(", ");
  } else if (!company.city && !company.state) {
    locationText = "US";
  } else {
    locationText = [
      company?.city,
      company?.state,
    ].filter(Boolean)
      .join(", ");
  }
  locationText = locationText.toUpperCase();

  const interactionTypeIcon = {
    email: <EmailIcon />,
    meeting: <CalendarMonthIcon />,
  };

  return (
    <Section>
      <Section.Title>General Information</Section.Title>
      <Section.Content>
        <Grid spacing={2} container>
          <Grid item xs={6} sm={3} lg={3}>
            <LabeledDetail title="WEBSITE">
              <Link href={`https://www.${company.domain}`}>
                {company.domain}
              </Link>
            </LabeledDetail>
          </Grid>
          <Grid item xs={6} sm={3} lg={3}>
            <LabeledDetail title="EMPLOYEES">
              {isLoadingEmployee ? (
                <Skeleton width="48px" variant="text" />
              ) : mostRecentEmployeeCount ? (
                <Typography variant="body2">
                  {formatTotal(mostRecentEmployeeCount)}
                </Typography>
              ) : (
                <Typography variant="overline">---</Typography>
              )}
            </LabeledDetail>
          </Grid>
          <Grid item xs={6} sm={3} lg={3}>
            <LabeledDetail title="LOCATION">
              {locationText.length ? (
                <Typography variant="subtitle2">{locationText}</Typography>
              ) : (
                <Typography variant="overline">---</Typography>
              )}
            </LabeledDetail>
          </Grid>
          <Grid item xs={6} sm={3} lg={3}>
            <LabeledDetail title="FOUNDED">
              <Typography variant="subtitle2">
                {company.yearFounded ? (
                  company.yearFounded
                ) : (
                  <Typography variant="overline">---</Typography>
                )}
              </Typography>
            </LabeledDetail>
          </Grid>
          <Grid item xs={12}>
            <LabeledDetail
              title="DESCRIPTION"
              expandable={!!company.description}
            >
              <Typography variant="body2">
                {company.description ? (
                  company.description
                ) : (
                  <Typography variant="overline">---</Typography>
                )}
              </Typography>
            </LabeledDetail>
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
            <LabeledDetail title="MONITORING SERVICE LEAD">
              <Box
                mt={1}
                display="inline-flex"
                flexWrap="wrap"
                sx={{ gap: "8px" }}
              >
                {company.monitoringServiceLead ? (
                  <UserAvatar
                    user={company.monitoringServiceLead}
                    displayTooltip
                  />
                ) : (
                  <Typography variant="overline">---</Typography>
                )}
              </Box>
            </LabeledDetail>
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
            <LabeledDetail
              title="LAST INTERACTION(S)"
              expandable={recentInteractions.length > 1}
            >
              <Box
                mt={1}
                display="flex"
                flexDirection="column"
                flexWrap="wrap"
                sx={{ gap: "8px" }}
              >
                {isLoadingInteractions ? (
                  <Skeleton variant="rectangular" />
                ) : recentInteractions?.length ? (
                  recentInteractions.map(
                    ({
                      id, date, interactionType, persons,
                    }) => (
                      <Box
                        key={id}
                        display="flex"
                        alignItems="center"
                        gap="2px"
                      >
                        {persons.slice(0, 2).map((p) => (
                          <UserAvatar
                            style={{ height: "32px", width: "32px" }}
                            key={p.id}
                            user={p}
                            displayTooltip
                          />
                        ))}
                        {persons.length > 2 ? (
                          <Tooltip
                            title={persons
                              .slice(2)
                              .map((p) => `${p.firstName} ${p.lastName}`)
                              .join(", ")}
                          >
                            <Box display="flex" padding={0.25}>
                              <Typography
                                sx={{ cursor: "pointer" }}
                                variant="body2"
                              >
                                +
                                {persons.length - 2}
                              </Typography>
                            </Box>
                          </Tooltip>
                        ) : null}

                        {interactionTypeIcon[interactionType]}
                        {dayjs(date).format("MM/DD/YY")}
                      </Box>
                    ),
                  )
                ) : null}
              </Box>
            </LabeledDetail>
          </Grid>
          <Grid item xs={12} xl={3}>
            <LabeledDetail title="VALOR INDUSTRY CLASSIFICATION">
              <Box
                mt={1}
                display="inline-flex"
                flexWrap="wrap"
                sx={{ gap: "8px", height: "100%" }}
              >
                {company.predictedSectors?.length ? (
                  company.predictedSectors?.map((s) => (
                    <SectorChip key={s} sectorType={s} />
                  ))
                ) : (
                  <Typography variant="overline">---</Typography>
                )}
              </Box>
            </LabeledDetail>
          </Grid>
          <Grid item xs={12} md={8} lg={8} xl={6}>
            <SignalFolio company={company} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={6}>
            <MarketMapsFolio company={company} />
          </Grid>
        </Grid>
      </Section.Content>
    </Section>
  );
}

export default GeneralInformation;
