import React from "react";
import { useQuery } from "react-query";
import { getDrafts } from "@/api/FrameworkSurvey";
import { Box, Typography, Skeleton, useMediaQuery, Card } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import { InvestmentProductMappings } from "@/constants/InvestmentProductsMapping";
import dayjs from "dayjs";

export function DraftCard() {
  const { data: drafts = [], isLoading } = useQuery("Drafts", async () =>
    getDrafts(null)
  );
  const navigate = useNavigate();
  const isMdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box>
      {!isMdDown && (
        <Box>
          <Typography
            sx={{
              "& > a": {
                color: (t) => t.palette.primary.main,
                textDecoration: "none",
              },
            }}
            variant="h4"
          >
            <Link to="/">Home</Link> &gt; Survey Drafts
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          color: (theme) => theme.palette.text.secondary,
        }}
        gap={1}
      >
        {isLoading && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={60} />
          </Box>
        )}
        {!isLoading && drafts.length === 0 && (
          <Card>
            <Box
              p={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>You have no drafts</Typography>
            </Box>
          </Card>
        )}
        {drafts.map((draft) => (
          <Card key={draft.id}>
            <Box
              gap={1}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "72px",
                overflow: "hidden",
                flexDirection: "row",
                padding: (theme) => theme.spacing(1),
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: (theme) => theme.palette.action.hover,
                },
              }}
              onClick={() =>
                navigate(
                  `/org/${draft.organization.valorId}/surveys/${draft.surveyId}?responseId=${draft.id}`
                )
              }
            >
              <Box
                sx={{
                  minWidth: 40,
                  minHeight: 40,
                  maxWidth: 40,
                  maxHeight: 40,
                }}
              >
                <CompanyAvatar src={draft.organization.logoUrl} />
              </Box>
              <Box>
                <Typography>
                  {draft.organization.name} -{draft.name} -{" "}
                  {InvestmentProductMappings[draft.department]}
                </Typography>
                <Typography variant="caption">
                  {dayjs
                    .utc(draft.lastModified)
                    .local()
                    .format("MM/DD/YYYY hh:mm a")}
                </Typography>
              </Box>
            </Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
}

export default DraftCard;
