import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useTheme } from "@mui/material/styles";
import { Organization } from "@/api/Organization";
import { getProcess, getProcessTasks } from "@/api/Process";
import Section from "@/ui/atoms/Section";
import { Chip } from "@mui/material";
import dayjs from "dayjs";
import { formatBigFundingRoundDollars } from "@/utils/numberFormat";
import MiniTable from "@/components/MiniTable";
import LabeledDetail from "@/components/LabeledDetail";

interface Props {
  company: Organization;
}

export function ValorFunding({ company }: Props) {
  const { valorId } = company;

  const columnAccessors = useMemo(
    () => ({
      Name: "fieldValues.44fcec37-381d-bfae-6c4f-e4f530fb376e.value",
      Stage: "fieldValues.a31088f0-0993-4c00-a3d1-04bf3be8a0e6.choiceId",
      "Investment Type":
        "fieldValues.5ab7cc9e-fe49-4488-b033-e71103fae725.choiceId",
      "Lead Type": "fieldValues.aea69848-6f72-4745-8d1c-b4b53200c317.choiceId",
      "Target Fund":
        "fieldValues.24f4430a-25a5-4d87-beda-5c3c3467ed31.choiceId",
      "Investment Amount":
        "fieldValues.83ea8f7a-d8de-4379-8f91-d0aa9cd32bef.value",
      "Target Close Date":
        "fieldValues.b92d82b2-089c-405e-9628-fe32a479efe9.value",
      "Pass Date": "fieldValues.e0ae21e1-1535-4d0d-a2da-03423e2de836.value",
    }),
    []
  );
  const hardcodedFilters = {
    AND: {
      eq: {
        "tasks.fieldValues.e46f4ec3-b130-4b19-8a9a-94ff0bb42732.company": [
          valorId,
        ],
      },
    },
  };

  const {
    data: processData = {
      name: "",
      fields: [],
      fieldFilters: [],
      organizationFields: [],
      settings: [],
      lifecycleStages: [],
    },
    isLoading: isProcessLoading,
  } = useQuery(["process", "deals"], async () => getProcess("deals"));
  const combinedFields = useMemo(
    () => [...processData.fields, ...processData.organizationFields],
    [processData.fields, processData.organizationFields]
  );

  const theme = useTheme();

  const colorCoding = useMemo(
    () => ({
      "New Opportunity": {
        backgroundColor: theme.palette.blue.highlight,
        color: theme.palette.getContrastText(theme.palette.blue.highlight),
      },
      "Pre Opportunity Assessment": {
        backgroundColor: theme.palette.blue.highlight,
        color: theme.palette.getContrastText(theme.palette.blue.highlight),
      },
      "IC Opportunity Assessment Approval": {
        backgroundColor: theme.palette.blue.highlight,
        color: theme.palette.getContrastText(theme.palette.blue.highlight),
      },
      "Considering for Team Sheet": {
        backgroundColor: theme.palette.blue.highlight,
        color: theme.palette.getContrastText(theme.palette.blue.highlight),
      },
      "IC Term Sheet Approval": {
        backgroundColor: theme.palette.teal.highlight,
        color: theme.palette.getContrastText(theme.palette.teal.highlight),
      },
      "Considering for TS": {
        backgroundColor: theme.palette.teal.highlight,
        color: theme.palette.getContrastText(theme.palette.teal.highlight),
      },
      "Term Sheet Approved by IC": {
        backgroundColor: theme.palette.teal.highlight,
        color: theme.palette.getContrastText(theme.palette.teal.highlight),
      },
      "IC Approval to Close": {
        backgroundColor: theme.palette.blue.main,
        color: theme.palette.getContrastText(theme.palette.blue.main),
      },
      "IC Approved to Close": {
        backgroundColor: theme.palette.blue.main,
        color: theme.palette.getContrastText(theme.palette.blue.main),
      },
      "Post Closing Items": {
        backgroundColor: theme.palette.green.highlight,
        color: theme.palette.getContrastText(theme.palette.green.highlight),
      },
      "Post Closing Complete": {
        backgroundColor: theme.palette.green.highlight,
        color: theme.palette.getContrastText(theme.palette.green.highlight),
      },
      Pass: {
        backgroundColor: theme.palette.text.disabled,
        color: theme.palette.getContrastText(theme.palette.text.disabled),
      },
      Following: {
        backgroundColor: theme.palette.yellow.main,
        color: theme.palette.getContrastText(theme.palette.yellow.main),
      },
    }),
    [theme]
  );
  const columns = useMemo(
    () => [
      {
        id: "name",
        header: "Deal Name",
        accessorKey: columnAccessors.Name,
        width: 200,
        cell: ({ getValue }) => {
          const val = getValue();
          if (!val) {
            return null;
          }
          return val;
        },
      },
      {
        id: "fund",
        header: "Target Fund",
        accessorKey: columnAccessors["Target Fund"],
        cell: ({ getValue }) => {
          const val = getValue();
          if (!val) {
            return null;
          }
          const id = columnAccessors["Target Fund"].split(".")[1];
          const field = combinedFields.find((f) => f.id === id);
          const choice = field?.choices.find((c) => c.id === val);
          return choice?.value;
        },
      },
      {
        id: "type",
        header: "Type",
        accessorKey: columnAccessors["Investment Type"],
        cell: ({ getValue }) => {
          const val = getValue();
          if (!val) {
            return null;
          }
          const id = columnAccessors["Investment Type"].split(".")[1];
          const field = combinedFields.find((f) => f.id === id);
          const choice = field?.choices.find((c) => c.id === val);
          return choice?.value;
        },
      },
      {
        id: "amount",
        header: "Amount",
        accessorKey: columnAccessors["Investment Amount"],
        cell: ({ getValue }) => {
          const val = getValue();
          if (!val) {
            return null;
          }

          return formatBigFundingRoundDollars(val);
        },
        width: 85,
      },
      {
        id: "status",
        header: "Status",
        accessorKey: columnAccessors.Stage,
        width: "auto",
        cell: ({ getValue }) => {
          const val = getValue();
          if (!val) {
            return null;
          }
          const id = columnAccessors.Stage.split(".")[1];
          const field = combinedFields.find((f) => f.id === id);
          const choice = field?.choices.find((c) => c.id === val);
          return <Chip sx={colorCoding[choice?.value]} label={choice?.value} />;
        },
      },
      // todo
      {
        id: "close_date",
        header: "Close Date",
        accessorFn: (row) => {
          const stage =
            row.fieldValues[columnAccessors.Stage.split(".")[1]]?.choiceId;
          const id = columnAccessors.Stage.split(".")[1];
          const field = combinedFields.find((f) => f.id === id);
          const stageName = field?.choices.find((c) => c.id === stage)?.value;
          const passDate =
            row.fieldValues[columnAccessors["Pass Date"].split(".")[1]]?.value;
          const closeDate =
            row.fieldValues[columnAccessors["Target Close Date"].split(".")[1]]
              ?.value;

          if (stageName === "Pass" && passDate) {
            return passDate;
          }
          return closeDate;
        },
        width: "auto",
        cell: ({ getValue }) => {
          const val = getValue();
          if (!val) {
            return null;
          }
          return dayjs(Number(val)).format("YYYY-MM-DD");
        },
      },
    ],
    [columnAccessors, combinedFields, colorCoding]
  );

  const { data: tasks = { data: [] }, isLoading } = useQuery(
    ["ValorFunding", valorId],
    () =>
      getProcessTasks(
        ["deals"],
        500,
        null,
        [
          "-tasks.fieldValues.b92d82b2-089c-405e-9628-fe32a479efe9.number", // close date
        ],
        hardcodedFilters
      )
  );

  const sortedData = useMemo(() => {
    return [...tasks.data].sort((a, b) => {
      const getCloseDate = (row) => {
        const stage =
          row.fieldValues[columnAccessors.Stage.split(".")[1]]?.choiceId;
        const id = columnAccessors.Stage.split(".")[1];
        const field = combinedFields.find((f) => f.id === id);
        const stageName = field?.choices.find((c) => c.id === stage)?.value;
        const passDate =
          row.fieldValues[columnAccessors["Pass Date"].split(".")[1]]?.value;
        const closeDate =
          row.fieldValues[columnAccessors["Target Close Date"].split(".")[1]]
            ?.value;

        return stageName === "Pass" && passDate ? passDate : closeDate;
      };

      const dateA = getCloseDate(a);
      const dateB = getCloseDate(b);

      // Sort in descending order (newest first)
      return dateB - dateA;
    });
  }, [tasks.data, columnAccessors, combinedFields]);

  return (
    <Section>
      <Section.Title>Valor Funding</Section.Title>
      <Section.Content>
        <LabeledDetail
          title="VALOR INVESTMENTS"
          expandable={tasks.data?.length}
          maxLines={4}
          lineHeight="36px"
        >
          <MiniTable
            isLoading={isLoading || isProcessLoading}
            data={sortedData}
            columns={columns}
            emptyTable="No Valor Funding Opportunities "
          />
        </LabeledDetail>
      </Section.Content>
    </Section>
  );
}

export default ValorFunding;
