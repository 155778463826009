import React, { createContext, useContext, useEffect } from "react";
import io from "socket.io-client";
import { useAuth } from "@/hooks/useAuth";
import config from "../config";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export function SocketProvider({ children }) {
  let { ingress } = config.api;
  const { firebaseToken } = useAuth();
  if (process.env.NODE_ENV === "development") {
    ingress = "http://localhost:8080";
  }

  const socket = io(ingress, {
    path: "/socket-io",
    transports: ["websocket", "polling", "flashsocket"],
    auth: {
      token: firebaseToken,
    },
  });

  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
}
