import React, {
  useCallback, useContext, useRef, useState,
} from "react";
import {
  TableRow as MuiTableRow,
  Box,
  useTheme,
} from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { flexRender, SortingState, ColumnFiltersState } from "@tanstack/react-table";
import { isObject } from "@/utils/isObjectTypeGuard";

const ParentRefContext = React.createContext<React.RefObject<HTMLDivElement | null>>(null);
const useParentRef = () => {
  const parentRef = useContext(ParentRefContext);
  if (!parentRef) {
    throw new Error("ParentRefContext is null");
  }
  return parentRef;
};

export const TableRow = MuiTableRow;

export function VirtualTableContainer(
  {
    onScrollBottomReached,
    children,
    height,
    minHeight,
  }: {
    onScrollBottomReached?: () => void;
    children: React.ReactNode;
    height?: string;
    minHeight?: string;
  },
) {
  const theme = useTheme();
  // let defaultNegativeHeight = 350;
  let defaultNegativeHeight = 550;
  if (theme.breakpoints.values.md < window.innerWidth) {
    // defaultNegativeHeight = 250;
    defaultNegativeHeight = 450;
  }
  if (theme.breakpoints.values.sm < window.innerWidth) {
    // defaultNegativeHeight = 150;
    defaultNegativeHeight = 250;
  }

  const ref = useRef<HTMLDivElement>(null);
  const handleScroll = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollTop, scrollHeight, clientHeight } = containerRefElement;
        if (scrollHeight - scrollTop - clientHeight < defaultNegativeHeight) {
          onScrollBottomReached?.();
        }
      }
    },
    [onScrollBottomReached, defaultNegativeHeight],
  );

  return (
    <ParentRefContext.Provider value={ref}>
      <Box
        ref={ref}
        onScroll={(e) => handleScroll(e.target as HTMLDivElement)}
        sx={{
          position: "relative",
          height: {
            xs: height || "calc(100vh - 450px)",
            sm: height || "calc(100vh - 350px)",
            md: height || "calc(100vh - 450px)",
          },
          minHeight: minHeight || "500px",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </ParentRefContext.Provider>
  );
}

export function VirtualTableBody({
  rows,
  estimateSize,
  onRowClick,
  onRowDoubleClick,
  table,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rows: any[],
  estimateSize: number,
  onRowClick?: (row: any) => void,
  onRowDoubleClick?: (row: any) => void,
  table: any,
}) {
  const tableContainerRef = useParentRef();

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef?.current,
    count: rows.length,
    estimateSize: useCallback(() => estimateSize, [estimateSize]),
    overscan: 5,
    measureElement:
    typeof window !== "undefined"
    && navigator.userAgent.indexOf("Firefox") === -1
      ? (element) => element?.getBoundingClientRect().height
      : undefined,
  });

  const visibleColumns = table.getVisibleLeafColumns();
  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: visibleColumns.length - 1,
    getScrollElement: () => tableContainerRef?.current,
    estimateSize: (index) => visibleColumns[index + 1].getSize(),
    overscan: 3,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();

  const virtualColumns = columnVirtualizer.getVirtualItems();
  let virtualPaddingLeft = 0;
  let virtualPaddingRight = 0;
  if (columnVirtualizer && virtualColumns?.length) {
    virtualPaddingLeft = visibleColumns.reduce((acc, curr, index) => {
      if (index !== 0 && index < virtualColumns[0].index + 1) {
        return acc + curr.getSize();
      }
      return acc;
    }, 0);
    // ;virtualColumns[0]?.start ?? 0;
    virtualPaddingRight = columnVirtualizer.getTotalSize()
    - (virtualColumns[virtualColumns.length - 1]?.end ?? 0);
  }

  const [timer, setTimer] = useState(null);
  const handleTouchStart = useCallback((row) => {
    const timeout = setTimeout(() => {
      onRowDoubleClick(row);
    }, 500); // 500ms threshold for long press
    setTimer(timeout);
  }, [onRowDoubleClick]);

  const handleTouchEnd = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
  }, [timer]);

  const theme = useTheme();
  const [activeRow, setActiveRow] = useState(null);

  return (
    <tbody
      style={{
        display: "grid",
        height: `${totalSize}px`,
        position: "relative",
      }}
    >
      {virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];
        const visibleCells = row.getVisibleCells();

        if (!visibleColumns.length) return null;

        return (
          <tr
            data-index={virtualRow.index}
            ref={(node) => rowVirtualizer.measureElement(node)}
            key={row.id}
            style={{
              display: "flex",
              position: "absolute",
              transform: `translateY(${virtualRow.start}px)`,
              width: "100%",
              // height: `${virtualRow.size}px`,
              height: `${estimateSize}px`,
              maxHeight: `${estimateSize}px`,
              minHeight: `${estimateSize}px`,
              zIndex: activeRow === virtualRow.index ? 1 : 0,
            }}
            onClick={() => onRowClick?.(row)}
            onDoubleClick={() => onRowDoubleClick?.(row)}
            onTouchStart={() => handleTouchStart(row)}
            onTouchEnd={handleTouchEnd}
            onTouchCancel={handleTouchEnd}
            onMouseOver={() => setActiveRow(virtualRow.index)}
            onMouseOut={() => setActiveRow(null)}
          >
            <td
              style={{
                background: virtualRow.index % 2
                  ? theme.palette.background.oddTableRow
                  : theme.palette.background.paper,
                borderRight: `3px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.white.dark}`,
                boxSizing: "border-box",
                minWidth: `${visibleColumns[0]?.getSize()}px`,
                width: `${visibleColumns[0]?.getSize()}px`,
                maxWidth: `${visibleColumns[0]?.getSize()}px`,
              }}
            >
              {flexRender(visibleColumns[0].columnDef.cell, visibleCells[0].getContext())}
            </td>
            {virtualPaddingLeft ? (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <td
                style={{
                  display: "flex",
                  width: `${virtualPaddingLeft}px`,
                }}
              />
            ) : null}
            {virtualColumns.map((virtualColumn) => {
              const cell = visibleCells[virtualColumn.index + 1];
              if (!cell) return null;
              return (
                <td
                  key={cell.id}
                  style={{
                    background: cell.column.getIsFiltered()
                      ? theme.palette.background.activeTableColumn
                      : virtualRow.index % 2
                        ? theme.palette.background.oddTableRow
                        : theme.palette.background.paper,
                    borderBottom: `1px solid ${theme.palette.white.dark}`,
                    borderRight: `1px solid ${theme.palette.divider}`,
                    boxSizing: "border-box",
                    minWidth: `${cell.column.getSize()}px`,
                    width: `${cell.column.getSize()}px`,
                    maxWidth: `${cell.column.getSize()}px`,
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
            {virtualPaddingRight ? (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <td
                style={{
                  display: "flex",
                  width: `${virtualPaddingRight}px`,
                }}
              />
            ) : null}
          </tr>
        );
      })}
    </tbody>
  );
}

export const mapSorting = (sorting: SortingState): string[] => (
  sorting?.map((x) => `${x.desc ? "-" : ""}${x.id}`) || []
);

const parseValue = (val: Array<{ value: string }> | { value: string }) => {
  if (Array.isArray(val) && isObject(val[0])) {
    return val.map((x) => x.value);
  }
  if (isObject(val) && val.value !== undefined) {
    return val.value;
  }
  return val;
};

export const mapFilters = (
  columnFilters: ColumnFiltersState,
  globalFilter: string | null = "",
) => ({
  ...columnFilters.reduce(
    (acc, curr) => ({
      ...acc,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [curr.id]: parseValue(curr.value as any),
    }),
    {},
  ),
  ...(globalFilter?.length ? { "*": globalFilter } : {}),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const depageData = (data?: { pages: any[] }) => data?.pages.map((x) => x.data).flat() || [];

export const getNextPageParam = (lastPage) => lastPage?.markers?.next;
