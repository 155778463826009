import React from "react";
import {
  Typography, Box, Button,
  Breadcrumbs,
} from "@mui/material";
import { useQuery } from "react-query";
import { createNoteOnTask, getNotesByTask } from "@/api/Process";
import { createNote, queryCompanyNotes } from "@/api/Notes";
import { useAuth } from "@/hooks/useAuth";
import NotesList from "@/pages/Org/Notes/NotesList";
import PlainSerializer from "@/ui/molecules/RichTextEditor/Serializer/PlainSerializer";
import Editor from "./Editor";

const VIEW_VIEW = "view";
const ADD_VIEW = "add";

export default function NotesDrawer({
  open,
  processId,
  taskId,
  valorId,
}: {
  open: boolean;
  processId?: string;
  taskId?: string;
  valorId?: string;
}) {
  const [creatingNote, setCreatingNote] = React.useState(false);
  const [creatingNoteError, setCreatingNoteError] = React.useState(false);

  const [view, setView] = React.useState(VIEW_VIEW);
  const { user } = useAuth();

  // get notes
  const {
    data: oppData,
    isLoading: oppLoading,
    refetch: refetchOppNotes,
  } = useQuery(
    ["notes", processId, taskId],
    () => getNotesByTask(processId, taskId),
    {
      enabled: !!processId && !!taskId,
    },
  );

  const {
    data: orgData,
    isLoading: orgLoading,
    refetch: refetchOrgNotes,
  } = useQuery(
    ["notes", valorId],
    () => queryCompanyNotes(valorId),
    {
      enabled: !!valorId,
    },
  );

  const notes = React.useMemo(() => {
    if (oppData) {
      return oppData?.hits;
    }
    if (orgData) {
      return orgData?.hits;
    }
    return [];
  }, [oppData, orgData]);

  const handleAddNote = () => {
    setView("add");
  };

  if (!open) {
    return null;
  }

  return (
    <Box
      minWidth={400}
      maxWidth="100%"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={1}
      >
        {view === VIEW_VIEW && (
          <>
            <Breadcrumbs>
              <Typography>Notes</Typography>
            </Breadcrumbs>
            <Button variant="outlined" size="small" onClick={handleAddNote}>Add Note</Button>
          </>
        )}
        {view === ADD_VIEW && (
          <Breadcrumbs>
            <Button variant="text" onClick={() => setView(VIEW_VIEW)}>Notes</Button>
            <Typography>New Note</Typography>
          </Breadcrumbs>
        )}
      </Box>

      <Box
        visibility={view === VIEW_VIEW ? "visible" : "hidden"}
        height={view === VIEW_VIEW ? "auto" : 0}
      >
        {
          (oppLoading || orgLoading) && (
            <Box>
              <Typography>Loading...</Typography>
            </Box>
          )
        }
        {notes.length === 0 && !(oppLoading || orgLoading) && (
          <Box p={1}>
            <Typography>No notes found</Typography>
          </Box>
        )}

        {notes.length > 0 && (
          <Box
            overflow="auto"
            my={1}
          >
            <NotesList
              notes={notes}
              userId={user.id}
              hideControls
              editNote={() => {}}
              deleteNote={() => {}}
              viewNote={() => {}}
            />
          </Box>
        )}
      </Box>

      {view === ADD_VIEW && (
        <Box>
          <Editor
            disabled={creatingNote}
            error={creatingNoteError}
            onPublish={async ({
              title, richText, meetingDate, meetingType,
            }) => {
              // create note
              setCreatingNote(true);
              setCreatingNoteError(false);
              try {
                if (processId && taskId) {
                  await createNoteOnTask(
                    processId,
                    taskId,
                    {
                      richText,
                      title,
                      meetingDate: Math.floor(meetingDate / 1000),
                      meetingType,
                      visibility: "public",
                      plainText: PlainSerializer.serialize(richText),
                    },
                  );
                } else if (valorId) {
                  await createNote(
                    {
                      valorId,
                      richText,
                      title,
                      meetingDate: Math.floor(meetingDate / 1000),
                      meetingType,
                      visibility: "public",
                      plainText: PlainSerializer.serialize(richText),
                    },
                  );
                }
                setView(VIEW_VIEW);
              } catch (e) {
                setCreatingNoteError(true);
              } finally {
                setCreatingNote(false);
              }

              // refetch notes
              if (processId && taskId) {
                refetchOppNotes();
              } else if (valorId) {
                refetchOrgNotes();
              }
            }}
          />
        </Box>
      )}
    </Box>
  );
}
