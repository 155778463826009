import React, { useMemo } from "react";
import { getDealPassReport } from "@/api/Reporting";
import dayjs from "dayjs";
import {
  Box, useMediaQuery, Theme, Alert,
} from "@mui/material";
import { useQuery } from "react-query";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import Progress from "@/ui/atoms/Progress";
import {
  UserCell,
  CompanyCell,
  TextCell,
  DisclaimerMoicCell,
  DateCell,
} from "@/components/Table/Primitives";
import DataTable from "@/components/LocalTable";
import { useQueryParam, StringParam, withDefault } from "use-query-params";
import { getLastMonday } from "@/utils/general";
import MeetingPage from "./MeetingPage";

export function DealPassReport() {
  const sinceDate = getLastMonday(dayjs).format("YYYY-MM-DD");
  const defaultCloseDate = withDefault(StringParam, sinceDate);
  const [statefulCloseDate, setCloseDate] = useQueryParam(
    "since",
    defaultCloseDate,
  );

  const [activeTab, setActiveTab] = useQueryParam("passType", StringParam);
  const [activeFund, setActiveFund] = useQueryParam("activeFund", StringParam);

  const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const { data, isLoading } = useQuery(
    ["DealPassReport", statefulCloseDate, activeFund],
    async () => getDealPassReport(statefulCloseDate, activeFund),
    {
      enabled: !!statefulCloseDate && !!activeFund,
    },
  );
  const amiraKhatib = 107172989;

  const sortedData = useMemo(() => {
    const qualifiedPass = { growth: [], vsv: [] };
    const immediateKill = { growth: [], vsv: [] };
    if (!data) {
      return { qualifiedPass, immediateKill };
    }

    data.forEach((deal) => {
      const {
        introductionCategory,
        sourceOfIntroduction,
        vsvSourceOfIntroduction,
        hasFirstMeeting,
        growthStatus,
        vsvStatus,
      } = deal;

      if (
        ["Intermediated", "Cold Inbound"].indexOf(introductionCategory)
          !== -1
        || sourceOfIntroduction === amiraKhatib
        || vsvSourceOfIntroduction === amiraKhatib
      ) {
        if (growthStatus) {
          immediateKill.growth.push(deal);
        }
        if (vsvStatus) {
          immediateKill.vsv.push(deal);
        }
      } else if (
        !hasFirstMeeting
        && ["Intermediated", "Cold Inbound"].indexOf(introductionCategory)
          === -1
        && sourceOfIntroduction !== amiraKhatib
        && vsvSourceOfIntroduction !== amiraKhatib
      ) {
        if (growthStatus) {
          qualifiedPass.growth.push(deal);
        }
        if (vsvStatus) {
          qualifiedPass.vsv.push(deal);
        }
      }
    });
    return { qualifiedPass, immediateKill };
  }, [data]);

  const columns = useMemo(
    () => [
      {
        header: "Company",
        accessorFn: (row) => row.organization,
        cell: (prop) => (
          <CompanyCell
            value={{
              ...prop.getValue(),
              description: prop.row.original.description,
            }}
          />
        ),
        id: "organization",
        getFilterValue: (row) => row.organization?.name.toLowerCase(),
        getSortValue: (row) => row.organization?.name.toLowerCase(),
        sortingFn: (rowA, rowB) => rowA.original.organization.name.localeCompare(
          rowB.original.organization.name,
        ),
        filterFn: "fuzzy",
        minSize: isSmDown ? 48 : 250,
        size: isSmDown ? 48 : 250,
      },
      {
        id: "description",
        header: "Description",
        accessorKey: "description",
        cell: (prop) => <TextCell value={prop.getValue()} />,
        size: isSmDown ? 48 : 480,
      },
      {
        header: "MSL",
        accessorKey: "msl",
        cell: (prop) => <UserCell value={prop.getValue()} />,
        minSize: 200,
      },
      {
        header: "MOIC Model",
        accessorKey: "predictedMoic",
        enableSorting: false,
        enableColumnFilter: false,
        cell: (prop) => (
          <DisclaimerMoicCell
            signal={prop.getValue()}
            valorId={prop.row.original.organization.valorId}
          />
        ),
      },

      {
        header: "Intro Category",
        accessorKey: "introductionCategory",
        cell: (prop) => <TextCell value={prop.getValue()} />,
      },
      {
        header: "Source of Intro",
        id: "sourceOfIntroduction",
        accessorFn: (row) => {
          const { introPerson, vsvIntroPerson } = row;
          if (activeFund === "growth") return introPerson;
          if (activeFund === "vsv") return vsvIntroPerson;
          return null;
        },
        cell: (prop) => <UserCell value={prop.getValue()} />,
        minSize: 200,
      },
      {
        header: "Sectors",
        accessorKey: "sectorsNew",
        cell: (prop) => (
          <TextCell value={prop.getValue() ? prop.getValue().join(", ") : ""} />
        ),
        enableSorting: false,
        enableFiltering: false,
      },
      {
        header: "Date Added",
        accessorKey: "createdAt",
        cell: (prop) => <DateCell value={prop.getValue()} />,
        meta: {
          sortType: "date",
          optional: true,
        },
      },
    ],
    [isSmDown, activeFund],
  );

  let blurbToShow = null;
  if (isLoading) blurbToShow = null;
  else if (!activeTab) blurbToShow = "Select a pass type to view the data";
  else if (!activeFund) blurbToShow = "Select a fund to view the data";
  else if (activeTab === "qualifiedPass" && !sortedData.qualifiedPass[activeFund].length) blurbToShow = "No qualified passes found for the selected date and fund";
  else if (activeTab === "immediateKill" && !sortedData.immediateKill[activeFund].length) blurbToShow = "No immediate kills found for the selected date";

  return (
    <MeetingPage title="Deal Passes – No Opportunity Assessment">
      <Box
        m={1}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
        }}
        gap={1}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <ButtonTabs
            tabLabel="Pass Type"
            width="320px"
            color="secondary"
            options={[
              {
                label: "Qualified Passes",
                key: "qualifiedPass",
              },
              {
                label: "Immediate Kills",
                key: "immediateKill",
              },
            ]}
            disableTest={(option) => option.disabled}
            activeKey={activeTab}
            onClick={(option) => setActiveTab(option.key)}
          />
          {activeTab ? (
            <ButtonTabs
              tabLabel="Fund"
              width="320px"
              options={[
                {
                  label: "Growth",
                  key: "growth",
                },
                {
                  label: "VSV",
                  key: "vsv",
                },
              ]}
              disableTest={(option) => option.disabled}
              activeKey={activeFund}
              onClick={(option) => setActiveFund(option.key)}
            />
          ) : null}
        </Box>
        <DatePicker
          label="Close Date"
          format="YYYY-MM-DD"
          value={statefulCloseDate ? dayjs(statefulCloseDate) : null}
          onChange={(date) => {
            setCloseDate(date.format("YYYY-MM-DD"));
          }}
        />
      </Box>
      {isLoading ? <Progress /> : null}
      {!activeTab
      || !activeFund
      || !sortedData[activeTab][activeFund]?.length ? (
          isLoading ? null : (
            <Box m={2} textAlign="center">
              <Alert severity="info">{blurbToShow}</Alert>
            </Box>
          )
        ) : (
          <DataTable columns={columns} data={sortedData[activeTab][activeFund]} />
        )}
    </MeetingPage>
  );
}

export default DealPassReport;
