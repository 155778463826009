import React, { useMemo } from "react";
import Progress from "@/ui/atoms/Progress";
import { DataTable } from "@/components/LocalTable";
import dayjs from "dayjs";
import {
  Box, Typography, Link, Card, Alert, useMediaQuery,
} from "@mui/material";
import { SelectFilter } from "@/components/Table/CustomFilters/Select";
import {
  CompanyCell,
  TextCell,
  MoneyCell,
  UserCell,
  MultiUserCell,
} from "@/components/Table/Primitives";
import { primaryFundSort } from "../utils";

const dealTypeOrder = ["Platform", "Opportunity", "Add-On", "VC", "Seed", "Core", "Placeholder", "Follow-on"];

function PipelineReviewTable({
  fund,
  status,
  isICItems,
  isIC,
  fundData,
  isLoading,
}: {
  fund?: string;
  status?: string;
  isICItems: boolean;
  isIC?: boolean;
  fundData: any;
  isLoading: boolean;
}) {
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  let data = fundData.map((
    co,
  ) => {
    const { ...copy } = co;
    copy.primaryFund = copy.primaryFund
      ? copy.primaryFund === fund
        ? "Lead"
        : "Participating"
      : "";
    return copy;
  });

  if (fund.indexOf("VSV") > -1) {
    data = data.sort((a, b) => {
      const primaryFundDiff = (
        primaryFundSort.indexOf(a.primaryFund)
        - primaryFundSort.indexOf(b.primaryFund)
      );
      if (primaryFundDiff !== 0) return primaryFundDiff;

      const dealTypeAIndex = dealTypeOrder.indexOf(a.dealType);
      const dealTypeBIndex = dealTypeOrder.indexOf(b.dealType);
      if (dealTypeAIndex !== dealTypeBIndex) {
        if (dealTypeAIndex === -1) return 1;
        if (dealTypeBIndex === -1) return -1;
        return dealTypeAIndex > dealTypeBIndex ? 1 : -1;
      }

      return b.amount - a.amount; // for descending sort by amount
    });
  } else {
    data = data.sort((a, b) => {
      const primaryFundAIndex = primaryFundSort.indexOf(a.primaryFund);
      const primaryFundBIndex = primaryFundSort.indexOf(b.primaryFund);
      if (primaryFundAIndex !== primaryFundBIndex) {
        return primaryFundAIndex - primaryFundBIndex;
      }

      const dealTypeAIndex = dealTypeOrder.indexOf(a.dealType);
      const dealTypeBIndex = dealTypeOrder.indexOf(b.dealType);
      if (dealTypeAIndex !== dealTypeBIndex) {
        if (dealTypeAIndex === -1) return 1;
        if (dealTypeBIndex === -1) return -1;
        return dealTypeAIndex > dealTypeBIndex ? 1 : -1;
      }

      return b.amount - a.amount; // assuming you want to sort by amount descending
    });
  }

  const columns = useMemo(
    () => [
      {
        header: "Company",
        accessorFn: (row) => row.organization,
        cell: (prop) => <CompanyCell value={prop.getValue()} />,
        id: "organization",
        getFilterValue: (row) => row.organization?.name.toLowerCase(),
        getSortValue: (row) => row.organization?.name.toLowerCase(),
        sortingFn: (rowA, rowB) => rowA.original.organization.name.localeCompare(
          rowB.original.organization.name,
        ),
        filterFn: "fuzzy",
        minSize: isSmDown ? 48 : 250,
        size: isSmDown ? 48 : 250,
      },
      {
        header: "Deal Name",
        accessorFn: (row) => row.dealName,
        cell: (prop) => (
          <Typography
            sx={(t) => ({
              color: t.palette.text.primary,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "normal",
            })}
          >
            <Link
              href={`https://valor.affinity.co/opportunities/${prop.row.original.dealId}`}
              target="_blank"
              rel="noreferrer"
            >
              {prop.getValue()}
            </Link>
          </Typography>
        ),
        id: "dealName",
        sortingFn: "alphanumeric",
        minSize: 100,
        size: 250,
      },
      isIC // TODO: && isVSV
        ? {
          header: "IC date",
          accessorFn: (row) => row.estimatedIcDate,
          cell: (prop) => {
            const value = prop.getValue();
            return (
              <TextCell
                value={
                      value === "TBD"
                        ? value
                        : dayjs(value ?? "").format("MM/DD/YYYY")
                    }
              />
            );
          },
          id: "estimatedIcDate",
          disableFilters: true,
          minSize: 100,
        }
        : null,
      {
        header: "Target Fund",
        accessorFn: (row) => row.targetFund,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        id: "targetFund",
        filter: SelectFilter,
        minSize: 100,
      },
      {
        header: "Lead vs. Participating Fund",
        accessorFn: (row) => row.primaryFund,
        cell: (prop) => {
          const val = prop.getValue();
          return <TextCell value={val} />;
        },
        id: "primaryFund",
        filter: SelectFilter,
        minSize: 100,
      },
      {
        header: "Deal Type",
        accessorFn: (row) => row.dealType,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        id: "dealType",
        filter: SelectFilter,
        minSize: 100,
      },
      {
        header: "Amount",
        accessorFn: (row) => row.amount,
        cell: (prop) => <MoneyCell value={prop.getValue()} />,
        meta: {
          sortType: "numeric",
        },
        id: "amount",
        filter: SelectFilter,
        minSize: 100,
      },
      status === "TS" || status === "Won"
        ? {
          header: "Close Date",
          accessorFn: (row) => row.closeDate,
          cell: (prop) => (
            <TextCell
              value={
                    prop.getValue()
                      ? dayjs(prop.getValue()).format("MM/DD/YYYY")
                      : null
                  }
            />
          ),
          sortingFn: "datetime",
          id: "closeDate",
          minSize: 100,
        }
        : null,
      {
        header: "MSL",
        accessorFn: (row) => row.msl,
        cell: (prop) => <UserCell value={prop.getValue()} />,
        id: "msl",
        sortingFn: (rowA, rowB) => rowA.original.msl.lastName.localeCompare(
          rowB.original.msl.lastName,
        ),
        enableColumnFilter: false,
        minSize: 120,
      },
      {
        header: "Owners",
        accessorFn: (row) => row.owners,
        cell: (prop) => <MultiUserCell value={prop.getValue()} />,
        id: "owners",
        enableColumnFilter: false,
        enableSorting: false,
        minSize: 100,
        size: 250,
      },
    ].filter((x) => x),
    [status, fund, isSmDown],
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {isLoading ? (
        <Box mt={3}>
          <Progress />
        </Box>
      ) : (
        <Card elevation={0} sx={{ height: "auto" }}>
          {fundData.length ? (
            <DataTable data={data} columns={columns} />
          ) : (
            <Alert severity="info" sx={{ margin: 2 }} data-cy="no-data-message">
              Nothing in the
              {" "}
              {fund}
              {" "}
              Deals
              {" "}
              {isICItems ? "for IC" : `for ${status}`}
            </Alert>
          )}
        </Card>
      )}
    </Box>
  );
}
export default PipelineReviewTable;
