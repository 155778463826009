/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import {
  Badge,
  Box,
  ButtonBase,
  ClickAwayListener,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import { Notifications, MenuOpen, Menu } from "@mui/icons-material";
import { useQuery } from "react-query";

import UserAvatar from "@/ui/atoms/UserAvatar";
import useLocalStorageState from "@/hooks/useLocalStorageState";
import { useNavigate } from "react-router-dom";
import { getNumTasks } from "@/api/Tasks";
import { saytForOrg } from "@/api/Search";
import {
  useAuth,
} from "@/hooks/useAuth";
import MainSearchBar from "@/components/MainSearchBar";
import {
  canSeeRecentMeetings,
  canSeeTasksAndAlerts,
  canSeeHelp,
  canSeeSearchBarDealSearch,
} from "@/constants/Roles";
import NotPrintable from "@/components/NotPrintable";
import useIsInTouchPWA from "@/hooks/useIsInTouchPWA";

const searchAndParse = async (query) => {
  const results = await saytForOrg(query);
  return results?.slice(0, 5);
};

const AlertsBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(7),
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  padding: theme.spacing(0.5, 1),
  cursor: "pointer",
  border: `1px solid ${theme.palette.background.paper}`,
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  "&:hover": {
    backgroundColor: theme.palette.background.blue,
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  "& .MuiBadge-badge": {
    color: "white",
  },
}));

interface UserBoxProps {
  isOpenUserMenu: boolean;
}
const UserBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isOpenUserMenu",
})<UserBoxProps>(({ theme, isOpenUserMenu }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  border: isOpenUserMenu ? `1px solid ${theme.palette.background.paper}` : `1px solid ${theme.palette.background.blue}`,
  borderRadius: theme.spacing(5),
  width: isOpenUserMenu ? "200px" : "160px",
  minHeight: theme.spacing(6),
  padding: theme.spacing(0.5, 1),
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "auto",
    minWidth: isOpenUserMenu ? "200px" : "auto",
    height: isOpenUserMenu ? "400px" : theme.spacing(6),
    padding: theme.spacing(0.5, 2),
  },
  marginLeft: "8px",
  height: isOpenUserMenu ? "396px" : theme.spacing(6),
  transition: "height 0.1s ease-in-out",
  webkitTransform: "translate3d(0,0,0)",
  backgroundColor: isOpenUserMenu ? theme.palette.background.blue : theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.background.blue,
    border: `1px solid ${theme.palette.background.paper}`,
  },
}));

const UserMenuItem = styled(ButtonBase)(({ theme }) => ({
  padding: theme.spacing(1.5, 1),
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    color: theme.palette.blue.main,
    backgroundColor: theme.palette.background.paper,
  },
}));

interface AvatarMenuProps {
  numTasks: number;
}
function AvatarMenu(props: AvatarMenuProps) {
  const { numTasks } = props;
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false);
  const [isDarkMode] = useLocalStorageState(false, "isDarkMode");
  const RootBox = styled(Box)(({ theme }) => ({
    width: "240px",
    position: "absolute",
    top: "4px", // TODO
    zIndex: theme.zIndex.appBar + 3,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: isOpenUserMenu ? "flex-start" : "center",

    [theme.breakpoints.down("md")]: {
      width: "96px",
    },
  }));

  const { user, signOut } = useAuth();

  const navigate = useNavigate();
  const isInTouchPWA = useIsInTouchPWA();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const MAX_TASKS = 99;
  const renderedNum = numTasks > 0 ? (numTasks > MAX_TASKS ? `${MAX_TASKS}+ ` : `${numTasks} `) : null;

  const menuItems = [
    {
      render: (
        <Typography variant="subtitle2">
          {renderedNum}
          Tasks &amp; Alerts
        </Typography>

      ),
      cyAttribute: "global__user-menu__tasks",
      canSee: canSeeTasksAndAlerts,
      onClick: () => navigate("/tasks"),
    },
    {
      render: (
        <Typography variant="subtitle2">
          Recent Meetings
        </Typography>

      ),
      cyAttribute: "global__user-menu__recent-meetings",
      canSee: canSeeRecentMeetings,
      onClick: () => navigate("/recent-meetings"),
    },
    {
      render: (
        <Typography variant="subtitle2">Fetch App Update</Typography>
      ),
      cyAttribute: "global__user-menu__fetch-app-update",
      canSee: () => true,
      onClick: () => location.reload(),
    },
    {
      render: (
        <Typography variant="subtitle2">Help</Typography>
      ),
      cyAttribute: "global__user-menu__help",
      canSee: canSeeHelp,
      onClick: () => navigate("/help"),
    },
    {
      render: (
        <Typography variant="subtitle2">Settings</Typography>
      ),
      // cyAttribute: "global__user-menu__help",
      canSee: () => true,
      onClick: () => navigate("/user-settings"),
    },
    {
      render: (
        <Typography variant="subtitle2">Log Out</Typography>
      ),
      cyAttribute: "global__user-menu__log-out",
      canSee: () => true,
      onClick: () => signOut(),
    },
  ];

  return (
    <RootBox>
      {(numTasks > 0 && !isInTouchPWA && canSeeTasksAndAlerts(user)) ? (
        <AlertsBox
          onClick={() => navigate("/tasks")}
          data-cy="global__user-menu__tasks"
        >
          <StyledBadge
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            badgeContent={renderedNum}
            max={MAX_TASKS}
            color="red"
            overlap="rectangular"
          >
            <Notifications
              style={{
                color: isDarkMode
                  ? theme.palette.text.primary
                  : theme.palette.gray.dark,
              }}
            />
          </StyledBadge>
        </AlertsBox>
      ) : null}
      <UserBox
        isOpenUserMenu={isOpenUserMenu}
        onClick={() => setIsOpenUserMenu(true)}
        data-cy="global__user-menu"
      >
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              justifyContent: "center",
              alignItems: "center",
              width: "100%,",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: theme.palette.primary.main,
                paddingRight: theme.spacing(1),
                fontWeight: "bold",
                margin: theme.spacing(0, 1),
              }}
              data-cy="global__username"
            >
              {user.firstName}
            </Typography>

            <UserAvatar user={user} />
          </Box>
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
                xl: "none",
              },
              justifyContent: isOpenUserMenu ? "flex-end" : "center",
              alignItems: "center",
              width: isOpenUserMenu ? "auto" : theme.spacing(3),
            }}
          >
            <UserAvatar user={user} />
          </Box>
        </Box>
        {isOpenUserMenu && (
          <ClickAwayListener onClickAway={() => setIsOpenUserMenu(false)}>
            <Box
              sx={{ animation: "0.3s ease-out 0s 1 fadeIn" }}
              display="flex"
              flexDirection="column"
              padding={1}
            >
              {menuItems
                .filter((i) => i.canSee(user))
                .map(({ render, onClick, cyAttribute }) => (
                  <UserMenuItem
                    key={cyAttribute}
                    onClick={(e) => {
                      if (isSmDown) {
                        setIsOpenUserMenu(false);
                        e.stopPropagation();
                      }
                      onClick();
                    }}
                    data-cy={cyAttribute}
                  >
                    {render}
                  </UserMenuItem>
                ))}
            </Box>
          </ClickAwayListener>
        )}
      </UserBox>
    </RootBox>
  );
}

const StyledAppBar = styled(Box)(({ theme }) => ({
  zIndex: theme.zIndex.appBar,
  backgroundColor: "transparent",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  padding: theme.spacing(0, 4),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 3),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0, 1.5),

  },
  width: "100%",
}));

interface HeaderProps {
  drawerOpen: boolean;
  setDrawerOpen: () => void;
}

export function Header(props: HeaderProps) {
  const {
    drawerOpen,
    setDrawerOpen,
  } = props;

  const { user } = useAuth();
  const { data: numTasks } = useQuery(
    ["NumTasks"],
    async () => {
      const num = await getNumTasks(user.id);
      if (num.status === 401) {
        return 0;
      }
      return num;
    },
    { enabled: user !== null && canSeeTasksAndAlerts(user) },
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <NotPrintable>
      <StyledAppBar>
        <Toolbar disableGutters>
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
          >
            <Grid
              item
              xs={5}
              sm={5}
              md={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box sx={{
                display: {
                  xs: "flex",
                  sm: "none",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%,",
              }}
              >
                <IconButton
                  aria-label="open drawer"
                  color="primary"
                  size={isSmDown ? "medium" : "large"}
                  onClick={() => setDrawerOpen()}
                  edge="start"
                >
                  {drawerOpen && isSmDown ? <MenuOpen fontSize="large" /> : <Menu fontSize="large" />}
                </IconButton>
              </Box>
              <Box>
                {canSeeSearchBarDealSearch(user) && (
                <MainSearchBar
                  showSAYT
                  search={(q) => searchAndParse(q)}
                  selectTerm={(item) => navigate(`/org/${item.valorId}`)}
                  placeholder={isMdDown ? "Search..." : "Search for a company by name or domain"}
                />
                )}
              </Box>
            </Grid>
            <Grid style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }} item xs={4} md={3}>
              {user && (
                <AvatarMenu numTasks={numTasks} />
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </StyledAppBar>
    </NotPrintable>
  );
}

export default Header;
