import React, { useMemo } from "react";
import { getOpportunities } from "@/api/Reporting";
import DataTable from "@/components/LocalTable";
import {
  TextCell,
  MoneyCell,
  OpportunityNoteCell,
} from "@/components/Table/Primitives";
import {
  Typography,
  Link,
  ButtonBase,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useQuery } from "react-query";
import Affinity from "@/ui/atoms/Icons/Affinity";
import {
  useQueryParam,
  StringParam,
  withDefault,
  QueryParamConfig,
} from "use-query-params";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import Progress from "@/ui/atoms/Progress";
import DateCell from "@/components/Table/Primitives/DateCell";
import MeetingPage from "./MeetingPage";

type ReportType = "active" | "consideration"

const reportStatuses: { [key in ReportType]: string[] } = {
  active: [
    "Closed – Add on Investor",
    "Drive to Closing",
    "Launch and Execute Fundraise",
  ],
  consideration: [
    "Prepare Documents and Materials",
    "CEO Approval to Activate",
    "Fund Design and Fundraising Strategy",
    "CEO Approval to Proceed",
    "Ideation and Evaluation",
  ],
};

const options: {key: ReportType, label: string}[] = [
  { key: "active", label: "Active" },
  { key: "consideration", label: "In Consideration" },
];

const DefaultValorFundRaising: QueryParamConfig<ReportType, ReportType> = withDefault(StringParam as QueryParamConfig<ReportType, string>, "active");

export function ValorFundraising() {
  const listId = 237474;

  const [reportFilter, setReportFilter] = useQueryParam<ReportType>("fundRaisingReport", DefaultValorFundRaising);

  const statuses = reportStatuses[reportFilter];

  const { data: opps = [], isLoading } = useQuery(
    ["Opportunities", listId],
    async () => {
      const opportunities = await getOpportunities(listId);
      const op = opportunities.map((opp) => {
        const copy = { ...opp };
        copy.fieldValues = opp.fieldValues.reduce((acc, curr) => {
          acc[curr.fieldName] = curr.value;
          return acc;
        }, {});
        return copy;
      });
      return op;
    },
  );
  const isSmDown = useMediaQuery((t) => t.breakpoints.down("sm"));

  const columns = useMemo(
    () => [
      {
        header: "Fund Name",
        id: "Fund Name",
        accessorFn: (row) => row.name,
        cell: (prop) => (
          <Typography
            sx={(t) => ({
              color: t.palette.text.primary,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "normal",
            })}
          >
            <Link
              href={`https://valor.affinity.co/opportunities/${prop.row.original.opportunityId}`}
              target="_blank"
              rel="noreferrer"
            >
              {prop.getValue()}
            </Link>
          </Typography>
        ),
        sortingFn: "alphanumeric",
        minSize: isSmDown ? 100 : 200,
      },
      {
        header: "Status",
        id: "Status",
        accessorFn: (row) => row.fieldValues.Status,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        size: 200,
      },
      {
        header: "Update",
        id: "Update",
        cell: (prop) => {
          const { opportunityId } = prop.row.original;
          return (
            <OpportunityNoteCell
              opportunityId={opportunityId}
              daysAgo={365}
              noteCount={1}
            />
          );
        },

        disableSortBy: true,
        disableFilters: true,
        minSize: 550,
        size: 750,
      },
      {
        header: "Target Fund Size",
        id: "Target Fund Size",
        accessorFn: (row) => row.fieldValues["Target Fund Size"],
        cell: (prop) => <MoneyCell value={prop.getValue()} isFundingRound />,
        size: 200,
      },
      reportFilter === "active"
        ? {
          header: "Closed Amounts",
          accessorFn: (row) => row.fieldValues["Closed Amounts"],
          cell: (prop) => (
            <MoneyCell value={prop.getValue()} isFundingRound />
          ),
          size: 200,
        }
        : null,
      reportFilter === "active"
        ? {
          header: "Next Closing Date",
          accessorFn: (row) => row.fieldValues["Next Closing Date"],
          cell: (prop) => <DateCell value={prop.getValue()} />,
          size: 200,
        }
        : null,
      reportFilter === "active"
        ? {
          header: "Next Closing Est Amount",
          accessorFn: (row) => row.fieldValues["Next Closing Est Amount"],
          cell: (prop) => (
            <MoneyCell value={prop.getValue()} isFundingRound />
          ),
          size: 200,
        }
        : null,
      reportFilter === "active"
        ? {
          header: "Final Closing Date",
          accessorFn: (row) => row.fieldValues["Final Closing Date"],
          cell: (prop) => <DateCell value={prop.getValue()} />,
          size: 200,
        }
        : null,
    ].filter((x) => x),
    [reportFilter, isSmDown],
  );

  return (
    <MeetingPage title="Valor Fundraising">
      <Box m={1} display="flex" justifyContent="space-between">
        <ButtonTabs
          width={320}
          tabLabel="Report Type"
          activeKey={reportFilter}
          options={options as { key: string; label: string }[]}
          onClick={(option) => setReportFilter(option.key as ReportType)}
        />
        <ButtonBase
          href={`https://valor.affinity.co/lists/${listId}`}
          target="_blank"
          rel="noreferrer"
          sx={(t) => ({
            border: `1px solid ${t.palette.divider}`,
            padding: 1,
            borderRadius: t.spacing(1),
            display: "flex",
            alignItems: "center",
            color: "primary.main",
            "&:hover": {
              color: "primary.dark",
            },
          })}
        >
          <Affinity />
          Edit Report
        </ButtonBase>
      </Box>
      {isLoading ? (
        <Progress />
      ) : (
        <DataTable
          columns={columns}
          data={opps.filter((x) => {
            const status = x.fieldValues.Status;
            return statuses.includes(status);
          }).sort((a, b) => {
            const aStatus = a.fieldValues.Status;
            const bStatus = b.fieldValues.Status;
            return statuses.indexOf(aStatus) - statuses.indexOf(bStatus);
          })}
        />
      )}
    </MeetingPage>
  );
}
export default ValorFundraising;
