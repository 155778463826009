/* eslint-disable react/prop-types */
import React from "react";
import {
  useParams, useNavigate, Navigate, Routes, Route,
} from "react-router-dom";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import Page from "@/ui/molecules/Page";
import { canSeeRevGen } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import RevGen from "../WeeklyMeeting/RevGen/RevGenUpdates";
import OnBoarding from "../WeeklyMeeting/RevGen/Onboarding";
import Graph from "./Dashboard";

export default function VSVOps() {
  const { "*": section } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  const tabs = [
    {
      key: "dashboard",
      label: "Dashboard",
    },
    {
      key: "rev-gen",
      label: "Revenue Generation",
    },
    {
      key: "onboarding",
      label: "Onboarding",
    },
  ];

  if (user.role_id && !canSeeRevGen(user)) {
    return <Navigate to="/" replace />;
  }
  return (
    <Page>
      <Routes>
        <Route
          path=""
          element={
            <Navigate to="/rev-ops/dashboard" />
          }
        />
      </Routes>
      <Page.Title>Revenue Generation</Page.Title>
      <Page.Content>
        <div style={{ width: "540px" }}>
          <ButtonTabs
            options={tabs}
            disableTest={(o) => o.key === "vsv-onboarding" || o.key === "vsv-corp-support"}
            onClick={({ key }) => {
              navigate(`/rev-ops/${key}`);
            }}
            activeKey={section}
          />
        </div>
        {section === "dashboard" ? <Graph /> : null}
        {section === "rev-gen" ? <RevGen /> : null}
        {section === "onboarding" ? <OnBoarding /> : null}
      </Page.Content>
    </Page>
  );
}
