/* eslint-disable no-console */
import axios from "axios";

import { components, paths } from "@/api/index";
import { searchParamsObject, buildURLParameters } from "@/utils/general";

import downloadFile from "@/api/utils/Download";

type GetOrganizationFinancialsById = paths["/api/organization/{valor_id}/funding"]["get"]
export type OrganizationFunding = GetOrganizationFinancialsById["responses"]["200"]["content"]["application/json"];

const getOrganizationFundingById = async (valorId:string):
    Promise<OrganizationFunding> => {
  const response = await axios.get<OrganizationFunding>(`/api/organization/${valorId}/funding`);
  return response.data;
};

type GetOrganizationOpportunitiesById = paths["/api/organization/{valor_id}/opportunities"]["get"]
export type OrganizationOpportunities= GetOrganizationOpportunitiesById["responses"]["200"]["content"]["application/json"];
const getOrganizationValorOpportunities = async (valorId:string):
  Promise<OrganizationOpportunities> => {
  const response = await axios.get<OrganizationOpportunities>(`/api/organization/${valorId}/opportunities`);
  return response.data as OrganizationOpportunities;
};

type GetOrganizationInteractionsById =
  paths["/api/organization/{valor_id}/recent_interactions"]["get"];
export type OrganizationInteractions =
  GetOrganizationInteractionsById["responses"]["200"]["content"]["application/json"];
const getOrganizationInteractions = async (
  valorId: string,
): Promise<OrganizationInteractions> => {
  const response = await axios.get<OrganizationInteractions>(
    `/api/organization/${valorId}/recent-interactions`,
  );
  return response.data as OrganizationInteractions;
};

type GetOrganizationListMembershipsById =
  paths["/api/organization/{valor_id}/opportunities"]["get"];
export type getOrganizationListMemberships =
  GetOrganizationListMembershipsById["responses"]["200"]["content"]["application/json"];
const getOrganizationListMemberships = async (
  valorId: string,
): Promise<getOrganizationListMemberships> => {
  const response = await axios.get<getOrganizationListMemberships>(
    `/api/organization/${valorId}/list-memberships`,
  );
  return response.data as getOrganizationListMemberships;
};

type GetOrganizationById = paths["/api/organization/{valor_id}"]["get"]
type GetOrganizationByIdParams= GetOrganizationById["parameters"]["path"];
export type Organization = GetOrganizationById["responses"]["200"]["content"]["application/json"];

const getOrganizationById = async (valorId: GetOrganizationByIdParams["valor_id"]): Promise<Organization> => {
  const response = await axios.get<Organization>(`/api/organization/${valorId}`);
  return response.data as Organization;
};

type GetOrganizationFeaturesById = paths["/api/organization/{valor_id}/features"]["get"]
export type OrganizationFeatures = GetOrganizationFeaturesById["responses"]["200"]["content"]["application/json"];

const getOrganizationFeaturesById = async (valorId:string, featureTypes: string[] = []):
    Promise<OrganizationFeatures> => {
  const queryParams = featureTypes.map((value) => `featureTypes[]=${value}`).join("&");

  const response = await axios.get<OrganizationFeatures>(`/api/organization/${valorId}/features?${queryParams}`);
  return response.data as OrganizationFeatures;
};

type GetOrganizationSignalsById = paths["/api/organization/{valor_id}/signals"]["get"]
export type OrganizationSignals = GetOrganizationSignalsById["responses"]["200"]["content"]["application/json"];

const getOrganizationSignalsById = async (valorId: string, signalTypes: string[] = []):
    Promise<OrganizationSignals> => {
  const queryParams = signalTypes.map((value) => `signalTypes[]=${value}`).join("&");
  const response = await axios.get<OrganizationSignals>(`/api/organization/${valorId}/signals?${queryParams}`);
  return response.data as OrganizationSignals;
};

type MatchingOrgsByDomainResponse = components["schemas"]["MatchingOrgsByDomainResponse"];
const getOrganizationByDomains = async (
  domains: string[],
): Promise<MatchingOrgsByDomainResponse> => {
  const queryParams = domains.map((domain) => `domain=${domain}`).join("&");
  try {
    const response = await axios.get(`/api/organization/domain?${queryParams}`);
    return response.data as MatchingOrgsByDomainResponse;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const getOverrideOrg = async (queryDict) => {
  try {
    const response = await axios.get("/api/organization/override", {
      params: queryDict,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const overrideOrganization = async (data) => {
  try {
    const response = await axios.post("/api/organization/override", {
      ...data,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const createOrg = async (data) => {
  try {
    const response = await axios.post("/api/organization", {
      ...data,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

type GetOrgFieldValue = paths["/api/organization/{valor_id}/fields/{field_id}"]["get"];
export type OrgValue = GetOrgFieldValue["responses"]["200"]["content"]["application/json"];
export const getOrgValue = async (valorId, fieldId): Promise<OrgValue> => {
  const response = await axios.get(`/api/organization/${valorId}/fields/${fieldId}`);
  return response.data;
};

type GetOrgValues = paths["/api/organization/{valor_id}/fields"]["get"];
export type OrgValues = GetOrgValues["responses"]["200"]["content"]["application/json"];
export const getOrgValues = async (valorId): Promise<OrgValues> => {
  const response = await axios.get(`/api/organization/${valorId}/fields`);
  return response.data;
};

type GetOrgFields = paths["/api/organization/{valor_id}/fields"]["get"];
export type OrgFields = GetOrgFields["responses"]["200"]["content"]["application/json"];
export const getOrgFields = async (): Promise<OrgFields> => {
  const response = await axios.get("/api/organization/fields");
  return response.data;
};

type GetUserOrgView = paths["/api/organization/{valor_id}/user-view"]["get"];
export type UserOrgView = GetUserOrgView["responses"]["200"]["content"]["application/json"];
const getUserOrgView = async (valorId): Promise<UserOrgView> => {
  const response = await axios.get(`/api/organization/${valorId}/user-view`);
  return response.data;
};

type UpsertOrgView = paths["/api/organization/{valor_id}/user-view"]["post"];
export type UpsertUserViewBody = UpsertOrgView["requestBody"]["content"]["application/json"];
export type UpsertUserViewResponse = UpsertOrgView["responses"]["200"]["content"]["application/json"];
const upsertUserView = async (
  valorId: string,
  data: UpsertUserViewBody,
): Promise<UpsertUserViewResponse> => {
  const response = await axios.post(`/api/organization/${valorId}/user-view`, data);
  return response.data;
};

type GetOrganizationPortfolioById = paths["/api/organization/{valor_id}/portfolio"]["get"]
export type OrganizationPortfolio = GetOrganizationPortfolioById["responses"]["200"]["content"]["application/json"];
const getOrganizationPortfolioById = async (
  valorId: string,
  limit = 25,
  marker: string | null = null,
  sort: string[] | null = null,
  filter: { [key: string]: string | any } | null = null,
): Promise<OrganizationPortfolio> => {
  const params = searchParamsObject({
    limit,
    marker,
    sort,
    filter: filter ? JSON.stringify(filter) : null,
  });
  const response = await axios.get<OrganizationPortfolio>(
    `/api/organization/${valorId}/portfolio`,
    { params },
  );
  return response.data;
};

export const updateOrgFieldValue = async (
  valorId: string,
  fieldId: string,
  value: Record<string, any>,
) => {
  const response = await axios.post(`/api/organization/${valorId}/fields/${fieldId}`, value);
  return response.data;
};

const getOrganizationPortfolioByIdCSV = async (
  valorId: string,
  sort: string[] | null = null,
  filter: { [key: string]: string | any } | null = null,
): Promise<void> => {
  const params = searchParamsObject({
    sort,
    filter: filter ? JSON.stringify(filter) : null,
  });
  downloadFile({
    url: `/api/organization/${valorId}/portfolio`,
    params,
    headers: {
      Accept: "text/csv",
    },
  });
};

type GetAllSectors = paths["/api/organization/sectors"]["get"];
export type AllSectors = GetAllSectors["responses"]["200"]["content"]["application/json"];
export const getAllSectors = async (): Promise<AllSectors> => {
  const response = await axios.get("/api/organization/sectors");
  return response.data;
};

type GetCoinvestors = paths["/api/organization/{valor_id}/coinvestors"]["get"];
export type Coinvestors = GetCoinvestors["responses"]["200"]["content"]["application/json"];
export const getCoinvestors = async (valorId: string): Promise<Coinvestors> => {
  const response = await axios.get(`/api/organization/${valorId}/coinvestors`);
  return response.data;
};

type GetPortfolioCount = paths["/api/organization/{valor_id}/portfolio/count"]["get"];
export type PortfolioCount = GetPortfolioCount["responses"]["200"]["content"]["application/json"];
export const getPortfolioCount = async (valorId: string): Promise<PortfolioCount> => {
  const response = await axios.get<PortfolioCount>(`/api/organization/${valorId}/portfolio/count`);
  return response.data;
};

type GetOrganizationFatIds = paths["/api/organization/fat-ids"]["get"];
export type OrganizationFatIds = GetOrganizationFatIds["responses"]["200"]["content"]["application/json"];
export const getOrganizationFatIds = async (
  limit = 25,
  marker: string | null = null,
  sort: string[] | null = null,
  filter: { [key: string]: string | any } | null = null,
): Promise<OrganizationFatIds> => {
  const params = buildURLParameters({
    limit,
    marker,
    sort,
    filter: filter ? JSON.stringify(filter) : null,
  });
  const response = await axios.get(`/api/organization/fat-ids?${params}`);
  return response.data;
};

export const publishOrganization = async (valorId: string) => {
  const response = await axios.post(`/api/organization/${valorId}/publish`);
  return response.data;
};

export {
  createOrg,
  getOrganizationByDomains,
  getOrganizationById,
  getOrganizationFeaturesById,
  getOrganizationFundingById,
  getOrganizationInteractions,
  getOrganizationListMemberships,
  getOrganizationPortfolioById,
  getOrganizationPortfolioByIdCSV,
  getOrganizationSignalsById,
  getOrganizationValorOpportunities,
  getOverrideOrg,
  getUserOrgView,
  overrideOrganization,
  upsertUserView,
};
