import React, { useMemo } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import {
  StylesProvider,
  createGenerateClassName,
  ThemeProvider as ThemeProviderV4,
  createTheme as createThemeV4,
} from "@material-ui/core/styles";
import {
  createTheme as createThemeV5,
  ThemeProvider as ThemeProviderV5,
} from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { QueryParamProvider } from "use-query-params";
// import LogRocket from "logrocket";
import analytics from "@/shared/analytics";
import { LinkBehavior, getTheme } from "@/utils/valorMUITheme";
import { useLocalStorageState } from "@/hooks/useLocalStorageState";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LinkProps } from "@mui/material/Link";
import { ProvidePlatformInfo } from "@/hooks/usePlatformInfo";
import { ProvideAuth } from "@/hooks/useAuth";
import { ProvidePopover } from "@/hooks/useGlobalPopover";
import { SocketProvider } from "@/hooks/useSocket";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import AuthApp from "./AuthApp";
import packageJson from "../package.json";

import "./App.css";

dayjs.extend(relativeTime);

// if (window.location.hostname === "os.labs.valorep.com") {
//   LogRocket.init("wb5g1h/argos", { release: packageJson.version });
// }
// LogRocket.getSessionURL((sessionURL) => {
//   analytics.track("LogRocket", { sessionURL });
// });

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  seed: "argos",
});

const queryClient = new QueryClient();

function App() {
  const [isDarkMode] = useLocalStorageState(false, "isDarkMode");

  const v4Theme = useMemo(
    () => createThemeV4(getTheme(isDarkMode ? "dark" : "light")),
    [isDarkMode],
  );
  const v5Theme = useMemo(
    () => createThemeV5({
      components: {
        MuiButtonBase: {
          defaultProps: {
            LinkComponent: LinkBehavior,
          },
        },
        MuiLink: {
          defaultProps: {
            component: LinkBehavior,
          } as LinkProps,
        },
      },
      ...getTheme(isDarkMode ? "dark" : "light"),
    }),
    [isDarkMode],
  );
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProviderV5 theme={v5Theme}>
        <ThemeProviderV4 theme={v4Theme}>
          <Router>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <ProvideAuth>
                <ProvidePopover>
                  <ProvidePlatformInfo>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <SocketProvider>
                        <QueryClientProvider client={queryClient}>
                          <AuthApp />
                        </QueryClientProvider>
                      </SocketProvider>
                    </LocalizationProvider>
                  </ProvidePlatformInfo>
                </ProvidePopover>
              </ProvideAuth>
            </QueryParamProvider>
          </Router>
        </ThemeProviderV4>
      </ThemeProviderV5>
    </StylesProvider>
  );
}

export default App;
