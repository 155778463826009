import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography, styled, Box, Card,
} from "@mui/material";
import { agendaActions } from "./utils";

const AgendaTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.blue,
    borderRadius: theme.spacing(1),
  },
  width: "fit-content",
}));

function RenderItem({
  item, type, depth, navigate,
}) {
  const title = item.agendaLabel ? item.agendaLabel : item.label;
  if (item.children) {
    return (
      <li key={item.key}>
        {item.disabled ? (
          <Typography
            variant="h6"
            sx={(t) => ({
              padding: t.spacing(1),
              fontStyle: item?.disabled && !item?.wip ? "italic" : null,
              color: item?.wip
                ? `${t.palette.action.disabled} !important`
                : null,
            })}
          >
            {title}
          </Typography>
        ) : (
          <AgendaTypography
            variant="h6"
            onClick={() => navigate(`/weekly-meeting/${type}/${item.link}`)}
          >
            {title}
          </AgendaTypography>
        )}
        <Box>
          <ol type="a">
            {item.children.map((child) => (
              <RenderItem
                key={child.key}
                navigate={navigate}
                item={child}
                type={type}
                depth={depth + 1}
              />
            ))}
          </ol>
        </Box>
      </li>
    );
  }
  return (
    <li key={item.key}>
      {item.disabled ? (
        <Typography
          variant="h6"
          sx={(t) => ({
            padding: t.spacing(1),
            fontStyle: item?.disabled && !item?.wip ? "italic" : null,
            color: item?.wip ? `${t.palette.action.disabled} !important` : null,
          })}
        >
          {title}
        </Typography>
      ) : (
        <AgendaTypography
          variant="h6"
          onClick={() => navigate(`/weekly-meeting/${type}/${item.link}`)}
        >
          {title}
        </AgendaTypography>
      )}
    </li>
  );
}

function Agenda() {
  const { type } = useParams();
  let render = agendaActions[type].filter((x) => x.key !== "agenda");
  // only show the first two levels of the agenda
  render = render.map((x) => ({
    ...x,
    children: x.children?.map((y) => ({
      ...y,
      children: undefined,
    })),
  }));
  const navigate = useNavigate();

  return (
    <Card
      sx={(theme) => ({
        gap: theme.spacing(1),
        padding: theme.spacing(2),
      })}
    >
      <ol>
        {render.map((item) => (
          <RenderItem
            key={item.key}
            navigate={navigate}
            item={item}
            type={type}
            depth={2}
          />
        ))}
      </ol>
    </Card>
  );
}

export default Agenda;
