import React from "react";
import { Box, useTheme, Typography } from "@mui/material";
import Flake from "../Icons/Flake";

interface Props {
  title: string;
  children: React.ReactElement<Props> | React.ReactElement<Props>[];
}

export default function ValorFolio({ title, children }: Props) {
  const theme = useTheme();
  return (
    <Box sx={{ height: "calc(100%  - 32px)" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          background: theme.palette.background.blue,
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          padding: theme.spacing(1, 1, 0, 1),
          width: "fit-content",
        }}
      >
        <Flake />
        <Typography sx={{ textTransform: "uppercase" }} variant="boldSubtitle2">
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          height: "100%",
          background: theme.palette.background.blue,
          borderBottomRightRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          padding: theme.spacing(2, 1),
          minWidth: "200px",
          minHeight: "64px",
          width: "fit-content",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
