/* eslint-disable react/prop-types */
import React from "react";
import { Box, Button } from "@material-ui/core";
import CompanyCell from "@/ui/atoms/CompanyCell";
import { titleCase } from "@/utils/titleCase";
import { format } from "date-fns";
import SelectColumnFilter from "@/ui/atoms/TableFilters/SelectColumnFilter";
import DateRangeFilter from "@/ui/atoms/TableFilters/DateRangeFilter";

export const sharedColumns = (
  field = "",
  closeTask = () => {},
) => [
  {
    Header: "Action",
    Cell: ({
      row: { original },
    }) => (
      <Button variant="outlined" color="primary" onClick={() => closeTask(original.taskId, field)}>
        Done
      </Button>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Task",
    accessor: "taskName",
    Filter: ({ column }) => <SelectColumnFilter column={column} labelFormatter={titleCase} />,
  },
  {
    Header: "Company",
    accessor: "companyName",
    Cell: ({ row }) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <CompanyCell {...row.original} />
    ),
    disableFilters: true,
  },
  {
    Header: "Created At",
    accessor: (row) => new Date(row.createdAt),
    filter: "dateRange",
    Filter: ({ column }) => <Box style={{ maxWidth: "260" }}><DateRangeFilter filterValue={column.filterValue} setFilter={column.setFilter} /></Box>,
    sortType: "datetime",
    Cell: ({ value: createdAt }) => format(createdAt, "MMM dd, yyyy"),
  },
  {
    id: "dueDate",
    Header: "Due Date",
    accessor: (row) => new Date(row.dueDate),
    filter: "dateRange",
    Filter: ({ column }) => <Box style={{ maxWidth: "260" }}><DateRangeFilter filterValue={column.filterValue} setFilter={column.setFilter} /></Box>,
    sortType: "datetime",
    Cell: ({ value: dueDate }) => format(dueDate, "MMM dd, yyyy"),
  },
];

export default {
  sharedColumns,
};
