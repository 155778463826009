import React from "react";
import { SignalChip } from "@/components/SignalChip";

type MoicCellProps = {
  value: number | null;
};

export default function MoicCell({
  value,

}: MoicCellProps) {
  if (typeof value !== "number") {
    return <>-</>;
  }
  // Hard coding mantis to show just moic multiple
  return (
    <SignalChip
      signal={{
        signalType: "signal_mantis_prediction",
        score: value,
      }}
      showIcon
    />
  );
}
