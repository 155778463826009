import React from "react";
import { Box } from "@mui/material";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { useParams, useNavigate } from "react-router-dom";
import RevGenStatus from "./RevGenUpdates";
import OnboardingRevGen from "./Onboarding";

export function RevGen() {
  const { subPage2 } = useParams<{ "*"?: string }>();
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <ButtonTabs
        activeKey={subPage2}
        tabLabel="RevGen"
        width="480px"
        options={[
          { key: "portco-update", label: "Portfolio Company Updates" },
          { key: "onboarding", label: "Onboarding Status" },
        ]}
        onClick={(option) => {
          navigate(
            `../../rev-gen/${option.key}`,
            { relative: "path" },
          );
        }}
      />
      {subPage2 === "portco-update" && <RevGenStatus />}
      {subPage2 === "onboarding" && <OnboardingRevGen />}
    </Box>
  );
}
export default RevGen;
