import React, { useMemo } from "react";
import {
  Box, Card, Link, Typography,
} from "@mui/material";
import { DataTable } from "@/components/LocalTable";
import { DateCell, OpportunityNoteCell } from "@/components/Table/Primitives";
import Progress from "@/ui/atoms/Progress";
import { getOpportunities } from "@/api/Reporting";
import { useQuery } from "react-query";

function UpcomingMeetings() {
  const listId = 227475;

  const { data: opps = [], isLoading } = useQuery(
    ["Opportunities", listId],
    async () => {
      const opportunities = await getOpportunities(listId);
      let filtered = opportunities.filter((opp) => {
        const fields = opp.fieldValues;
        const giveUpdate = fields.find(
          (field) => field.fieldId === 4217828,
        );
        if (!giveUpdate) return false;
        return giveUpdate.value === "Yes";
      });

      filtered = filtered.map((opp) => {
        const copy = { ...opp };
        copy.fieldValues = opp.fieldValues.reduce((acc, curr) => {
          acc[curr.fieldName] = curr.value;
          return acc;
        }, {});
        return copy;
      });
      return filtered.sort((a, b) => {
        const aDate = new Date(a.fieldValues["Meeting Date"]);
        const bDate = new Date(b.fieldValues["Meeting Date"]);
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        if (aDate.getTime() === bDate.getTime()) {
          return aName.localeCompare(bName);
        }
        return aDate.getTime() - bDate.getTime();
      });
    },
  );

  const columns = useMemo(
    () => [
      {
        header: "Deal Name",
        accessorFn: (row) => row.name,
        cell: (prop) => (
          <Typography
            sx={(t) => ({
              color: t.palette.text.primary,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "normal",
            })}
          >
            <Link
              href={`https://valor.affinity.co/opportunities/${prop.row.original.opportunityId}`}
              target="_blank"
              rel="noreferrer"
            >
              {prop.getValue()}
            </Link>
          </Typography>
        ),
        id: "name",
        sortingFn: "alphanumeric",
        minSize: 250,
      },
      {
        header: "Meeting Date",
        accessorFn: (row) => row.fieldValues["Meeting Date"],
        cell: (prop) => <DateCell value={prop.getValue()} />,
        id: "meetingDate",
        enableColumnFilter: true,
        enableSorting: true,
        minSize: 100,
        size: 250,
      },
      {
        header: "Update",
        cell: (prop) => {
          const { opportunityId } = prop.row.original;
          return (
            <OpportunityNoteCell
              opportunityId={opportunityId}
              daysAgo={14}
              noteCount={1}
            />
          );
        },
        id: "notes",
        disableSortBy: true,
        disableFilters: true,
        minSize: 550,
        size: 750,
      },
    ],
    [],
  );

  const filteredOpps = opps.filter((opp) => {
    const fields = opp.fieldValues;
    const type = fields.Type;
    if (!type) return false;
    return type === "Meeting";
  });

  if (!filteredOpps.length && !isLoading) {
    return (
      <Box>
        <Box m={3}>
          <Typography variant="body2" align="center">
            No opportunites with a meeting date that are marked as Give Update
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      {isLoading ? (
        <Progress />
      ) : (
        <Card elevation={0}>
          <DataTable data={filteredOpps} columns={columns} />
        </Card>
      )}
    </Box>
  );
}

export default UpcomingMeetings;
