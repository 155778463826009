import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  Chip,
  TextField,
  Typography,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { saytForOrg } from "@/api/Search";
import MainSearchBar from "@/components/MainSearchBar";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import { InputLabel } from "@material-ui/core";
import {
  patchExperience,
  postExperience,
  deleteExperience,
} from "@/api/People";

function DatePickerField({ label, input, meta, disabled }) {
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <InputLabel>{label}</InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          open={isOpenDatePicker}
          onOpen={() => setIsOpenDatePicker(true)}
          onClose={() => setIsOpenDatePicker(false)}
          value={input.value ? dayjs(input.value) : null}
          onChange={(date) =>
            input.onChange(date ? date.format("YYYY-MM-DD") : null)
          }
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              fullWidth
              margin="normal"
              error={meta.touched && meta.error}
              helperText={meta.touched && meta.error}
              disabled={disabled}
            />
          )}
        />
      </LocalizationProvider>
      {meta.touched && meta.error && (
        <Typography color="error" variant="body2">
          {meta.error}
        </Typography>
      )}
    </Box>
  );
}

const searchAndParse = async (query) => {
  const results = await saytForOrg(query);
  return results?.slice(0, 5);
};

function OrganizationSelect({ input, meta, disabled }) {
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  useEffect(() => {
    if (input.value) {
      setSelectedOrganization(input.value);
    }
  }, [input.value]);

  const handleSelect = (organization) => {
    const { description, ...org } = organization;
    setSelectedOrganization(org);
    input.onChange(org);
  };

  return (
    <div>
      {selectedOrganization?.valorId ? (
        <Chip
          onDelete={() => {
            setSelectedOrganization(null);
            input.onChange(null);
          }}
          avatar={
            <CompanyAvatar
              name={selectedOrganization.name}
              src={selectedOrganization.logoUrl}
              size={24}
            />
          }
          label={selectedOrganization.name}
          sx={{ height: "40px" }}
        />
      ) : (
        <MainSearchBar
          showSayt
          selectTerm={handleSelect}
          search={(q) => searchAndParse(q)}
          placeholder="Search organization..."
          onClose={() => {}}
        />
      )}
      {meta.touched && meta.error && (
        <Typography color="error" variant="body2">
          {meta.error}
        </Typography>
      )}
    </div>
  );
}

function ExperienceFormItem({
  personId,
  experience,
  onDelete,
  onSubmitSuccess,
  disabled,
  isLatest,
}) {
  const [expanded, setExpanded] = useState(isLatest);

  const onSubmit = async (values) => {
    if (values.organization?.valorId) {
      const expBody = {
        valorId: values.organization.valorId,
        title: values.title,
        startDate: values.startDate,
        endDate: values.endDate,
      };
      if (experience.id) {
        await patchExperience(personId, experience.id, expBody);
      } else {
        await postExperience(personId, expBody);
      }
      onSubmitSuccess();
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Title Required";
    }
    if (!values.organization?.valorId) {
      errors.organization = "Organization Required";
    }
    if (!values.startDate) {
      errors.startDate = "Start Date Required";
    }
    return errors;
  };

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        onClick={() => setExpanded(!expanded)}
        expandIcon={<ExpandMoreIcon />}
        sx={{
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" mr={2}>
              {experience.title || "New Experience"}
              {experience.organization?.name &&
                ` - ${experience.organization.name}`}
            </Typography>
            {experience.organization?.logoUrl && (
              <CompanyAvatar
                name={experience.organization.name}
                src={experience.organization.logoUrl}
                size={40}
              />
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={experience}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            submitSucceeded,
          }) => (
            <form onSubmit={handleSubmit}>
              <Field name="title">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    label="Title"
                    fullWidth
                    margin="normal"
                    error={meta.touched && meta.error}
                    helperText={meta.touched && meta.error}
                    disabled={disabled}
                  />
                )}
              </Field>
              <Field name="organization">
                {({ input, meta }) => (
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    <InputLabel>Organization</InputLabel>
                    <OrganizationSelect
                      input={input}
                      meta={meta}
                      disabled={disabled}
                    />
                  </Box>
                )}
              </Field>
              <Field name="startDate">
                {({ input, meta }) => (
                  <DatePickerField
                    label="Start Date"
                    input={input}
                    meta={meta}
                    disabled={disabled}
                  />
                )}
              </Field>
              <Field name="endDate">
                {({ input, meta }) => (
                  <DatePickerField
                    label="End Date"
                    input={input}
                    meta={meta}
                    disabled={disabled}
                  />
                )}
              </Field>
              {submitSucceeded && (
                <Alert sx={{ marginTop: 1 }} severity="success">
                  Experience saved
                </Alert>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting || pristine}
                >
                  Save Experience
                </Button>
                <Button color="error" onClick={() => onDelete(experience.id)}>
                  Remove
                </Button>
              </Box>
            </form>
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
}

function ExperienceForm({
  personId,
  experiences = [],
  valorId,
  companyName,
  logoUrl,
  isNewPerson,
}) {
  const [experienceList, setExperienceList] = useState(() => {
    if (experiences.length === 0 && isNewPerson && valorId) {
      return [
        {
          organization: {
            valorId,
            name: companyName,
            domain: "",
            logoUrl,
          },
          title: "",
          startDate: null,
          endDate: null,
        },
      ];
    }
    return experiences.length === 0
      ? [
          {
            organization: {
              valorId: "",
              name: "",
              domain: "",
              logoUrl: null,
            },
            title: "",
            startDate: null,
            endDate: null,
          },
        ]
      : experiences;
  });
  const handleDelete = async (id) => {
    if (id && personId) {
      await deleteExperience(personId, id);
    }
    setExperienceList(experienceList.filter((exp) => exp.id !== id));
  };

  const handleSubmitSuccess = () => {
    // Optionally refresh the experience list here
  };

  const addExperience = () => {
    setExperienceList([
      ...experienceList,
      {
        organization: {
          valorId: "",
          name: "",
          domain: "",
          logoUrl: null,
        },
        title: "",
        startDate: null,
        endDate: null,
      },
    ]);
  };

  return (
    <Card elevation={4} sx={{ padding: 2, marginBottom: 2 }}>
      {experienceList.map((experience, index) => (
        <ExperienceFormItem
          key={experience.id || index}
          personId={personId}
          experience={experience}
          onDelete={handleDelete}
          onSubmitSuccess={handleSubmitSuccess}
          disabled={!personId}
          isLatest={index === 0}
        />
      ))}
      <Button
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
        sx={{ marginTop: 2 }}
        onClick={addExperience}
        disabled={!personId}
      >
        Add Experience
      </Button>
    </Card>
  );
}

export default ExperienceForm;
