import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import {
  TextField,
  Chip,
  Button,
  Box,
  Autocomplete,
  Card,
  Alert,
  Typography,
} from "@mui/material";
import { patchPerson, createPerson, deletePerson } from "@/api/People";

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export function PersonForm({ person, navigate }) {
  const [formStatus, setFormStatus] = useState(null);

  const onSubmit = async (values) => {
    try {
      if (person?.id) {
        await patchPerson(person.id, values);
        setFormStatus({
          type: "success",
          message: "Person updated successfully",
        });
      } else {
        const newPerson = await createPerson(values);
        setFormStatus({
          type: "success",
          message: "Person created successfully",
        });
        navigate(`/person/${newPerson.id}`);
      }
    } catch (error) {
      setFormStatus({
        type: "error",
        message: "An error occurred. Please try again.",
      });
    }
  };

  const handleDeletePerson = async () => {
    try {
      if (person.id) {
        await deletePerson(person.id);
        setFormStatus({
          type: "success",
          message: "Person deleted successfully",
        });
        setTimeout(() => navigate("/person"), 2000);
      }
    } catch (error) {
      setFormStatus({
        type: "error",
        message: "Failed to delete person. Please try again.",
      });
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) errors.firstName = "Required";
    if (!values.lastName) errors.lastName = "Required";
    if (!values.primaryEmail) errors.primaryEmail = "Required";
    if (!validateEmail(values.primaryEmail))
      errors.primaryEmail = "Invalid email";
    if (values.otherEmails) {
      values.otherEmails.forEach((email, index) => {
        if (!validateEmail(email)) {
          errors.otherEmails = errors.otherEmails || [];
          errors.otherEmails[index] = `Invalid email: ${email}`;
        }
      });
    }
    return errors;
  };

  return (
    <Card elevation={4} sx={{ padding: 2, marginBottom: 2 }}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          firstName: person?.firstName || "",
          lastName: person?.lastName || "",
          primaryEmail: person?.primaryEmail || "",
          otherEmails: person?.otherEmails || [],
        }}
        render={({
          handleSubmit,
          submitting,
          pristine,
          values,
          hasValidationErrors,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography variant="h6">
                {person?.id ? "Edit Person" : "Create New Person"}
              </Typography>

              <Field name="firstName">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    fullWidth
                    label="First Name"
                    error={meta.touched && meta.error}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>

              <Field name="lastName">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    fullWidth
                    label="Last Name"
                    error={meta.touched && meta.error}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>

              <Field name="primaryEmail">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    fullWidth
                    label="Primary Email"
                    error={meta.touched && meta.error}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>

              <Field name="otherEmails">
                {({ input, meta }) => (
                  <Autocomplete
                    multiple
                    freeSolo
                    options={[]}
                    value={input.value}
                    onChange={(event, newValue) => input.onChange(newValue)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={`${option}-${index}`}
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                          color={
                            option === values.primaryEmail
                              ? "primary"
                              : "default"
                          }
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Other Emails (Press Enter to Add)"
                        error={meta.touched && meta.error}
                        helperText={
                          meta.touched && meta.error
                            ? meta.error.join(", ")
                            : null
                        }
                      />
                    )}
                  />
                )}
              </Field>

              {formStatus && (
                <Alert severity={formStatus.type} sx={{ mb: 2 }}>
                  {formStatus.message}
                </Alert>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  justifyContent: "space-between",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting || pristine || hasValidationErrors}
                >
                  {person?.id ? "Update Person" : "Create Person"}
                </Button>
                {person?.id && (
                  <Button
                    onClick={handleDeletePerson}
                    color="error"
                    variant="outlined"
                  >
                    Delete Person
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        )}
      />
    </Card>
  );
}

export default PersonForm;
