import React from "react";
import { Routes, Route } from "react-router-dom";
import Helmet from "react-helmet";
import MarketMapView from "./MarketMapView";
import MarketMaps from "./MarketMaps";
import MarketMapCreateEdit from "./MarketMapCreateEdit";

function MarketMapRoot() {
  return (
    <>
      <Helmet>
        <title>MarketMap - vOS</title>
      </Helmet>
      <Routes>
        <Route path="" element={<MarketMaps />} />
        <Route path="create" element={<MarketMapCreateEdit />} />
        <Route path="edit/:id" element={<MarketMapCreateEdit />} />
        <Route
          path=":slug?/view/:id"
          element={<MarketMapView />}
        />
      </Routes>
    </>
  );
}

export default MarketMapRoot;
