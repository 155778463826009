import React, { useState, useEffect } from "react";
import {
  Zoom, Fab,
} from "@mui/material";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";
import NotPrintable from "@/components/NotPrintable";
import useIsInTouchPWA from "@/hooks/useIsInTouchPWA";

type ScrollToTopProps = {
  scrollable: JSX.Element;
};

function ScrollToTop({
  scrollable,
}: ScrollToTopProps) {
  const el = scrollable;
  const [visible, setVisible] = useState(false);
  const isPWATouch = useIsInTouchPWA();

  function handleScroll(e) {
    setVisible(
      e.target.scrollTop > 300,
    );
  }

  useEffect(() => {
    if (el) {
      el.addEventListener("scroll", handleScroll);
      return () => el.removeEventListener("scroll", handleScroll);
    }
    return () => {};
  });

  async function scrollToTop() {
    el.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <NotPrintable>
      <Zoom
        in={visible}
        sx={{
          zIndex: 405,
          position: "fixed",
          bottom: isPWATouch ? 70 : 20,
          right: 20,
        }}
      >
        <Fab
          color="primary"
          sx={{
            zIndex: 405,
            opacity: 0.7,
          }}
          onClick={() => scrollToTop()}
        >
          <UpIcon />
        </Fab>
      </Zoom>
    </NotPrintable>
  );
}

export default ScrollToTop;
