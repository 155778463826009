/* eslint-disable react/prop-types */
import React from "react";
import { canSeeDev } from "@/constants/Roles";
import CompanyCell from "@/ui/atoms/CompanyCell";
import DateRangeFilter from "@/ui/atoms/TableFilters/DateRangeFilter";
import SelectColumnFilter from "@/ui/atoms/TableFilters/SelectColumnFilter";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import { useQuery } from "react-query";
import { Box } from "@material-ui/core";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import xlsx from "json-as-xlsx";
import { format } from "date-fns";
import { getAllTasks, completeTask } from "@/api/Tasks";
import { useAuth } from "@/hooks/useAuth";
import CustomTable from "@/ui/molecules/CustomTable";
import { colorForStatus, getDaysPastDue } from "./utils";

function AllTasks({ exportToExcel }) {
  const { data: tasks = [], isLoading, refetch } = useQuery(["Tasks"], async () => getAllTasks());
  const { user } = useAuth();

  function exportAllTasks() {
    xlsx(
      [{
        sheet: "All Tasks",
        columns: [
          { label: "Task", value: "taskName" },
          { label: "Company", value: "companyName" },
          { label: "Valor Id", value: "valorId" },
          { label: "Domain", value: "domain" },
          { label: "Assignee", value: "assignee" },
          { label: "Created At", value: "createdAt" },
          { label: "Due Date", value: "dueDate" },
        ],
        content: tasks.map((task) => ({
          taskName: task.taskName,
          companyName: task.companyName,
          valorId: task.valorId,
          domain: task.domain,
          assignee: task.assignee,
          createdAt: dayjs(task.createdAt).format("MMMM DD, YYYY"),
          dueDate: dayjs(task.dueDate).format("MMMM DD, YYYY"),
        })),
      },
      ],
      {
        fileName: "All Tasks", // Name of the resulting spreadsheet
        extraLength: 0, // A bigger number means that columns will be wider
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      },
    );
  }

  React.useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    exportToExcel.current = exportAllTasks;
  }, [tasks]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Task",
        accessor: "taskName",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Company",
        accessor: "companyName",
        // eslint-disable-next-line react/jsx-props-no-spreading
        Cell: ({ row }) => <CompanyCell {...row.original} />,
      },
      {
        Header: "Assignee",
        accessor: (row) => row.assignee,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      // {
      //   Header: "MSL",
      //   accessor: (row) => row.msl,
      //   Filter: SelectColumnFilter,
      //   filter: "equals",
      // },
      {
        Header: "Owners",
        accessor: "ownerNames",
        Cell: ({ value: ownerNames }) => ownerNames.join(", "),
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Created At",
        accessor: (row) => new Date(row.createdAt),
        filter: "dateRange",
        sortType: "date",
        Filter: ({ column }) => <Box style={{ maxWidth: "260" }}><DateRangeFilter filterValue={column.filterValue} setFilter={column.setFilter} /></Box>,
        Cell: ({ value: createdAt }) => format(createdAt, "MMM dd, yyyy"),
      },
      {
        Header: "Due Date",
        accessor: (row) => new Date(row.dueDate),
        filter: "dateRange",
        sortType: "date",
        Filter: ({ column }) => <Box style={{ maxWidth: "260" }}><DateRangeFilter filterValue={column.filterValue} setFilter={column.setFilter} /></Box>,
        Cell: ({ value: dueDate }) => format(dueDate, "MMM dd, yyyy"),
      },
      {
        Header: "Days past Due",
        accessor: (row) => getDaysPastDue(row.dueDate),
      },
      {
        Header: "Status",
        accessor: (row) => row.status,
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: ({ cell: { value } }) => <p style={{ color: colorForStatus[value], fontWeight: "bold" }}>{value}</p>,
      },
      canSeeDev(user)
        ? {
          Header: "Close Task",
          accessor: (row) => row.taskId,
          Cell: ({ cell: { value } }) => (
            <Button
              color="primary"
              variant="contained"
              onClick={async () => {
                completeTask(value);
                refetch();
              }}
            >
              Close
            </Button>
          ),
        } : null,
    ].filter((x) => x),
    [user.roleId, refetch],
  );

  return (
    <CenteredProgress isLoading={isLoading}>
      <CustomTable
        title="Tasks &amp; Alerts"
        data={tasks}
        columns={columns}
      />
    </CenteredProgress>
  );
}

export default AllTasks;
