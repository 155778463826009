/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";
import { useQuery } from "react-query";
import {
  Box,
  Typography,
  Skeleton,
} from "@mui/material";
import { getNoteByValorIdAndOpportunityId, Note } from "@/api/Notes";

export function OpportunityNoteCell({ opportunityId, daysAgo, noteCount }) {
  const { data: noteData = [] as Note[], isLoading } = useQuery(
    ["opportunityNote", opportunityId, daysAgo, noteCount],
    async () => {
      const notes = await getNoteByValorIdAndOpportunityId(
        "00000000-0000-0000-0000-000000000000",
        opportunityId,
        daysAgo,
        noteCount,
      );
      return notes;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    },
  );
  if (isLoading) return <Skeleton variant="text" />;
  if (!noteData || !noteData.length) {
    return <>-</>;
  }
  return (
    <Box
      sx={{
        display: "flex",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: null,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "normal",
        gap: 2,
        height: "100%",
      }}
      flexDirection="column"
    >
      {noteData?.map((note) => (
        <Typography
          key={note.documentId}
          sx={(t) => ({
            color: t.palette.text.primary,
          })}
        >
          {note.plainText}
        </Typography>
      ))}
    </Box>
  );
}

export default OpportunityNoteCell;
