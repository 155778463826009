import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { searchNotes } from "@/api/Notes";
import { useQuery } from "react-query";
import { groupBy } from "lodash";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { Box } from "@mui/material";
import CompanyNotesTable from "./CompanyNotesTable";
import MeetingPage from "../MeetingPage";
import { FundList, FundListFilter } from "../utils";

const noteFlavors = {
  "liquidity-events": {
    title: "Liquidity Events",
    type: "Liquidity Event - Active",
  },
  "pro-entropic-m-a": {
    title: "Pro-Entropic M&A",
    type: "Pro-Entropic M&A - Active",
  },
  "fund-raising": {
    title: "Fundraising (Debt & Equity)",
    type: "Fundraising (Debt and Equity) – Active",
  },
};

const noteOptions = Object.keys(noteFlavors).map((key) => ({
  label: noteFlavors[key].title,
  key,
}));

function MeetingNotesPages() {
  const { subPage } = useParams<{ subPage?: string }>();
  const navigate = useNavigate();
  const [activeFund, setActiveFund] = useState(null);
  useEffect(() => {
    setActiveFund(null);
  }, [subPage]);

  const noteFlavor = noteFlavors[subPage];

  const { type } = noteFlavor ?? {};
  const {
    data = {},
    isLoading,
    error,
  } = useQuery(
    type,
    async () => {
      const response = await searchNotes(type, null, null, null, 0, 100);
      return groupBy(response?.hits ?? [], "valorId"); // change
    },
    {
      enabled: !!noteFlavor,
    },
  );

  const flatNotes = Object.values(data ?? {}).flat();

  const buttonOptions = FundList.filter((x) => x !== "R&D").map((key) => [key, FundListFilter[key]]).map(
    ([fundName, fundBoolConditional]) => {
      let shouldDisable = !flatNotes.some(
        (x) => x?.funds.primaryFund === fundName,
      );
      const anySecondary = shouldDisable
        && flatNotes.every((x) => (x?.funds.primaryFund === null
          ? x?.funds[fundBoolConditional] === false
          : true));
      shouldDisable = shouldDisable && anySecondary;

      return {
        label: fundName,
        key: fundBoolConditional,
        disabled: fundName === "VAF" ? true : shouldDisable,
      };
    },
  );

  const coNotes = Object.values(data ?? {});
  let sortedNotes = coNotes.sort((a, b) => {
    const maxUpdate = (x) => Math.max(...x.map((y) => (y.updatedAt)));
    return maxUpdate(b) - maxUpdate(a);
  });

  if (sortedNotes.length) {
    sortedNotes = sortedNotes.map((notes) => {
      const fundNotes = notes.filter((x) => {
        if (activeFund === FundListFilter[x.funds.primaryFund]) {
          return true;
        } if (x.funds.primaryFund === null) {
          return x.funds[activeFund] === true;
        }
        return false;
      });
      return fundNotes;
    });
    sortedNotes = sortedNotes.filter((x) => x.length);
  }

  const buttonTabs = (
    <ButtonTabs
      width="720px"
      wrap
      tabLabel="Financing Type"
      activeKey={subPage}
      options={noteOptions}
      onClick={(option) => navigate(
        `../weekly-ic/portfolio-financing/${option.key}`,
      )}
    />
  );
  return (
    <MeetingPage title="Portfolio Company Financing">
      <Box m={1}>{buttonTabs}</Box>
      {subPage ? (
        <>
          <Box m={1} height={80}>
            <ButtonTabs
              width="720px"
              wrap
              tabLabel="Fund"
              activeKey={activeFund}
              options={buttonOptions}
              disableTest={(option) => option.disabled}
              onClick={(option) => setActiveFund(option.key)}
            />
          </Box>
          {activeFund ? (
            <CompanyNotesTable
              companyNotes={sortedNotes}
              isLoading={isLoading}
              error={error}
            />
          ) : null}
        </>
      ) : null}
    </MeetingPage>
  );
}

export default MeetingNotesPages;
