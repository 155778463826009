import React from "react";
import { SignalChip } from "@/components/SignalChip";

type MoicCellProps = {
  valorId: string,
  signal: {
    signalType: string;
    score: number;
  } | null;
};

export default function DisclaimerMoicCell({ signal, valorId }: MoicCellProps) {
  if (!signal) {
    return <>-</>;
  }
  // Hard coding mantis to show just moic multiple
  return (
    <SignalChip
      signal={signal}
      valorId={valorId}
      showIcon
    />
  );
}
