import React, { useEffect } from "react";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { Box, Button } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import {
  ProcessProvider,
  useProcessData,
  useProcessActions,
} from "./ProcessContext";
import TableView from "./TableView";

function TableContainer({
  viewId,
  disableFilters = false,
}: {
  viewId: string;
  disableFilters?: boolean;
}) {
  const navigate = useNavigate();
  const {
    id: processId,
    tasks,
    isLoadingTasks,
    fields,
    isFetching,
    // settings,
    sorting,
    columnFilters,
    columnVisibility,
    columnOrder,
    views,
    fieldFilters,
    entityName,
  } = useProcessData();

  const {
    fetchNextPage,
    setSorting,
    setColumnFilters,
    setColumnVisibility,
    setColumnOrder,
    getFilterCounts,
  } = useProcessActions();

  const viewReport = `/process-management/${processId}?view=table&activeView=${viewId}`;
  const activeView = React.useMemo(
    () => views?.find((v) => v.id === viewId),
    [views, viewId],
  );

  const [, setActiveViewId] = useQueryParam(
    "activeView",
    StringParam,
  );

  useEffect(
    () => {
      if (viewId) {
        setActiveViewId(viewId);
      }
    },
    [viewId, setActiveViewId],
  );

  const [activeHotFilter, setActiveHotFilter] = React.useState(null);
  // get hotfilter options
  const hotFilterId = "24f4430a-25a5-4d87-beda-5c3c3467ed31";
  const hotFilter = fields?.find((f) => f.id === hotFilterId);
  const hotFilterOptions = (hotFilter?.choices || []).map((m) => ({
    key: m.id,
    label: m.value,
    disabled: false,
  }));

  const { data = {}, isLoading: isLoadingHotData } = useQuery(
    ["hotFilter", hotFilterId, viewId, activeView?.columnFilters],
    async () => {
      const filterCounts = await getFilterCounts(hotFilterId);
      return filterCounts.reduce((acc, { value, count }) => {
        acc[value] = Boolean(count);
        return acc;
      }, {});
    },
    {
      enabled: !!hotFilter,
    },
  );

  return (
    <Box>
      <Box m={1} display="flex" gap={1} alignItems="center">
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate(viewReport)}
        >
          Edit
        </Button>
        {disableFilters ? null : (
          <ButtonTabs
            options={hotFilterOptions}
            activeKey={activeHotFilter?.key}
            width="100%"
            disableTest={(option) => isLoadingHotData || !data[option.key]}
            evenSizedButtons
            onClick={(option) => {
              if (!option) {
                setActiveHotFilter(null);
                setColumnFilters((prev) => prev.filter(
                  (f) => f.id !== `tasks.fieldValues.${hotFilterId}.choiceId`,
                ));
              } else {
                setActiveHotFilter(option);
                setColumnFilters((prev) => {
                  const next = [
                    ...(prev.filter(
                      (f) => f.id !== `tasks.fieldValues.${hotFilterId}.choiceId`,
                    ) || []),
                    {
                      id: `tasks.fieldValues.${hotFilterId}.choiceId`,
                      operator: "eq",
                      value: [
                        {
                          label: option.label,
                          value: option.key,
                        },
                      ],
                    },
                  ];
                  return next;
                });
              }
            }}
          />
        )}
      </Box>
      <TableView
        enableViews={false}
        isLoading={isLoadingTasks}
        fields={fields}
        processId={processId}
        tasks={tasks}
        views={views}
        fieldFilters={fieldFilters}
        entityName={entityName}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        sorting={sorting || []}
        columnFilters={columnFilters || []}
        columnOrder={columnOrder || []}
        columnVisibility={columnVisibility || {}}
        onColumnOrderChange={setColumnOrder}
        onSortingChange={setSorting}
        onColumnFilterChange={setColumnFilters}
        onColumnVisibilityChange={setColumnVisibility}
        enableColumnFilter={false}
        enableSorting={false}
      />
    </Box>
  );
}

export default function TableReport({
  processId,
  viewId,
  disableFilters = false,
}: {
  processId: string;
  viewId: string;
  disableFilters?: boolean;
}) {
  // store sorting / filters / etc state here
  const [sorting, setSorting] = React.useState([]);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [columnOrder, setColumnOrder] = React.useState([]);
  return (
    <ProcessProvider
      processId={processId}
      mode="ungrouped"
      viewId={viewId}
      sorting={sorting}
      columnFilters={columnFilters}
      columnVisibility={columnVisibility}
      columnOrder={columnOrder}
      onColumnFiltersChange={setColumnFilters}
      onColumnVisibilityChange={setColumnVisibility}
      onColumnOrderChange={setColumnOrder}
      onSortingChange={setSorting}
    >
      <TableContainer viewId={viewId} disableFilters={disableFilters} />
    </ProcessProvider>
  );
}
