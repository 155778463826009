import React from "react";
import { Box } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import LeanGrowthUpdates from "../LeanGrowthUpdates/OneFirm";
import RevGen from "../RevGen";
import HumanCapitalSlide from "../HumanCapital";

export default function ScaleGroupApprovals() {
  const navigate = useNavigate();
  const params = useParams<{ page?: string; subPage?: string }>();
  const { subPage } = params;
  const tabs = [
    { key: "lean-growth", label: "Lean/Growth" },
    { key: "rev-gen", label: "Revenue Generation" },
    { key: "people-ops", label: "Human Capital" },
  ];

  const linkLookup = {
    "rev-gen": "/weekly-meeting/weekly-ic/requested-scale-group-approvals/rev-gen/portco-update",
  };

  const active = subPage || tabs[0].key;

  const options = tabs.map((tab) => ({
    key: tab.key,
    label: tab.label,
  }));
  return (
    <Box>
      <Box mb={1}>
        <ButtonTabs
          width="480px"
          tabLabel="Scale Group Dept."
          options={options}
          activeKey={active}
          onClick={(option) => {
            if (linkLookup[option.key]) {
              navigate(linkLookup[option.key]);
            } else {
              navigate(
                `/weekly-meeting/weekly-ic/requested-scale-group-approvals/${option.key}`,
                // { relative: "path" },
              );
            }
          }}
        />
      </Box>

      {active === "lean-growth" && <LeanGrowthUpdates />}
      {active === "rev-gen" && <RevGen />}
      {active === "people-ops" && <HumanCapitalSlide />}
    </Box>
  );
}
