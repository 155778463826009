/* eslint-disable react/prop-types */
import React from "react";

import CustomTable from "@/ui/molecules/CustomTable";
import { Box, useTheme } from "@material-ui/core";
import { getDaysPastDue } from "../utils";

import { sharedColumns } from "./TableConfig";

function UserTasksTable({
  tasks,
  department,
  fetchTasks,
  closeTask,
}) {
  const theme = useTheme();

  return (
    <Box data-cy="overdue-table">
      <CustomTable
        title="Overdue"
        titleStyle={{
          color: "red",
          fontWeight: "bold",
        }}
        data={tasks.overdue}
        columns={React.useMemo(
          () => [
            ...sharedColumns("overdue", closeTask, department),
            {
              Header: "Days past Due",
              accessor: (row) => getDaysPastDue(row.dueDate),
              disableFilters: true,
            },
          ],
          [fetchTasks],
        )}
      />
      <Box data-cy="upcoming-table" marginTop={2} marginBottom={2}>
        <CustomTable
          title="Upcoming"
          titleStyle={{
            color: theme.palette.yellow.main,
            fontWeight: "bold",
          }}
          data={tasks.upcoming}
          columns={React.useMemo(
            () => sharedColumns(
              // 0.3333,
              "upcoming",
              closeTask,
              department,
            ),
            [fetchTasks],
          )}
        />
      </Box>
    </Box>
  );
}

export default UserTasksTable;
