import React from "react";
import Page from "@/ui/molecules/Page";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button } from "@mui/material";

import { useQuery } from "react-query";
import { getPerson } from "@/api/People";
import Progress from "@/ui/atoms/Progress";
import PersonForm from "./PersonForm";
import ExperienceForm from "./ExperienceForm";

export function Person() {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { state } = location || {};
  const { valorId = "", companyName = "", logoUrl = "" } = state || {};

  const { data, isLoading } = useQuery(["Person", id], () => getPerson(id), {
    enabled: !!id,
  });
  const { experience, ...person } = data || {};
  const navigate = useNavigate();
  if (isLoading) {
    return <Progress />;
  }

  return (
    <Page>
      <Page.Title>{person.id ? "Create Person" : "Edit Person"}</Page.Title>
      <Page.Content>
        {valorId ? (
          <Button
            sx={{ marginBottom: 2 }}
            variant="outlined"
            onClick={() => navigate(`/org/${valorId}/contacts`)}
          >
            Back to {companyName}
          </Button>
        ) : null}
        <PersonForm
          person={person}
          navigate={(path) =>
            navigate(path, { state: { valorId, companyName } })
          }
        />

        <ExperienceForm
          personId={person.id}
          experiences={experience}
          valorId={valorId}
          companyName={companyName}
          logoUrl={logoUrl}
          isNewPerson={!person.id}
        />
      </Page.Content>
    </Page>
  );
}

export default Person;
