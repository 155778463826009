import React, {
  useState, useCallback, useContext, ReactNode,
} from "react";
import {
  IconButton,
  Popover,
  Box,
  ClickAwayListener,
  Modal,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { ErrorBoundary } from "@/utils/ErrorBoundary";

interface PopoverContextValue {
  isOpen: boolean;
  anchorRef: React.RefObject<HTMLElement> | null;
  popoverContent: ReactNode | null;
  popoverTitle: string | null;
  openPopover: (ref: React.RefObject<HTMLElement>, content: ReactNode) => void;
  closePopover: () => void;
}

const initialStatus: PopoverContextValue = {
  isOpen: false,
  anchorRef: null,
  popoverContent: null,
  popoverTitle: null,
  openPopover: () => {},
  closePopover: () => {},
};

const PopoverContext = React.createContext<PopoverContextValue | null>(
  initialStatus,
);

interface PopoverProviderProps {
  children: ReactNode;
}

export const usePopover = (): PopoverContextValue => (
  useContext<PopoverContextValue>(PopoverContext));

function useProvidePopover() {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorRef, setAnchorRef] = useState<React.RefObject<HTMLElement> | null>(null);
  const [popoverContent, setPopoverContent] = useState<ReactNode | null>(null);
  const [popoverTitle, setPopoverTitle] = useState<string | null>(null);

  const openPopover = useCallback(
    (ref: React.RefObject<HTMLElement>, content: ReactNode, title: string) => {
      setAnchorRef(ref);
      setPopoverContent(content);
      setPopoverTitle(title);
      setIsOpen(true);
    },
    [],
  );

  const closePopover = useCallback(() => {
    setIsOpen(false);
    setAnchorRef(null);
    setPopoverContent(null);
    setPopoverTitle(null);
  }, []);

  return {
    isOpen,
    anchorRef,
    popoverContent,
    popoverTitle,
    openPopover,
    closePopover,
  };
}

export function ProvidePopover({ children }: PopoverProviderProps) {
  const popover = useProvidePopover();
  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  );
}

const StyledPopover = styled(Popover)(({ theme }) => ({
  ".MuiPopover-paper": {
    maxWidth: "370px",
    display: "flex",
    padding: theme.spacing(1),
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledModal = styled(Modal)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export function GlobalPopover() {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    isOpen, anchorRef, popoverContent, popoverTitle, closePopover,
  } = usePopover();
  if (!anchorRef || !anchorRef.current) {
    return null;
  }
  const getIsOutOfViewport = () => {
    const rect = anchorRef.current?.getBoundingClientRect();
    const outOfViewport = rect && rect.bottom + 40 > window.innerHeight;
    return outOfViewport;
  };
  const getAnchorOrigin = () => {
    const outOfViewport = getIsOutOfViewport();
    return {
      vertical: outOfViewport ? "bottom" : "top",
      horizontal: "center",
    };
  };
  const getTransformOrigin = () => {
    const outOfViewport = getIsOutOfViewport();
    return {
      vertical: outOfViewport ? "bottom" : "top",
      horizontal: "center",
    };
  };

  return isSmDown ? (
    <StyledModal open={isOpen} onClose={closePopover}>
      <Box
        sx={{
          maxWidth: "370px",
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(2),
          borderRadius: theme.spacing(1),
          boxShadow: theme.shadows[5],
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {popoverTitle ? <Typography sx={{ flex: 1 }} variant="boldBody1">{popoverTitle}</Typography> : null}
          <IconButton onClick={() => closePopover()}>
            <Close />
          </IconButton>
        </Box>
        <ErrorBoundary errorMessage="A problem occurred loading, Please contact the labs team if this persists">
          {popoverContent}
        </ErrorBoundary>
      </Box>
    </StyledModal>
  ) : (
    <ClickAwayListener onClickAway={() => closePopover()}>
      <StyledPopover
        open={isOpen}
        anchorEl={anchorRef.current}
        onClose={closePopover}
        anchorOrigin={getAnchorOrigin()}
        transformOrigin={getTransformOrigin()}
      >
        <Box>
          <Box sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            padding: theme.spacing(1),
          }}
          >
            <Typography variant="boldBody1">{popoverTitle}</Typography>
            <IconButton onClick={() => closePopover()}>
              <Close />
            </IconButton>
          </Box>
          <ErrorBoundary errorMessage="A problem occurred loading, Please contact the labs team if this persists">
            {popoverContent}
          </ErrorBoundary>
        </Box>
      </StyledPopover>
    </ClickAwayListener>
  );
}

export default ProvidePopover;
