import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { getActiveVSVDealsWeeklyMeeting, getActiveGrowthDealsWeeklyMeeting } from "@/api/Reporting";
import {
  Alert,
  Box,
  Card,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Progress from "@/ui/atoms/Progress";
import { DataTable } from "@/components/LocalTable";
import {
  CompanyCell,
  TextCell,
  UserCell,
  MoneyCell,
  MultiUserCell,
} from "@/components/Table/Primitives";
import { SelectFilter } from "@/components/Table/CustomFilters/Select";

function FundAnnouncements() {
  const announcementStatuses = [
    "Investment Team Drafting",
    "Company Review",
    "IR Review",
    "Legal Review",
  ];
  const { data: dealData = [], isLoading } = useQuery(
    ["VSVDealData", announcementStatuses],
    async () => getActiveVSVDealsWeeklyMeeting(null, announcementStatuses, null),
    {
      staleTime: 10 * 60 * 1000,
    },
  );

  const { data: growthDealData = [], isLoading: isLoadingGrowth } = useQuery(
    ["GrowthDealData", announcementStatuses],
    async () => getActiveGrowthDealsWeeklyMeeting(null, null, null, announcementStatuses),
    {
      staleTime: 10 * 60 * 1000,
    },
  );

  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const columns = useMemo(
    () => [
      {
        header: "Company",
        accessorFn: (row) => row.organization,
        cell: (prop) => <CompanyCell value={prop.getValue()} />,
        id: "organization",
        getFilterValue: (row) => row.organization?.name.toLowerCase(),
        getSortValue: (row) => row.organization?.name.toLowerCase(),
        sortingFn: (rowA, rowB) => rowA.original.organization.name.localeCompare(
          rowB.original.organization.name,
        ),
        filterFn: "fuzzy",
        minSize: isSmDown ? 48 : 250,
        size: isSmDown ? 48 : 250,
      },
      {
        header: "Deal Name",
        accessorFn: (row) => row.dealName,
        cell: (prop) => (
          <Typography
            sx={(t) => ({
              color: t.palette.text.primary,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "normal",
            })}
          >
            <Link
              href={`https://valor.affinity.co/opportunities/${prop.row.original.dealId}`}
              target="_blank"
              rel="noreferrer"
            >
              {prop.getValue()}
            </Link>
          </Typography>
        ),
        id: "dealName",
        sortingFn: "alphanumeric",
        minSize: 100,
      },
      {
        header: "Announcement Status",
        accessorFn: (row) => row.investmentAnnouncement,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        id: "investmentAnnouncement",
        filter: SelectFilter,
        minSize: 100,
      },
      {
        header: "Target Fund",
        accessorFn: (row) => row.targetFund,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        id: "targetFund",
        filter: SelectFilter,
        minSize: 100,
      },
      {
        header: "Check Type",
        accessorFn: (row) => row.dealType,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        id: "dealType",
        filter: SelectFilter,
        minSize: 100,
      },
      {
        header: "Amount",
        accessorFn: (row) => row.amount,
        cell: (prop) => <MoneyCell value={prop.getValue()} />,
        meta: {
          sortType: "numeric",
        },
        id: "amount",
        filter: SelectFilter,
        minSize: 100,
      },
      {
        header: "MSL",
        accessorFn: (row) => row.msl,
        cell: (prop) => <UserCell value={prop.getValue()} />,
        id: "msl",
        sortingFn: (rowA, rowB) => rowA.original.msl.lastName.localeCompare(
          rowB.original.msl.lastName,
        ),
        enableColumnFilter: false,
        minSize: 120,
      },
      {
        header: "Deal Owners",
        accessorFn: (row) => row.owners,
        cell: (prop) => <MultiUserCell value={prop.getValue()} />,
        id: "owners",
        enableColumnFilter: false,
        enableSorting: false,
        minSize: 100,
        size: 250,
      },
    ].filter((x) => x),
    [isSmDown],
  );

  const combinedData = dealData.concat(growthDealData).sort((a, b) => {
    const aStatus = a.investmentAnnouncement;
    const bStatus = b.investmentAnnouncement;
    if (aStatus === bStatus) {
      return 0;
    }
    return announcementStatuses.indexOf(aStatus) > announcementStatuses.indexOf(bStatus) ? 1 : -1;
  }).sort((a, b) => {
    const aDealName = a.dealName;
    const bDealName = b.dealName;
    if (aDealName === bDealName) {
      return 0;
    }
    return aDealName > bDealName ? 1 : -1;
  });
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {isLoading || isLoadingGrowth ? (
        <Box mt={3}>
          <Progress />
        </Box>
      ) : (
        <Card elevation={0} sx={{ height: "auto" }}>
          {combinedData.length ? (
            <DataTable data={combinedData} columns={columns} />
          ) : (
            <Alert severity="info" sx={{ margin: 2 }} data-cy="no-data-message">
              No LP/AB meetings with an update.
            </Alert>
          )}
        </Card>
      )}
    </Box>
  );
}
export default FundAnnouncements;
