import React from "react";
import { SignalChip } from "@/components/SignalChip";

type PredictedMoicCellProps = {
  value: number;
  country?: string;
  valorId: string;
};

export default function PredictedMoicCell({ value, country, valorId }: PredictedMoicCellProps) {
  if (value === null) {
    if (country !== "US") {
      return "Not US";
    }
    return "-";
  }

  return (
    <SignalChip
      signal={{
        signalType: "signal_fimoica_prediction",
        score: value,
      }}
      valorId={valorId}
      showIcon
    />
  );
}
