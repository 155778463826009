import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import { canSeeProcess } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import PageNotFound from "@/ui/organisms/PageNotFound";

import {
  JsonParam, StringParam, useQueryParam, withDefault, ArrayParam,
} from "use-query-params";
import useDebounce from "@/hooks/useDebounce";
import { useTheme } from "@mui/material";
import { ProcessProvider } from "./ProcessContext";

import ProcessView from "./ProcessView";

export default function Process() {
  const theme = useTheme();
  const { processId } = useParams<{ processId: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const hasAccessToProcess = canSeeProcess(user, processId);

  const [query, setQuery] = useQueryParam("q", StringParam);

  const [sort, setSort] = useQueryParam("sort", withDefault(JsonParam, []));
  const [filter, setFilter] = useQueryParam("filter", withDefault(JsonParam, []));
  const [visibility, setVisibility] = useQueryParam("visibility", withDefault(JsonParam, []));
  const [order, setOrder] = useQueryParam("order", withDefault(ArrayParam, []));

  const [view] = useQueryParam("view", withDefault(StringParam, window.innerWidth < theme.breakpoints.values.md ? "list" : "board"));
  const [groupBy, setGroupBy] = useQueryParam("groupBy", withDefault(StringParam, ""));

  const debouncedQuery = useDebounce(query, 350);
  // redirect to home if accessing other process
  if (
    (!processId || (!hasAccessToProcess && processId))
  ) {
    navigate("/home");
  }

  if (!hasAccessToProcess && processId) {
    return (
      <PageNotFound />
    );
  }

  return (
    <ProcessProvider
      processId={processId}
      mode={view === "table" || view === "archived" ? "ungrouped" : "grouped"}
      activeGroup={groupBy}
      query={debouncedQuery}
      archived={view === "archived"}
      sorting={sort}
      columnFilters={filter}
      columnVisibility={visibility}
      columnOrder={order}
      onSortingChange={setSort}
      onColumnFiltersChange={(value) => {
        setFilter(value);
      }}
      onColumnVisibilityChange={setVisibility}
      onColumnOrderChange={setOrder}
    >
      <ProcessView
        query={query}
        onQueryChange={setQuery}
        view={view}
        groupBy={groupBy}
        onGroupByChange={setGroupBy}
      />
    </ProcessProvider>
  );
}
