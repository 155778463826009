import React from "react";
import dayjs from "dayjs";
import { Chip } from "@mui/material";

export const SPECIAL_FIELD_SETTING_IDS = {
  NAME: "33a4daaf-4e75-4f8b-9eca-45e853b6e9ed",
  DESCRIPTION: "29a74b46-d168-4caf-a5f8-1b33f48a09c1",
};

export const getPrimaryUser = (task, fields, includeMulti) => {
  const name = "Assignee"; // default to Assignee
  const userFields = fields.filter(
    (f) => f.type === "user" || (includeMulti && f.type === "user_multi"),
  );
  const field = userFields.find((f) => f.isPrimary && f.showOnCard)
    || userFields.find((f) => f.name === name);
  if (field) {
    const fieldValue = task.fieldValues?.[field.id];
    if (fieldValue) {
      return fieldValue.user || fieldValue.users;
    }
  }
  return null;
};

export const getPrimaryCompany = (task, fields) => {
  const companyFields = fields.filter((f) => f.type === "company");
  const field = companyFields.find((f) => f.isPrimary && f.showOnCard);
  if (field) {
    const fieldValue = task.fieldValues?.[field.id];
    if (fieldValue) return fieldValue.company;
  }
  return null;
};

export const getNameField = (fields) => {
  const names = ["Name", "Opportunity Name"]; // default to Name
  const field = fields?.find((f) => f?.settingId === SPECIAL_FIELD_SETTING_IDS.NAME)
    || fields?.find((f) => f.name.includes(names));
  return field;
};

export const getNameFieldValue = (task, fields) => {
  const field = getNameField(fields);
  if (field) {
    const fieldValue = task.fieldValues?.[field.id];
    if (fieldValue) return fieldValue.value;
  }
  return null;
};

export const getDescriptionField = (fields) => {
  const name = "Description"; // default to Name
  const field = fields?.find(
    (f) => f?.settingId === SPECIAL_FIELD_SETTING_IDS.DESCRIPTION,
  ) || fields?.find((f) => f.name === name);
  return field;
};

export const getDescriptionFieldValue = (task, fields) => {
  const field = getDescriptionField(fields);
  if (field) {
    const fieldValue = task.fieldValues?.[field.id];
    if (fieldValue) return fieldValue.value;
  }
  return null;
};

export const getFieldsToShow = (fields, groupedByFieldId, task) => fields
  .filter((f) => f.showOnCard && f.id !== groupedByFieldId)
  .map((f) => ({
    field: f,
    fieldValue: task.fieldValues?.[f.id],
  }))
  .filter((f) => f.fieldValue);

export const getHasLifecycle = (lifecycleStages) => !!lifecycleStages.length;

export const getFieldsByStage = (lifecycleId, lifecycleStages, fields) => {
  if (!lifecycleStages.length) {
    return null;
  }
  // get choice sort order from field data
  const lifecycleField = fields.find((f) => f.id === lifecycleId);
  const lifecycleChoices = lifecycleField?.choices || [];

  const stage = lifecycleStages.toSorted((a, b) => {
    if (a.sort !== null && b.sort !== null) {
      return a.sort - b.sort;
    }

    if (a.stageId === null) {
      return -1;
    }
    if (b.stageId === null) {
      return 1;
    }

    // get choice
    const aChoice = lifecycleChoices.find((c) => c.id === a.stageId);
    const bChoice = lifecycleChoices.find((c) => c.id === b.stageId);

    if (!aChoice || !bChoice) {
      return 0;
    }

    return aChoice.sort - bChoice.sort;
  });

  const undefinedStage = {
    stageId: null,
    stageName: null,
    isFieldDepednent: stage.some((s) => s.stageId),
    fields: fields
      .filter((f) => f.showOnForm)
      .filter(
        (f) => !lifecycleStages.some((s) => s.fields.some((sf) => sf.childProcessFieldId === f.id)),
      )
      .map((usf) => ({
        ...usf,
        sortOverride: null,
        required: false,
        visible: usf.visible ?? true,
      })),
  };
  const stages = stage.map((s) => ({
    stageId: s.stageId ?? s.stageCategoryId,
    stageName: s.stageName,
    isFieldDependent: !s.stageId,
    fields: s.fields
      .map((sf) => {
        const existingField = fields.find(
          (f) => f.id === sf.childProcessFieldId,
        );
        return {
          ...existingField,
          sortOverride: sf.sort,
          required: sf?.required || existingField?.required || false,
          visible: sf.visible ?? !s.stageCategoryId,
        };
      })
      .toSorted((a, b) => {
        if (a.sortOverride && b.sortOverride) {
          return a.sortOverride - b.sortOverride;
        }
        if (a.sortOverride) {
          return -1;
        }
        if (b.sortOverride) {
          return 1;
        }
        return a.sort - b.sort;
      }),
  }));
  return [undefinedStage, ...stages];
};

function parseInterval(intervalStr) {
  if (!intervalStr.includes("INTERVAL")) return null;

  const parts = intervalStr.split(" ");
  const sign = parts[1] === "+" ? 1 : -1;
  const number = parseInt(parts[2].replace("'", ""), 10);
  const unit = parts[3];

  if (unit === "day") {
    return dayjs()
      .startOf("day")
      .add(sign * number, "day")
      .format("MM/DD/YYYY");
  }

  // Add more unit cases as needed
  return null;
}

export const processFilterValue = (filter, filterValue, fields, operator) => {
  const processChoice = (v) => {
    const choices = fields.filter((f) => f.choices).flatMap((f) => f.choices);
    const choice = choices.find((c) => c.id === v);
    return choice ? { value: choice.id, label: choice.value } : null;
  };

  const splitFilter = filter.split(".");
  const processFilterId = splitFilter[2];

  const filteringField = fields.find((x) => x.id === processFilterId);

  if (filter.endsWith(".choiceId")) {
    if (Array.isArray(filterValue)) {
      return filterValue.map(processChoice).filter(Boolean);
    }
    const result = processChoice(filterValue);
    return result ? [result] : [];
  }
  const rangeFields = ["date", "target_date", "number", "dollar"];
  if (filter === "dateAdded" || rangeFields.includes(filteringField.type)) {
    let val = filterValue;
    if (filterValue?.toString().indexOf("INTERVAL") !== -1) {
      val = parseInterval(filterValue);
    }
    if (operator === "gte") {
      return {
        min: val,
        max: null,
      };
    }
    if (operator === "lte") {
      return {
        min: null,
        max: val,
      };
    }
  }

  return filterValue;
};

export const processFilterType = (filterType, fields) => {
  const operators = Object.keys(filterType);
  return operators
    .map((op) => {
      const filters = Object.keys(filterType[op]);
      const values = filters.map((filter) => {
        const val = filterType[op][filter];
        return {
          id: filter,
          value: processFilterValue(filter, val, fields, op),
          operator: op,
        };
      });
      return values;
    })
    .flat();
};
export const filterBodyToArray = (filterBody, fields) => {
  if (!fields || fields.length === 0) {
    return [];
  }
  // get filters
  const { AND = {}, OR = {}, NOT = {} } = filterBody;

  return [
    ...processFilterType(AND, fields),
    ...processFilterType(OR, fields),
    ...processFilterType(NOT, fields),
  ];
};

export const renderFieldValue = (
  {
    field,
    fieldValue,
  }: { field: FieldList[0]; fieldValue: Task["fieldValues"][0] },
  theme,
) => {
  const RYGBackgrounds = {
    R: theme.palette.error.main,
    Y: theme.palette.warning.main,
    G: theme.palette.success.main,
  };

  if (!fieldValue) return null;

  if (field.type === "select") {
    const fieldChoice = field.choices?.find(
      (choice) => choice.id === fieldValue.choiceId,
    )?.value;

    if (fieldChoice) {
      return (
        <Chip
          key={field.id}
          label={
            field.choices.find((choice) => choice.id === fieldValue.choiceId)
              ?.value
          }
          size="small"
          variant="outlined"
          style={{
            maxWidth: 140,
          }}
        />
      );
    }
    return null;
  }
  if (field.type === "select_multi") {
    const fieldChoices = fieldValue.choices?.map(
      (choiceId) => field.choices?.find((choice) => choice.id === choiceId)?.value,
    );
    if (fieldChoices?.length > 0) {
      return (
        <Chip
          key={field.id}
          label={fieldChoices.join(", ")}
          size="small"
          variant="outlined"
          style={{
            maxWidth: 140,
          }}
        />
      );
    }
    return null;
  }
  if (field.type === "ryg" && fieldValue.value) {
    return (
      <Chip
        key={field.id}
        label={fieldValue.value}
        size="small"
        style={{
          backgroundColor: RYGBackgrounds[fieldValue.value],
          color: "white",
          fontWeight: theme.typography.fontWeightBold,
        }}
      />
    );
  }
  if (field.type === "date" && fieldValue.value) {
    return (
      <Chip
        key={field.id}
        label={`Due ${dayjs(Number(fieldValue.value)).format("DD/MM/YYYY")}`}
        size="small"
        variant="outlined"
      />
    );
  }
  if (field.type === "target_date" && fieldValue.value) {
    return (
      <Chip
        key={field.id}
        label={`Due ${dayjs(Number(fieldValue.value)).fromNow()}`}
        size="small"
        variant="outlined"
      />
    );
  }
  if (field.type === "target_date" && fieldValue.value) {
    return (
      <Chip
        key={field.id}
        label={`Due ${dayjs(Number(fieldValue.value)).fromNow()}`}
        size="small"
        variant="outlined"
      />
    );
  }
  if (field.type === "checkbox" && fieldValue.value === "Yes") {
    return (
      <Chip key={field.id} label={field.name} size="small" variant="outlined" />
    );
  }
  if (field.type === "text" && fieldValue.value) {
    return (
      <Chip
        key={field.id}
        label={fieldValue.value}
        size="small"
        variant="outlined"
        style={{
          maxWidth: 140,
        }}
      />
    );
  }
  return null;
};

export const onDragEndHandler = (updateTaskGroup, groupedTasks) => (dragEvent) => {
  // update grouped tasks
  const { source, destination, draggableId } = dragEvent;
  const destIndex = destination?.index;
  // get previous and next task
  const tasks = groupedTasks.find(
    (g) => g.group === destination.droppableId,
  )?.taskList;
  let prevTask;
  let nextTask;
  if (
    source.index > destination.index
      || source.droppableId !== destination.droppableId
  ) {
    prevTask = tasks?.[destIndex - 1];
    nextTask = tasks?.[destIndex];
  } else {
    prevTask = tasks?.[destIndex];
    nextTask = tasks?.[destIndex + 1];
  }

  let newSortOrder = 0;
  if (prevTask?.sortOrder && nextTask?.sortOrder) {
    newSortOrder = (prevTask.sortOrder + nextTask.sortOrder) / 2;
  } else if (prevTask?.sortOrder) {
    newSortOrder = prevTask.sortOrder + 1;
  } else if (nextTask?.sortOrder) {
    newSortOrder = nextTask.sortOrder - 1;
  }

  if (destination) {
    updateTaskGroup(draggableId, destination.droppableId, newSortOrder);
  }
};

export default {
  getFieldsToShow,
  renderFieldValue,
};
