export const flattenData = (objects) => {
  function flatten(node) {
    if (!node.children || node.children.length === 0) {
      return [node];
    }
    const flattened = node.children.filter(
      (child) => child.children && child.children.length > 0,
    );
    if (flattened.length === 0) {
      return [node];
    }
    return [node, ...flattened.flatMap((child) => flatten(child))];
  }

  return objects.flatMap((obj) => flatten(obj));
};
export const primaryFundSort = ["Lead", "", "Participating"];
export const agendaActions = {
  "weekly-ic": [
    {
      link: "agenda",
      key: "agenda",
      label: "Agenda",
    },
    {
      link: "ceo-remarks",
      key: "ceo-remarks",
      label: "CEO Remarks",
      disabled: true,
    },
    {
      link: "requested-investment-approvals",
      key: "requested-investment-approvals",
      label: "IC Approval Requests",
      agendaLabel: "Investment Committee Approval Requests",
    },
    {
      link: "completed-since-last-ic",
      key: "completed-since-last-ic",
      label: "Inv Closed Since Last IC",
      agendaLabel: "Investments Closed Since Last Investment Committee",
    },
    {
      link: "deal-passes",
      key: "deal-passes",
      label: "New Opportunities",
      children: [
        {
          link: "deal-passes",
          key: "deal-passes",
          label: "Passes - No OA",
          agendaLabel: "Passes - No Opportunity Assessment",
        },
        {
          link: "F",
          key: "F",
          label: "New Opp. - Pre OA",
          agendaLabel: "New Opportunities - Pre Opportunity Assessment",
          disabled: true,
        },
      ],
    },
    {
      link: "first-meeting",
      key: "first-meeting",
      agendaLabel: "Approval of Opportunity Assessment Recommendations",
      label: "OA Recommendation Approvals",
    },
    {
      link: "in-progress/under-ts",
      key: "in-progress",
      label: "Investments in Progress",
      agendaLabel: "Investments in Progress",
      children: [
        {
          link: "in-progress/under-ts",
          key: "under-ts-2",
          label: "Under Term Sheet",
          agendaLabel: "Under Term Sheet",
        },
        {
          link: "in-progress/considering-for-ts-review",
          key: "considering-for-ts-review",
          label: "Considering for Term Sheet Review",
          agendaLabel: "Considering for Term Sheet Review",
        },
        {
          link: "in-progress/signal-model-fund",
          key: "signal-model-fund",
          label: "Signal Model Fund",
          agendaLabel: "Signal Model Fund",
        },
      ],
    },

    {
      link: "performance-to-plan",
      key: "performance-to-plan",
      label: "P2P Reviews",
      agendaLabel: "Performance To Plan Reviews",
      disabled: true,
    },
    // {
    //   link: "new-investments",
    //   key: "new-investments",
    //   label: "New Investments",
    //   disabled: true,
    //   children: [
    // {
    //   link: "first-meeting",
    //   key: "first-meeting-investment-recommendations",
    //   label: "First Meeting Recommendation Review",
    // },
    //     {
    //       link: "resource-allocation",
    //       key: "resource-allocation",
    //       label: "Resource Allocation / Team Formation",
    //       disabled: true,
    //     },
    //   ],
    // },
    {
      link: "requested-scale-group-approvals/lean-growth",
      key: "requested-scale-group-approvals",
      label: "Scale Group Update",
      agendaLabel: "Scale Group Updates and Approvals",
      children: [
        {
          link: "requested-scale-group-approvals/lean-growth/pipeline",
          key: "requested-scale-group-approvals/lean-growth",
          label: "Lean/Growth",
          agendaLabel: "Lean/Growth",
        },
        {
          link: "requested-scale-group-approvals/rev-gen/portco-update",
          key: "requested-scale-group-approvals/rev-gen",
          label: "Revenue Generation",
          agendaLabel: "Revenue Generation",
        },
        {
          link: "requested-scale-group-approvals/people-ops",
          key: "requested-scale-group-approvals/people-ops",
          label: "Human Capital",
        },
      ],
    },
    {
      link: "investment-ihe-review",
      key: "investment-ihe-review",
      label: "IHE Changes",
      agendaLabel: "Portfolio Company Surveys Since Last IC",
    },

    {
      link: "portfolio-financing/liquidity-events",
      key: "portfolio-financing",
      label: "PortCo Financing Activity",
      agendaLabel: "Portfolio Company Financing Activity",
      children: [
        {
          link: "portfolio-financing/liquidity-events",
          key: "liquidity-events",
          label: "Liquidity Events",
        },
        {
          link: "portfolio-financing/pro-entropic-m-a",
          key: "pro-entropic-m-a",
          label: "Pro-Entropic M&A",
        },
        {
          link: "portfolio-financing/fund-raising",
          key: "fund-raising",
          label: "Fundraising (Debt & Equity)",
        },
      ],
    },
    {
      link: "valor-fundraising",
      key: "valor-fundraising",
      label: "Valor Fundraising",
      children: [
        {
          link: "valor-fundraising/active",
          key: "valor-fundraising/active",
          label: "Active",
        },
        {
          link: "valor-fundraising/in-consideration",
          key: "valor-fundraising/in-consideration",
          label: "In Consideration",
        },
      ],
    },
    {
      link: "fund-management",
      key: "fund-management",
      label: "Fund Management",
      disabled: true,
      children: [
        {
          link: "fund-management/pacing",
          key: "fund-management/pacing",
          label: "Pacing",
          disabled: true,
        },
        {
          link: "fund-management/reserve-analysis",
          key: "fund-management/reserve-analysis",
          label: "Reserve Analysis",
          disabled: true,
        },
      ],
    },
    {
      link: "fund-admin/investment-memos",
      key: "fund-admin",
      label: "Fund Administration",
      children: [
        {
          link: "fund-admin/investment-memos",
          key: "investment-memos",
          label: "Investment Announcements",
        },
        {
          link: "fund-admin/upcoming-meetings",
          key: "upcoming-meetings",
          label: "Upcoming LP/AB Meetings",
        },
        {
          link: "fund-admin/lp-consents",
          key: "fund-admin/lp-consents",
          label: "LP/AB Consents",
        },
      ],
    },
    {
      link: "smaller-group-discussion",
      key: "smaller-group-discussion",
      label: "Smaller Group Discussion",
      agendaLabel:
        "Smaller Group Discussion (if needed) for any agenda item or ad hoc discussion",
      disabled: true,
    },
    {
      link: "partner-group-discussion",
      key: "partner-group-discussion",
      label: "Partner Group Discussion",
      disabled: true,
    },
  ],
  standup: [
    {
      link: "agenda",
      key: "agenda",
      label: "Agenda",
    },
    {
      disabled: true,
      key: "ceo-discussion",
      label: "CEO Discussion / Update",
    },
    {
      disabled: true,
      key: "team-discussion",
      label: "Team Updates",
    },
    {
      disabled: true,
      key: "ideasession",
      label: "Ideas Session and/or Research Discussion",
    },
    {
      link: "deals-dashboard?chart=deal_flow&backlogType=total",
      key: "Growth Pipeline Discussion",
      uselessParent: true,
      label: "Growth Pipeline Discussion",
      children: [
        {
          link: "deals-dashboard?chart=deal_flow&backlogType=total",
          key: "deals-dashboard",
          label: "Deals Dashboard - Top Of Funnel",
          children: [
            {
              link: "deals-dashboard?chart=deal_flow&backlogType=total",
              key: "total-deal-flow",
              label: "Total Deal Flow",
            },
            {
              link: "deals-dashboard?chart=intro&backlogType=total",
              key: "lean-growth-updates/lead-submission",
              label: "Deal Flow by Source of Intro",
            },
          ],
        },

        {
          link: "decision-backlog?chart=decision_backlog&backlogType=total",
          key: "decision-backlog",
          label: "Deals Dashboard - Decision Backlog",
          children: [
            {
              link: "decision-backlog?chart=decision_backlog&backlogType=total",
              key: "total-decision",
              label: "Total Decision Backlog",
            },
            {
              link: "decision-backlog?chart=decision_backlog&backlogType=aging",
              key: "aging-decisions",
              label: "Decision Backlog Aging",
            },
            {
              link: "decision-backlog?chart=velocity_team_assigned&backlogType=total",
              key: "decision-velocity-team-assigned",
              label: "Decision Velocity - Team Assigned",
            },
            {
              link: "decision-backlog?chart=velocity_considering&backlogType=total",
              key: "decision-velocity-considering",
              label: "Decision Velocity - Considering",
            },
            {
              link: "decision-backlog?chart=velocity_termsheet&backlogType=total",
              key: "decision-velocity-TS",
              label: "Decision Velocity - Term Sheet",
            },
          ],
        },
      ],
    },

    {
      disabled: true,
      wip: true,
      key: "Platform & Platform Add-On",
      label: "Platform & Platform Add-On",
      children: [
        {
          disabled: true,
          wip: true,
          key: "plat-updates",
          label: "Updates",
        },
        {
          disabled: true,
          wip: true,
          key: "plat-recommend-pass",
          label: "Recommend Pass",
        },
        {
          disabled: true,
          wip: true,
          key: "plat-new-top-of-funnel",
          label: "New / Top of Funnel",
        },
      ],
    },
    {
      disabled: true,
      wip: true,
      key: "VC & VC Add-On",
      label: "VC & VC Add-On",
      children: [
        {
          disabled: true,
          wip: true,
          key: "VC-updates",
          label: "Updates",
        },
        {
          disabled: true,
          wip: true,
          key: "VC-recommend-pass",
          label: "Recommend Pass",
        },
        {
          disabled: true,
          wip: true,
          key: "VC-new-top-of-funnel",
          label: "New / Top of Funnel",
        },
      ],
    },

    {
      disabled: true,
      wip: true,
      key: "SeedUpdates",
      label: "Seed Pipeline Updates",
    },
    {
      disabled: true,
      key: "Other Updates",
      label: "Other Portfolio Updates",
    },
    {
      disabled: true,
      key: "Once Monthly Pacing",
      label: "Once Monthly Pacing Update",
    },
  ],
};

export const FundListFilter = {
  "Fund VI": "isGrowthFund6",
  "Fund V": "isGrowthFund5",
  "Fund IV": "isGrowthFund4",
  "Fund III": "isGrowthFund3",
  VOF: "isVofFund",
  "CV Consortio": "isCvConsortioFund",
  "Seed I": "isSeedFund1",
  "VSV I": "isVsvFund1",
  "VSV II": "isVsvFund2",
  VAF: "isVafFund1",
};

export const FundList = [
  "Fund VI",
  "Fund V",
  "Fund IV",
  "Fund III",
  "VOF",
  "CV Consortio",
  "VAF",
  "Seed I",
  "VSV I",
  "VSV II",
  "R&D",
];

export const FundListAffinityKey = {
  "Fund IV": "Fund IV",
  "Fund V": "Fund V",
  "Fund VI": "Fund VI",
  "Fund III": "Fund III",
  VOF: "VOF",
  "CV Consortio": "CV Consortio",
  VAF: "VAF",
  "Seed I": "Seed",
  "VSV I": "VSV I",
  "VSV II": "VSV II",
  "R&D": "R&D",
};

export const GrowthStatuses = {
  TS: "TS",
  TeamAssigned: "Team Assigned",
  Considering: "Considering",
  Won: "Won",
};

export const VSVStatuses = {
  TS: "TS Executed",
  TeamAssigned: "Team Assigned",
  Considering: "Considering for TS",
  Won: "Won",
};

export const FundListStatus = {
  "Fund IV": GrowthStatuses,
  "Fund V": GrowthStatuses,
  "Fund VI": GrowthStatuses,
  VOF: GrowthStatuses,
  VAF: GrowthStatuses,
  "CV Consortio": GrowthStatuses,
  "Seed I": GrowthStatuses,
  "VSV I": VSVStatuses,
  "VSV II": VSVStatuses,
  "R&D": GrowthStatuses,
};

export default agendaActions;
