/* eslint-disable react/prop-types */
import React, { useState, useMemo } from "react";

import {
  Box,
  Card,
  Typography,
  makeStyles,
  Button,
  Input,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import PollIcon from "@material-ui/icons/Poll";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { useNavigate } from "react-router-dom";
import analytics from "@/shared/analytics";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import { sharedColumns } from "./TableConfig";
import {
  getDaysPastDue,
  getDaysTillDue,
} from "../utils";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10),
  },
  search: {
    marginTop: theme.spacing(1),
  },
  searchInput: {
    width: "100%",
  },
  card: {
    display: "grid",
    gridTemplateAreas: `
                        "company company"
                        "task task"
                        "actions actions"
                      `,
    gridTemplateColumns: "auto auto",
    gridTemplateRows: "auto auto auto",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    minHeight: 0,
    minWidth: 0,
  },
  company: {
    gridArea: "company",
    display: "flex",
    gap: theme.spacing(1),
  },
  task: {
    gridArea: "task",
  },
  actions: {
    gridArea: "actions",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
  },
  coName: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: "underline",
  },
  domainLink: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
  },
  overdueText: {
    color: theme.palette.red.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  upcomingText: {
    color: theme.palette.orange.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

function UserTasksCards({
  tasks,
  closeTask,
  page,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const isOverdue = page.includes("overdue");
  const [overdueSearchValue, setOverdueSearchValue] = useState("");
  const [upcomingSearchValue, setUpcomingSearchValue] = useState("");

  function getSearchValue() {
    if (isOverdue) {
      return overdueSearchValue;
    }
    return upcomingSearchValue;
  }

  function setSearchValue(value) {
    if (isOverdue) {
      setOverdueSearchValue(value);
    } else {
      setUpcomingSearchValue(value);
    }
  }

  const header = isOverdue ? "Overdue Tasks" : "Upcoming Tasks";
  const { overdue, upcoming } = tasks;
  const pageTasks = isOverdue ? overdue : upcoming;

  const columns = useMemo(() => sharedColumns(), []);
  const sortBy = useMemo(() => [{ id: "dueDate", desc: false }], []);

  const {
    rows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: pageTasks,
      initialState: {
        sortBy,
        globalFilter: getSearchValue(),
      },
    },
    useGlobalFilter,
    useSortBy,
  );

  const updateSearch = useAsyncDebounce((value) => {
    setGlobalFilter(value);
  }, 200);

  function renderTaskCard(task) {
    const daysTillDue = getDaysTillDue(task.dueDate);
    const daysPastDue = getDaysPastDue(task.dueDate);
    return (
      <Card
        variant="outlined"
        className={classes.card}
        key={task.taskId}
      >
        <Box
          className={classes.company}
        >
          <CompanyAvatar
            src={task.logoUrl}
            name={task.companyName}
            domain={task.domain}
            size="40"
          />
          <Box
            onClick={() => navigate(`/org/${task.valorId}`)}
          >
            <Typography
              variant="body1"
              className={classes.coName}
            >
              {task.companyName}
            </Typography>
            <Typography
              variant="body1"
              className={classes.domainLink}
            >
              {task.domain}
            </Typography>
          </Box>
        </Box>
        <Box
          className={classes.task}
        >
          <Typography
            variant="h2"
          >
            {task.taskName}
          </Typography>
          {
            isOverdue
              ? (
                <Typography
                  variant="body2"
                  className={classes.overdueText}
                >
                  {daysPastDue !== "1" ? `${daysPastDue} Days Overdue` : "1 Day Overdue"}
                </Typography>
              )
              : (
                <Typography
                  variant="body2"
                  className={classes.upcomingText}
                >
                  {`${daysTillDue} Days Remaining`}
                </Typography>
              )
          }
        </Box>
        <Box
          className={classes.actions}
        >
          <Button
            onClick={() => closeTask(task.taskId, isOverdue ? "overdue" : "upcoming")}
            variant="text"
          >
            Mark as Done
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/org/${task.valorId}`)}
            startIcon={<PollIcon />}
          >
            Take Survey
          </Button>
        </Box>
      </Card>
    );
  }

  function renderTaskCards(filteredTasks) {
    return (
      <Box
        className={classes.cardContainer}
      >
        {filteredTasks.map((task) => renderTaskCard(task))}
      </Box>
    );
  }

  function renderSearch() {
    return (
      <Box
        className={classes.search}
      >
        <InputLabel htmlFor="search-with-icon-adornment">Search</InputLabel>
        <Input
          className={classes.searchInput}
          value={getSearchValue()}
          onChange={(e) => {
            setSearchValue(e.target.value);
            updateSearch(e.target.value);
          }}
          id="search-with-icon-adornment"
          startAdornment={(
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )}
          endAdornment={(
            <InputAdornment
              style={{
                visibility: getSearchValue().length ? "visible" : "hidden",
              }}
              position="end"
              onClick={() => {
                setSearchValue("");
                updateSearch("");

                analytics
                  .track("MOBILE tasks clear-search");
              }}
            >
              <ClearIcon />
            </InputAdornment>
          )}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <Typography
          variant="h4"
        >
          {header}
        </Typography>
      </Box>
      {renderSearch()}
      {pageTasks.length > 0 ? renderTaskCards(rows.map((x) => x.original)) : (
        <Box className={classes.cardContainer}>
          <Typography variant="h2">
            You&apos;re all caught up! Future tasks will appear here.
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default UserTasksCards;
