import dayjs from "dayjs";

export const colorForStatus = {
  INCOMPLETE: "red",
  "IN PROGRESS": "orange",
  COMPLETE: "green",
};

export const getDaysPastDue = (dueDate) => {
  const daysPastDue = dayjs().diff(dueDate, "day");
  return daysPastDue > 0 ? daysPastDue : 0;
};

export const getDaysTillDue = (dueDate) => Math.abs(dayjs().diff(dueDate, "day"));

export default {
  colorForStatus,
  getDaysPastDue,
  getDaysTillDue,
};
