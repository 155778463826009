/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import { useMediaQuery } from "@material-ui/core";
import { useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import xlsx from "json-as-xlsx";
import { getAllTasks, completeTask } from "@/api/Tasks";
import UserTasksCards from "./UserTasksCards";
import UserTasksTable from "./UserTasksTable";

function UserTasks({
  userId,
  department,
  exportToExcel,
  page,
}) {
  const queryClient = useQueryClient();
  const { data: tasks = [], isLoading, refetch } = useQuery(["UserTasks"], async () => {
    const response = await getAllTasks(userId);
    const overdue = [];
    const upcoming = [];
    response.forEach((task) => {
      if (dayjs(task.dueDate) < dayjs()) {
        overdue.push(task);
      } else {
        upcoming.push(task);
      }
    });
    return { overdue, upcoming, all: response };
  });

  function exportUserTasks() {
    xlsx(
      [{
        sheet: "My Tasks",
        columns: [
          { label: "Status", value: "status" },
          { label: "Task", value: "taskName" },
          { label: "Company", value: "companyName" },
          { label: "Valor Id", value: "valorId" },
          { label: "Domain", value: "domain" },
          { label: "Created At", value: "createdAt" },
          { label: "Due Date", value: "dueDate" },
        ],
        content: tasks.all.map((task) => ({
          status: dayjs(task.dueDate) < dayjs() ? "Overdue" : "Ucpoming",
          taskName: task.taskName,
          companyName: task.companyName,
          valorId: task.valorId,
          domain: task.domain,
          createdAt: dayjs(task.createdAt).format("MMMM DD, YYYY"),
          dueDate: dayjs(task.dueDate).format("MMMM DD, YYYY"),
        })),
      },
      ],
      {
        fileName: "My Tasks", // Name of the resulting spreadsheet
        extraLength: 0, // A bigger number means that columns will be wider
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      },
    );
  }

  useEffect(() => {
    exportToExcel.current = exportUserTasks;
  }, [tasks]);

  const handleTaskClose = async (taskId, field) => {
    queryClient.setQueriesData(
      ["NumTasks"],
      (oldData) => oldData - 1,
    );
    queryClient.setQueriesData(
      ["UserTasks"],
      (oldData) => {
        const fieldData = oldData[field].filter((x) => x.taskId !== taskId);
        oldData[field] = fieldData;
        return oldData;
      },
    );
    await completeTask(taskId);
    await refetch();
  };

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <CenteredProgress isLoading={isLoading}>
      {isMdUp
        ? (
          <UserTasksTable
            tasks={tasks}
            department={department}
            fetchTasks={() => refetch()}
            closeTask={handleTaskClose}
          />
        )
        : (
          <UserTasksCards
            tasks={tasks}
            department={department}
            closeTask={handleTaskClose}
            page={page}
          />
        )}
    </CenteredProgress>
  );
}

export default UserTasks;
