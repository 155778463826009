import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { FrameworkChoiceColorIconMapping } from "@/constants/FrameworkChoiceColorIconMapping";

interface Props {
  color: "green" | "yellow" | "red" | "gray" | "blue";
  isActive?: boolean;
  onClick(...args: unknown[]): void;
  text: string;
}

const animation = {
  blue: "spin 2s ease",
  green: "bounce 2s ease 3",
  red: "bounceDown 2s ease 3",
  yellow: "hithere 2s ease 3",
};

export default function FrameworkRadio({
  color,
  isActive = false,
  onClick,
  text,
}: Props) {
  const theme = useTheme();
  const Icon = FrameworkChoiceColorIconMapping[color];

  return (
    <Box
      onClick={onClick}
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "flex-start",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        border: isActive
          ? `2px solid ${theme.palette[color].main}`
          : `1px solid ${theme.palette.divider}`,
        margin: isActive ? "-1px" : undefined,
        boxShadow: theme.shadows[3],
        borderRadius: theme.spacing(1),
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        [theme.breakpoints.only("xl")]: {
          height: theme.spacing(10),
        },
        [theme.breakpoints.only("lg")]: {
          height: theme.spacing(15),
        },
        [theme.breakpoints.down("md")]: {
          height: theme.spacing(10),
        },
        [theme.breakpoints.down("xs")]: {
          height: theme.spacing(18),
          padding: theme.spacing(1),
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingRight: theme.spacing(2),
          color: theme.palette[color].main,
        }}
      >
        <Icon
          style={{
            animation: isActive ? animation[color] : "none",
            width: ".75em",
            height: ".75em",
          }}
        />
      </Box>
      <Box
        sx={{
          "& p::before": {
            height: 0,
            overflow: "hidden",
            fontWeight: theme.typography.fontWeightBold,
            fontSize: "1.2rem",
            visibility: "hidden",
          },
        }}
      >
        <Typography
          sx={{
            color: isActive
              ? theme.palette[color].main
              : theme.palette.text.primary,
            fontWeight: isActive
              ? theme.typography.fontWeightBold
              : theme.typography.fontWeightRegular,
            userSelect: "none",
            fontSize: "1.2em",
            textAlign: "start",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: { xl: 3, xs: 4 },
            WebkitBoxOrient: "vertical",
            wordBreak: "break-word",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
}
