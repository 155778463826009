import React from "react";
import {
  Box, Button, TextField, Typography, Alert,
} from "@mui/material";
import { uploadImage, uploadExternalImage } from "@/api/Images";
import RTFEditor from "@/ui/molecules/RichTextEditor";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import MeetingTypeSelect from "@/pages/Org/Notes/EditNotes/MeetingTypeSelect";

export default function Editor({
  disabled,
  error,
  onPublish,
}: {
  disabled: boolean;
  error: boolean;
  onPublish: (note: {
    title: string;
    richText: any;
    meetingDate: number;
    meetingType: string;
  }) => void;
}) {
  const [workingTitle, setWorkingTitle] = React.useState("Untitled Note");
  const [workingRichText, setWorkingRichText] = React.useState(null);
  const [imageUploadError, setImageUploadError] = React.useState(false);

  const [workingMeetingDate, setWorkingMeetingDate] = React.useState(Date.now());
  const [workingMeetingType, setWorkingMeetingType] = React.useState("Deal Engagement / Status");
  const [isOpenDatePicker, setIsOpenDatePicker] = React.useState(false);

  const handleTextChange = (newRichText: any) => {
    setWorkingRichText(newRichText);
  };

  const handlePublish = () => {
    onPublish({
      title: workingTitle,
      richText: workingRichText,
      meetingDate: workingMeetingDate,
      meetingType: workingMeetingType,
    });
  };

  return (
    <Box
      p={1}
    >
      {error && (
        <Alert severity="error" sx={{ mb: 1 }}>
          There was an error saving the note. Please try again.
          If problems persist, please contact the labs team.
        </Alert>
      )}

      {imageUploadError && (
        <Alert severity="error" sx={{ mb: 1 }}>
          There was an error uploading the image. Please try again.
          If problems persist, please contact the labs team.
        </Alert>
      )}

      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        size="small"
        value={workingTitle}
        onChange={(e) => {
          setWorkingTitle(e.target.value);
        }}
        disabled={disabled}
      />

      <Box
        marginY={1}
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent="space-between"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection={{
            xs: "column",
            sm: "row",
          }}
          gap={1}
          justifyContent="flex-start"
          alignItems={{
            xs: "flex-start",
            sm: "center",
          }}
          flexWrap="wrap"
        >
          <Typography variant="body2">Date:</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={disabled}
              open={isOpenDatePicker}
              onOpen={() => setIsOpenDatePicker(true)}
              onClose={() => setIsOpenDatePicker(false)}
              value={dayjs(workingMeetingDate)}
              onChange={(date) => setWorkingMeetingDate(date.valueOf())}
            />
          </LocalizationProvider>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          gap={1}
        >
          <Typography variant="body2">Note Type:</Typography>
          <Box
            minWidth={200}
            sx={{
              zIndex: 100000,
            }}
          >
            <MeetingTypeSelect
              disabled={disabled}
              value={workingMeetingType}
              onChange={(event) => setWorkingMeetingType(event.target.value)}
            />
          </Box>
        </Box>
      </Box>
      <RTFEditor
        disabled={disabled}
        initialValue={workingRichText || null}
        uploadImage={async ({
          file,
          url,
        }: {
          file?: File;
          url?: string;
        }) => {
          if (file) {
            const ext = file.name.split(".").pop();
            try {
              const data = await uploadImage(
                `${crypto.randomUUID()}.${ext}`,
                file,
              );
              return {
                name: data.fileName,
                url: data.signedUrl,
              };
            } catch (err) {
              // show error toast
              setImageUploadError(true);
              return null;
            }
          }

          try {
            const data = await uploadExternalImage(url);
            return {
              name: data.fileName,
              url: data.signedUrl,
            };
          } catch (err) {
            setImageUploadError(true);
            return null;
          }
        }}
        onChange={(newRichText) => {
          handleTextChange(newRichText);
        }}
      />
      <Box
        display="flex"
        justifyContent="flex-end"
        my={1}
      >
        <Button variant="contained" disabled={disabled} onClick={handlePublish}>Publish</Button>
      </Box>
    </Box>
  );
}
