/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, {
  useMemo, useState,
} from "react";
import { useQuery } from "react-query";
import { vsvRevGen } from "@/api/Reporting";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Typography,
  Select,
  Link,
  Card,
  Alert,
} from "@mui/material";
import { DataTable } from "@/components/LocalTable";
import {
  CompanyCell,
  TextCell,
  MultiUserCell,
  OpportunityNoteCell,
} from "@/components/Table/Primitives";
import Progress from "@/ui/atoms/Progress";
// import { formatBigFundingRoundDollars } from "@/utils/numberFormat";

function RevGenStatus() {
  const [daysAgo, setDaysAgo] = useState(14);
  const [grabOnlyLatestNote, setGrabLatestNote] = useState(true);

  const columns = useMemo(
    () => [
      {
        header: "Deal Name",
        accessorFn: (row) => row.name,
        cell: (prop) => (
          <Typography
            sx={(t) => ({
              color: t.palette.text.primary,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "normal",
            })}
          >
            <Link
              href={`https://valor.affinity.co/opportunities/${prop.row.original.opportunityId}`}
              target="_blank"
              rel="noreferrer"
            >
              {prop.getValue()}
            </Link>
          </Typography>
        ),
        id: "name",
        sortingFn: "alphanumeric",
        minSize: 250,
      },
      {
        header: "Company",
        accessorFn: (row) => row.company,
        cell: (prop) => <CompanyCell value={prop.getValue()} />,
        id: "company",
        getFilterValue: (row) => row.company?.name.toLowerCase(),
        getSortValue: (row) => row.company?.name.toLowerCase(),
        sortingFn: (rowA, rowB) => rowA.original.company.name.localeCompare(rowB.original.company.name),
        filterFn: "fuzzy",
        minSize: 250,
      },
      {
        header: "Strategic Company",
        accessorFn: (row) => row.strategicCo,
        cell: (prop) => <CompanyCell value={prop.getValue()} />,
        id: "strategicCo",
        getFilterValue: (row) => row.strategicCo?.name.toLowerCase(),
        getSortValue: (row) => row.strategicCo?.name.toLowerCase(),
        sortingFn: (rowA, rowB) => rowA.original.strategicCo.name.localeCompare(
          rowB.original.strategicCo.name,
        ),
        filterFn: "fuzzy",
        minSize: 250,
      },
      // {
      //   header: "Total $",
      //   accessorFn: (row) => row.totalInvestedCapital,
      //   id: "totalInvestedCapital",
      //   cell: (prop) => (
      //     <TextCell value={formatBigFundingRoundDollars(prop.getValue())} />
      //   ),
      // },
      {
        header: "Update",
        cell: (prop) => {
          const { opportunityId } = prop.row.original;
          return <OpportunityNoteCell opportunityId={opportunityId} daysAgo={daysAgo} noteCount={grabOnlyLatestNote ? 1 : 10} />;
        },
        id: "notes",
        disableSortBy: true,
        disableFilters: true,
        minSize: 550,
        size: 750,
      },
      {
        header: "Rev Gen Lead",
        accessorFn: (row) => row.revGenMsl,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        id: "revGenMsl",
        enableColumnFilter: true,
        enableSorting: true,
        minSize: 100,
        size: 250,
      },
      {
        header: "Owners",
        accessorFn: (row) => row.vsvOwners,
        cell: (prop) => <MultiUserCell value={prop.getValue()} />,
        id: "vsvOwners",
        enableColumnFilter: false,
        enableSorting: false,
        minSize: 100,
        size: 250,
      },
    ],
    [],
  );

  const { data = [], isLoading } = useQuery(
    ["vsvRevGenUpdates"],
    async () => {
      let records = await vsvRevGen(true, null, null);
      records = records.map((x) => ({ ...x, company: x.companies?.[0] }));
      const updatedData = records.sort((a, b) => {
        if (!a?.totalInvestedCapital) {
          return 1;
        }
        if (!b?.totalInvestedCapital) {
          return -1;
        }
        return b.totalInvestedCapital - a.totalInvestedCapital;
      });
      return updatedData;
    },
  );

  if (isLoading) return <Progress />;
  if (!data.length) {
    return (
      <Card sx={{ padding: 2 }}>
        <Alert severity="info" data-cy="no-data-message">
          No RevGen Updates.
        </Alert>
      </Card>
    );
  }

  return (
    <div>
      <Box display="flex" sx={{ gap: "8px", marginTop: 1, marginBottom: 1 }}>
        <FormControl
          margin="dense"
          variant="outlined"
          style={{ width: "240px" }}
        >
          <InputLabel>Grab Notes For</InputLabel>
          <Select
            value={daysAgo}
            onChange={(e) => {
              setDaysAgo(e.target.value);
            }}
            label="Grab Notes For"
          >
            <MenuItem value={7}>
              <ListItemText primary="Last Week" />
            </MenuItem>
            <MenuItem value={14}>
              <ListItemText primary="Last Two Weeks" />
            </MenuItem>
            <MenuItem value={28}>
              <ListItemText primary="Last Four Weeks" />
            </MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={(
            <Checkbox
              checked={grabOnlyLatestNote}
              onChange={(e) => setGrabLatestNote(e.target.checked)}
            />
          )}
          label="Grab only Latest Note"
        />
      </Box>
      {isLoading ? (
        <Progress />
      ) : (
        <Card elevation={0}>
          <DataTable data={data} columns={columns} />
        </Card>
      )}
    </div>
  );
}

export default RevGenStatus;
