/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Alert,
  useMediaQuery,
  Card,
  Divider,
  Typography,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemButton,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import domtoimage from "dom-to-image-more";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import useLocalStorageState from "@/hooks/useLocalStorageState";
import slugify from "@/utils/slugify";

import {
  getMarketMap,
  getCellMembers,
  createExcelReport,
} from "@/api/MarketMap";
import { getOrganizationById } from "@/api/Organization";
import { MarketMapGrid } from "./MarketMapGrid";
import { MarketMapHeader } from "./MarketMapHeader";
import { MarketMapDrawer } from "../MarketMapDrawer";

function MarketMap() {
  const { id, slug } = useParams();
  const navigate = useNavigate();

  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [display, setDisplay] = useState(false);

  const [hasShownAlert, setShownAlert] = useLocalStorageState(
    false,
    `showIsMigrated-${id}`
  );
  const [showIsMigrated, setShowMigrated] = useState(false);

  const [highlightedCompanyId, setHighlightedCompanyId] = useState(null);

  const [drawerCompanyId, setDrawerCompanyId] = useState(null);

  const { isLoading: areMemberCellsLoading, data: memberCells = [] } = useQuery(
    ["CellMembers", id],
    async () => {
      const response = await getCellMembers(id);
      return response;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    }
  );

  const { isLoading: isMapLoading, data: map = {} } = useQuery(
    ["MarketMap", id],
    async () => {
      const response = await getMarketMap(id);
      return response;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    }
  );
  useEffect(() => {
    if (map.isMigrated && !hasShownAlert) {
      setShowMigrated(true);
      setShownAlert(true);
    }
  }, [map.isMigrated, hasShownAlert, setShownAlert, setShowMigrated]);

  const { isLoading, data: drawerCompany = null } = useQuery(
    ["Organization", drawerCompanyId],
    async () => getOrganizationById(drawerCompanyId),
    {
      enabled: !!drawerCompanyId,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    }
  );

  if (!slug && map.title) {
    navigate(`/market-maps/${slugify(map.title)}/view/${id}`, {
      replace: true,
    });
  }

  const onItemClick = (companyId) => {
    setDrawerCompanyId(companyId);
  };

  const onCloseDrawer = () => {
    setDrawerCompanyId(null);
  };

  const searchCompanies = (q) => {
    const needles = memberCells.filter((company) =>
      company.name.toLowerCase().includes(q.toLowerCase())
    );

    if (needles) {
      return needles.map((company) => ({
        valorId: company.valorId,
        name: company.name,
        domain: company.domain,
        logoUrl: company?.logoUrl,
      }));
    }
    return [];
  };

  const createAccordions = (xvalues, yvalues, companies) => (
    <Box>
      {xvalues.map((xvalue, xIndex) =>
        yvalues.map((yvalue, yIndex) => {
          const accordionCompanies = companies.filter(
            (company) =>
              company.rowDimensionValueId === xvalue.id &&
              company.colDimensionValueId === yvalue.id
          );

          if (accordionCompanies.length === 0) {
            return null;
          }

          return (
            <Accordion
              key={`${xvalue.id}-${yvalue.id}`}
              title={`X ${xIndex + 1}, Y ${yIndex + 1}`}
            >
              <AccordionSummary
                sx={{ height: "72px" }}
                expandIcon={<ArrowDownwardIcon />}
                aria-controls={`panel${xIndex + 1}-${yIndex + 1}-content`}
                id={`panel${xIndex + 1}-${yIndex + 1}-header`}
              >
                <Box display="flex" alignItems="center" width="100%">
                  <Box flex={1}>
                    <Typography variant="subtitle1">{xvalue.name}</Typography>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box flex={1} textAlign="right">
                    <Typography variant="boldSubtitle1">
                      {yvalue.name}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {accordionCompanies.map((company) => (
                    <React.Fragment key={company.valorId}>
                      <ListItemButton
                        onClick={() => onItemClick(company.valorId)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <CompanyAvatar
                            size={48}
                            src={company.logoUrl}
                            name={company.name}
                            domain={company.domain}
                            loading="eager"
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Typography
                              sx={{ textAlign: "right" }}
                              variant="boldSubtitle2"
                            >
                              {company.name}
                            </Typography>
                            <Typography
                              sx={{ textAlign: "right" }}
                              variant="subtitle2"
                            >
                              {company.domain}
                            </Typography>
                          </Box>
                        </Box>
                      </ListItemButton>
                      <Divider orientation="horizontal" flexItem />
                    </React.Fragment>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          );
        })
      )}
    </Box>
  );

  useEffect(() => {
    if (display) {
      setTimeout(() => {
        const size = {
          width: 1450,
          height: 900,
        };

        domtoimage
          .toPng(document.getElementById("fixed-size-market-map-grid"), size)
          .then((dataUrl) => {
            const link = document.createElement("a");
            link.download = `${map.title}.png`;
            link.href = dataUrl;
            link.click();
            setDisplay(false);
          })
          .catch((error) => {
            setDisplay(false);
            console.error("Error while exporting as PNG", error);
          });
      }, 500);
    }
  }, [display, map.title]);

  if (isSmDown) {
    return (
      <Box>
        <Card>
          <MarketMapHeader
            title={map.title}
            isDraft={map.isDraft}
            editor={map?.authorUser?.name || ""}
            lastUpdated={map.publishedAt ? dayjs(map.publishedAt) : null}
            onBack={() => {
              navigate("/");
            }}
            exportToExcel={() => createExcelReport(id)}
            exportAsPNG={() => setDisplay(true)}
            onSearch={searchCompanies}
            onSelect={({ valorId }) => setDrawerCompanyId(valorId)}
          />
          {Boolean(drawerCompanyId) && (
            <MarketMapDrawer
              isLoading={isLoading}
              open={drawerCompanyId}
              onClose={onCloseDrawer}
              company={drawerCompany}
              pushHistory={navigate}
            />
          )}
          <CardContent>
            <Box display="flex" alignItems="center" width="100%">
              <Box flex={1} align="left">
                <Typography variant="h3" sx={{ fontWeight: 300 }}>
                  {map.row?.name}
                </Typography>
              </Box>
              <Box flex={1} align="right">
                <Typography variant="h3" sx={{ fontWeight: 700 }}>
                  {map.column?.name}
                </Typography>
              </Box>
            </Box>
            {createAccordions(
              map.row?.values || [],
              map.column?.values || [],
              memberCells
            )}
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <div>
      <CenteredProgress isLoading={isMapLoading || areMemberCellsLoading}>
        {showIsMigrated && (
          <Alert severity="info" onClose={() => setShowMigrated(false)}>
            This map was migrated into vOS from the Research team&apos;s
            historical records. Some companies might have been dropped during
            the migration process due to various data mapping issues.
          </Alert>
        )}
        <MarketMapHeader
          title={map.title}
          isDraft={map.isDraft}
          editor={map?.authorUser?.name || ""}
          lastUpdated={map.publishedAt ? dayjs(map.publishedAt) : null}
          onBack={() => {
            navigate("/");
          }}
          exportToExcel={() => createExcelReport(id)}
          exportAsPNG={() => setDisplay(true)}
          onSearch={searchCompanies}
          onSelect={({ valorId }) => setDrawerCompanyId(valorId)}
        />
        {Boolean(drawerCompanyId) && (
          <MarketMapDrawer
            isLoading={isLoading}
            open={drawerCompanyId}
            onClose={onCloseDrawer}
            company={drawerCompany}
            pushHistory={navigate}
          />
        )}
        <Box padding={1}>
          <MarketMapGrid
            id="market-map-grid"
            row={map.row}
            col={map.column}
            memberCells={memberCells}
            onClick={onItemClick}
            highlightedCompanyId={highlightedCompanyId}
            setHighlightedCompanyId={setHighlightedCompanyId}
          />
        </Box>
        {display && (
          <MarketMapGrid
            id="fixed-size-market-map-grid"
            row={map.row}
            col={map.column}
            memberCells={memberCells}
            highlightedCompanyId={null}
            setHighlightedCompanyId={setHighlightedCompanyId}
            style={{ height: "900px", width: "1415px" }}
          />
        )}
      </CenteredProgress>
    </div>
  );
}

export default MarketMap;
